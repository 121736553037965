import gql from "graphql-tag";
const CREATE_HOME_VIDEO_REVIEWS_IMAGES_GRID = gql `
  mutation createHomeVideoReviewsImagesGrid($homeVideoReviewsIds: [ID!]!) {
    createHomeVideoReviewsImagesGrid(
      homeVideoReviewsIds: $homeVideoReviewsIds
    ) {
      imagesGridId
    }
  }
`;
export default CREATE_HOME_VIDEO_REVIEWS_IMAGES_GRID;
