import { useMemo } from "react";
import { prepareComponent, useToggle } from "@bluframe/grapple";
import Layout from "./Layout";
import useAddHomeVideoReview from "hooks/useAddHomeVideoReview";
import useReviews from "hooks/useReviews";
const DEFAULT_IS_EXPANDED = true;
const usePrepareComponent = (props) => {
    // Extract loading from useAddHomeVideoReview
    const { loading: addHomeVideoReviewLoading } = useAddHomeVideoReview({
        isReadOnly: true
    });
    const { state: { isToggleFlaggedLoading } } = useReviews();
    const [isExpanded, toggleIsExpanded] = useToggle(DEFAULT_IS_EXPANDED);
    // Overlay label
    const overlayLabel = useMemo(() => {
        if (addHomeVideoReviewLoading) {
            return "Adding Home Video Review...";
        }
        if (isToggleFlaggedLoading) {
            return "Flagging Critics Review...";
        }
        return "";
    }, [addHomeVideoReviewLoading, isToggleFlaggedLoading]);
    // Show overlay
    const isShowOverlay = useMemo(() => {
        if (addHomeVideoReviewLoading || isToggleFlaggedLoading) {
            return true;
        }
        return false;
    }, [addHomeVideoReviewLoading, isToggleFlaggedLoading]);
    // Toggle expanded
    const onToggleIsExpanded = () => {
        toggleIsExpanded();
    };
    // Return props
    return {
        ...props,
        isExpanded,
        isShowOverlay,
        onToggleIsExpanded,
        overlayLabel
    };
};
export default prepareComponent(usePrepareComponent)(Layout);
