import { gql } from "graphql-tag"

const blog = gql`
  input PlatformAddInput {
    description: String
    logoId: ID
    name: String!
    slug: String!
    splashId: ID
  }

  type PlatformAddResponse {
    id: ID!
  }

  input SocialLinksAddInput {
    facebook: String
    instagram: String
    reddit: String
    twitterX: String
    website: String
    youtube: String
  }

  input WritingSamplesUpdateInput {
    after: String!
    before: String!
    samples: [String!]!
  }

  input WritingSamplesAddInput {
    after: String!
    before: String!
    samples: [String!]!
  }

  type WritingSamples {
    after: String!
    before: String!
    samples: [String!]!
  }

  input WritingInstructionsAddInput {
    caption: String
    conclusion: String
    expectedOutput: String!
    intro: String
    objective: String!
    outputAndStyleReminderFrequency: Int
    styleToAdopt: String
    writingSamples: WritingSamplesAddInput!
  }

  input CharacterAddInput {
    bio: String
    email: String!
    firstName: String!
    lastName: String!
    writingInstructions: WritingInstructionsAddInput
  }

  input WritingInstructionsUpdateInput {
    caption: String
    conclusion: String
    expectedOutput: String
    intro: String
    objective: String
    outputAndStyleReminderFrequency: Int
    styleToAdopt: String
    writingSamples: WritingSamplesUpdateInput
  }

  type CharacterAddResponse {
    id: ID!
  }

  input CharacterUpdateInput {
    bio: String
    email: String
    firstName: String
    id: ID!
    lastName: String
    writingInstructions: WritingInstructionsUpdateInput
  }

  type CharacterUpdateResponse {
    id: ID!
  }

  type WritingInstructions {
    caption: String
    conclusion: String
    expectedOutput: String!
    intro: String
    objective: String!
    outputAndStyleReminderFrequency: Int
    styleToAdopt: String
    writingSamples: WritingSamples!
  }

  type Character {
    bio: String
    email: String!
    firstName: String!
    id: ID!
    lastName: String!
    writingInstructions: WritingInstructions
  }

  input BlogRoundupGenerateInput {
    characterId: ID!
    homeVideoReviewIds: [ID!]!
    theme: String!
  }

  type SectionHeading {
    heading: String!
    subheading: String!
  }

  type SectionText {
    text: String!
  }

  type RoundupConclusion {
    heading: SectionHeading!
    text: SectionText!
  }

  type RoundupIntro {
    text: SectionText!
  }

  type RoundupReviewSnippetParagraph {
    heading: String!
    text: String!
  }

  type RoundupReviewSnippet {
    heading: SectionHeading!
    mightyChromaId: ID!
    paragraphs: [RoundupReviewSnippetParagraph!]!
  }

  type BlogRoundupGenerateResponse {
    status: String!
  }
`

export default blog
