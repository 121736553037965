import { H2 as BluBlocksH2, Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const H2 = styled(BluBlocksH2)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-weight: 500;
  line-height: 1.1;
  font-size: 28px;
  font-family: "Roboto Condensed Bold", Roboto, sans-serif;
  margin-top: 0;

  ${mediaQueries.tablet} {
    font-size: 30px;
  }
`

export default H2
