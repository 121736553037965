import { ComponentProps } from "."
import Item from "./Item"
import MUIList from "@material-ui/core/List"
import RadioGroup from "@material-ui/core/RadioGroup"
import React from "react"
import { Review } from "@bluframe/mightychroma-mightytool-frontend-types"
import colors from "styles/colors"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  list: {
    background: colors.white,
    maxHeight: 220,
    overflowY: "auto"
  }
})

const List = ({
  display,
  flagLoading,
  isShowFlagButton,
  onSelect,
  onToggleFlagged,
  selected
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <RadioGroup
      aria-label="Reviews"
      name="reviews"
      onChange={onSelect}
      value={selected}
    >
      <MUIList aria-label="Reviews" className={classes.list}>
        {display.map(({ id, isFlagged, title, url }: Review) => (
          <Item
            flagLoading={flagLoading}
            id={id}
            isFlagged={isFlagged}
            isShowFlagButton={isShowFlagButton}
            key={id}
            label={title || url}
            onToggleFlagged={onToggleFlagged}
            url={url}
          />
        ))}
      </MUIList>
    </RadioGroup>
  )
}

export default List
