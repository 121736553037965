import { Paragraph } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: flex-start;
  display: grid;
  row-gap: 30px;

  ${mediaQueries.tablet} {
    column-gap: 20px;
    grid-template-columns: 200px auto;
  }
`

export const Right = styled.div`
  display: grid;
  row-gap: 10px;
`

export const Title = styled(Paragraph)`
  font-weight: 700;
  margin: 0;
`
