import gql from "graphql-tag"

const UPDATE_CATEGORY = gql`
  mutation updateCategory($category: CategoryUpdateInput!) {
    updateCategory(category: $category) {
      id
    }
  }
`

export default UPDATE_CATEGORY
