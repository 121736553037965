/* eslint-disable max-lines-per-function */
import { useMutation, useQuery } from "@apollo/client";
import List from "./List";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
const usePrepareComponent = ({ deleteMutation, itemNamePlural, label, listQuery, listQueryDataName, pathPrefix }) => {
    const history = useHistory();
    const { data, loading } = useQuery(listQuery);
    const [deleteItem] = useMutation(deleteMutation, {
        refetchQueries: [listQuery]
    });
    const onAdd = () => {
        history.push(`${pathPrefix ?? ""}/${itemNamePlural}/add`);
    };
    const onDelete = (id) => {
        deleteItem({
            variables: {
                id
            }
        });
    };
    const onView = (id) => {
        history.push(`${pathPrefix ?? ""}/${itemNamePlural}/${id}`);
    };
    return {
        items: data?.[listQueryDataName].map((item) => ({
            ...item,
            label: item.name ?? item.title,
            onDelete,
            onView
        })) ?? [],
        label,
        loading,
        onAdd
    };
};
export default prepareComponent(usePrepareComponent)(List);
