import gql from "graphql-tag";
const UPDATE_NEWSLETTER = gql `
  mutation updateNewsletter(
    $id: ID!
    $newsletterUpdateInput: NewsletterUpdateInput!
  ) {
    updateNewsletter(id: $id, newsletterUpdateInput: $newsletterUpdateInput) {
      id
    }
  }
`;
export default UPDATE_NEWSLETTER;
