import { gql } from "graphql-tag"

const mutation = gql`
  type Mutation {
    addPlatform(
      platform: PlatformAddInput!
      socialLinks: SocialLinksAddInput
    ): PlatformAddResponse!

    generateBlogRoundup(
      blogRoundupGenerateInput: BlogRoundupGenerateInput!
    ): BlogRoundupGenerateResponse!
  }
`

export default mutation
