import Check from "./Check"

export interface Props {
  disabled: boolean
  isGreen: boolean
  isLoading: boolean
  label?: string
}

export default Check
