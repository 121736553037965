import List from "./List";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
const usePrepareComponent = ({ serviceName }) => {
    const { getList, isSkipGetList, loading } = useEightLeggedFreakList(serviceName);
    const onList = () => {
        if (!isSkipGetList) {
            getList();
        }
    };
    return {
        isDisabled: isSkipGetList || loading,
        loading,
        onList
    };
};
export default prepareComponent(usePrepareComponent)(List);
