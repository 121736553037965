import gql from "graphql-tag";
const image = gql `
  fragment ImageFormat on ImageFormat {
    height
    name
    url
    width
  }

  fragment ImageFormats on ImageFormats {
    large {
      ...ImageFormat
    }
    medium {
      ...ImageFormat
    }
    small {
      ...ImageFormat
    }
    thumbnail {
      ...ImageFormat
    }
    xlarge {
      ...ImageFormat
    }
    xsmall {
      ...ImageFormat
    }
  }

  fragment Image on Image {
    alternativeText
    caption
    formats {
      ...ImageFormats
    }
    height
    id
    name
    url
    width
  }
`;
export default image;
