import styled from "styled-components";
export const Row = styled.div `
  column-gap: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
`;
export const Buttons = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: start;
`;
export const Checkboxes = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;
