const BAD_REQUEST = 400;
const CONFLICT = 409;
const onEmailsError = (setErrors) => (error) => {
    const failedFieldError = error.graphQLErrors.find((graphQLError) => (graphQLError.extensions.code === BAD_REQUEST ||
        graphQLError.extensions.code === CONFLICT) &&
        graphQLError.extensions.failedField);
    if (failedFieldError) {
        setErrors((prev) => ({
            ...prev,
            [failedFieldError.extensions.failedField]: failedFieldError.message
        }));
    }
};
export default onEmailsError;
