import dashboard from "./dashboard"
import forum from "./forum"
import { gql } from "graphql-tag"
import homeVideoReviews from "./homeVideoReviews"

const query = gql`
  ${dashboard}
  ${forum}
  ${homeVideoReviews}

  type Query {
    drafts: [HomeVideoReview]
    draftsWave(cursor: String): HomeVideoReviewWave

    eightLeggedFreakList(
      eightLeggedFreakListInput: EightLeggedFreakListInput!
    ): EightLeggedFreakListResponse
    getCharacter(id: ID!): Character

    getContests: [Contest]
    getContest(id: ID!): Contest

    getEmailsFooters: [EmailsFooter]
    getEmailsFooter(id: ID!): EmailsFooter
    getEmailsHeadings: [EmailsHeading]
    getEmailsHeading(id: ID!): EmailsHeading
    getEmailsTemplates: [EmailsTemplate]
    getEmailsTemplate(id: ID!): EmailsTemplate

    getNewsletter(id: ID!): Newsletter
    getNewsletterAndSubscribersCount(id: ID!): NewsletterAndSubscribersCount
    getNewsletters: [Newsletter]

    getSiteUsersByContestId(contestId: ID!): [SiteUser]

    me: User

    omdbId(bluRayId: String!): String

    reviews(serviceName: String!): [Review]

    searchHomeVideoReviews(
      searchHomeVideoReviewsInput: SearchHomeVideoReviewsInput!
    ): [HomeVideoReview]
  }
`

export default query
