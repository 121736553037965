import styled from "styled-components";
export const Wrapper = styled.div `
  display: grid;
  row-gap: 20px;
`;
export const Top = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
`;
export const Main = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export const Left = styled.div `
  display: grid;
  row-gap: 30px;

  & input {
    margin-bottom: 0;
  }
`;
export const Right = styled.div `
  column-gap: 20px;
  display: grid;
`;
export const Buttons = styled.div `
  align-items: flex-start;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
`;
