/* eslint-disable no-undefined, react/hook-use-state, max-lines-per-function, max-statements */
import { useEffect, useState } from "react";
import HomeVideoReview from "./HomeVideoReview";
import axios from "axios";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useMutation } from "@apollo/client";
const { homeVideoReviews: { ADD_HOME_VIDEO_REVIEW } } = mutations;
const handleChangeId = (setId) => (ev) => {
    ev.preventDefault();
    // @ts-ignore
    setId(ev.currentTarget.value);
};
const HOSTNAME = "http://localhost";
const PORT = 3000;
const handleClear = ({ setAvForumsId, setAvNirvanaId, setAvsForumId, setBluRayAuthorityId, setBluRayId, setDigitalBitsId, setHidefDigestId, setHomeTheaterForumId, setId, setOMDbId, setName }) => (ev) => {
    ev.preventDefault();
    setAvForumsId("");
    setAvNirvanaId("");
    setAvsForumId("");
    setBluRayAuthorityId("");
    setBluRayId("");
    setDigitalBitsId("");
    setHidefDigestId("");
    setHomeTheaterForumId("");
    setId("");
    setOMDbId("");
    setName("");
};
const handleAdd = (addHomeVideoReview) => (ev) => {
    ev.preventDefault();
    addHomeVideoReview();
};
const handleUpdate = ({ OMDbId, avForumsId, avNirvanaId, avsForumId, bluRayAuthorityId, bluRayId, digitalBitsId, doBluId, hidefDigestId, homeTheaterForumId, id, setPreview, whySoBluId }) => async (ev) => {
    ev.preventDefault();
    setPreview({ message: `Loading Home Video Review...` });
    const { data } = await axios.put(`${HOSTNAME}:${PORT}/mightychroma/${id}`, {
        OMDbId: OMDbId || undefined,
        avForumsId: avForumsId || undefined,
        avNirvanaId: avNirvanaId || undefined,
        avsForumId: avsForumId || undefined,
        bluRayAuthorityId: bluRayAuthorityId || undefined,
        bluRayId: bluRayId || undefined,
        digitalBitsId: digitalBitsId || undefined,
        doBluId: doBluId || undefined,
        hidefDigestId: hidefDigestId || undefined,
        homeTheaterForumId: homeTheaterForumId || undefined,
        whySoBluId: whySoBluId || undefined
    });
    setPreview(data);
};
const useInputs = (props) => {
    const [OMDbId, setOMDbId] = useState("");
    const [id, setId] = useState("");
    const [addHomeVideoReview, { data, loading }] = useMutation(ADD_HOME_VIDEO_REVIEW, {
        variables: {
            homeVideoReview: {
                OMDbId,
                avForumsId: props.avForumsId || undefined,
                avNirvanaId: props.avNirvanaId || undefined,
                avsForumId: props.avsForumId || undefined,
                bluRayAuthorityId: props.bluRayAuthorityId || undefined,
                bluRayId: props.bluRayId || undefined,
                digitalBitsId: props.digitalBitsId || undefined,
                doBluId: props.doBluId || undefined,
                hidefDigestId: props.hidefDigestId || undefined,
                homeTheaterForumId: props.homeTheaterForumId || undefined,
                imageServiceName: props.imageServiceName,
                isBluRay: true,
                isPublished: false,
                isUHD: props.isUHD,
                name: props.name,
                whySoBluId: props.whySoBluId || undefined
            }
        }
    });
    useEffect(() => {
        if (loading) {
            props.setPreview({ message: `Loading Mighty Chroma...` });
        }
        if (data) {
            props.setPreview(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading]);
    const onToggleIsUHD = () => {
        props.toggleIsUHD();
    };
    return {
        ...props,
        OMDbId,
        id,
        loading,
        // @ts-ignore
        onAdd: handleAdd(addHomeVideoReview),
        // @ts-ignore
        onChangeId: handleChangeId,
        // @ts-ignore
        onClear: handleClear({ ...props, setId, setOMDbId }),
        onToggleIsUHD,
        // @ts-ignore
        onUpdate: handleUpdate({ ...props, OMDbId, id }),
        setId,
        setOMDbId
    };
};
export default prepareComponent(useInputs)(HomeVideoReview);
