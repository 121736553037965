/* eslint-disable no-ternary */

import { Buttons, Label, Left, Main, Wrapper } from "./styled"
import { ConfirmModal, Modal } from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."

const Item = ({
  expandedItem,
  icon,
  isExpanded,
  isModalOpen,
  isShowDelete,
  isShowView,
  label,
  onModalClose,
  onModalConfirm,
  onModalOpen,
  onView
}: ComponentProps) => (
  <Wrapper>
    <Main>
      <Left $hasIcon={Boolean(icon)}>
        {icon ? icon : null}
        <Label>{label}</Label>
      </Left>
      <Buttons>
        {isShowDelete ? (
          <Button label="Delete" onClick={onModalOpen} outlined />
        ) : null}
        {isShowView ? <Button label="View" onClick={onView} /> : null}
      </Buttons>
    </Main>
    {isExpanded ? expandedItem : null}
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      <ConfirmModal
        message={`Are you sure you want to delete ${label}?`}
        onClose={onModalClose}
        onConfirm={onModalConfirm}
      />
    </Modal>
  </Wrapper>
)

export default Item
