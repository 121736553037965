/* eslint-disable no-ternary, max-statements, max-lines-per-function */

import { FormEventHandler, RefObject, useRef, useState } from "react"
import {
  ForumCategory,
  ForumCategoryUpdateInput
} from "@bluframe/mightychroma-mightytool-frontend-types"
import Item from "./Item"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type ModalContent = "deleteConfirm" | "saveConfirm" | null

export interface Props {
  isAdd?: boolean
  isEditing?: boolean
  item?: ForumCategory
  onCancel: () => void
  onDelete?: () => void
  onEdit?: () => void
  // eslint-disable-next-line no-unused-vars
  onSave: (category: ForumCategoryUpdateInput["category"]) => void
}

export interface ComponentProps
  extends Omit<
    Props,
    "isAdd" | "isEditing" | "onCancel" | "onDelete" | "onEdit" | "onSave"
  > {
  actionButtonLabel: string
  actionButtonOnClick?: () => void
  descriptionRef: RefObject<HTMLTextAreaElement>
  isModalOpen: boolean
  isEditMode: boolean
  modalContent: ModalContent
  nameRef: RefObject<HTMLInputElement>
  onModalClose: () => void
  onModalDeleteConfirm: () => void
  onModalSaveConfirm: () => void
  onSubmit: FormEventHandler
  orderRef: RefObject<HTMLInputElement>
  outlinedButtonLabel: string
  outlinedButtonOnClick?: () => void
  sectionIdRef: RefObject<HTMLInputElement>
  slugRef: RefObject<HTMLInputElement>
}

const DEFAULT_MODAL_CONTENT = null

const usePrepareComponent = ({
  isAdd,
  isEditing,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  ...props
}: Props): ComponentProps => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContent>(
    DEFAULT_MODAL_CONTENT
  )

  const descriptionRef = useRef<HTMLTextAreaElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const orderRef = useRef<HTMLInputElement>(null)
  const sectionIdRef = useRef<HTMLInputElement>(null)
  const slugRef = useRef<HTMLInputElement>(null)

  const isEditMode = Boolean(isAdd || isEditing)

  const onModalClose = () => {
    setModalContent(DEFAULT_MODAL_CONTENT)
    setIsModalOpen(false)
  }

  const onModalConfirmOpen = (newModalContent: ModalContent) => () => {
    setModalContent(newModalContent)
    setIsModalOpen(true)
  }

  const onModalDeleteConfirm = () => {
    onDelete?.()
    onModalClose()
  }

  const onModalSaveConfirm = () => {
    if (
      !descriptionRef.current ||
      !nameRef.current ||
      !orderRef.current ||
      !sectionIdRef.current ||
      !slugRef.current
    ) {
      return
    }

    onSave({
      description: descriptionRef.current?.value,
      name: nameRef.current?.value,
      order: Number(orderRef.current?.value),
      sectionId: sectionIdRef.current?.value,
      slug: slugRef.current?.value
    })

    onModalClose()
  }

  const actionButtonLabel = isEditMode ? "Save" : "Delete"
  const outlinedButtonLabel = isEditMode ? "Cancel" : "Edit"

  const actionButtonOnClick = isEditMode
    ? undefined
    : onModalConfirmOpen("deleteConfirm")
  const outlinedButtonOnClick = isEditMode ? onCancel : onEdit

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    onModalConfirmOpen("saveConfirm")()
  }

  return {
    ...props,
    actionButtonLabel,
    actionButtonOnClick,
    descriptionRef,
    isEditMode,
    isModalOpen,
    modalContent,
    nameRef,
    onModalClose,
    onModalDeleteConfirm,
    onModalSaveConfirm,
    onSubmit,
    orderRef,
    outlinedButtonLabel,
    outlinedButtonOnClick,
    sectionIdRef,
    slugRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
