import { ContentComponents, ContentProps } from "@bluframe/blublocks"
import Content from "./Content"
import prepareComponent from "@bluframe/grapple/prepareComponent"

interface Props extends ContentProps {}

export interface ComponentProps extends ContentProps {}

export const usePrepareComponent = ({
  components: overrideComponents = {},
  content,
  max = Infinity,
  paragraphAs,
  stripMarkdown
}: Props): ComponentProps => {
  // If we ever need our own shared overrides
  const components: ContentComponents = {}

  return {
    components: {
      ...components,
      ...overrideComponents
    },
    content,
    max,
    paragraphAs,
    stripMarkdown
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Content
)
