import {
  ServiceLabel,
  ServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"

const serviceNameLabels: Record<ServiceName, ServiceLabel> = {
  avForums: "AV Forums",
  avNirvana: "AV Nirvana",
  avsForum: "AVS Forum",
  bluRay: "Blu-ray.com",
  bluRayAuthority: "Blu-ray Authority",
  digitalBits: "Digital Bits",
  doBlu: "Do Blu",
  hidefDigest: "High-Def Digest",
  homeTheaterForum: "Home Theater Forum",
  whySoBlu: "Why So Blu?"
}

export default serviceNameLabels
