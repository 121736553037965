import { Props as AutosuggestProps } from "components/Autosuggest"
import EditActions from "./EditActions"
import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  homeVideoReviewTitles: HomeVideoReviewSearchItem[]
  isUpdateHomeVideoReviewEnabled: boolean
  label: string
  loading: boolean
  onClearAll: () => void
  onGetSuggestions: AutosuggestProps["onGetSuggestions"]
  // eslint-disable-next-line no-unused-vars
  onSelectHomeVideoReviewId: (id: string, name: string) => void
  onUpdateHomeVideoReview: () => void
  selectedHomeVideoReviewId: string
}

export interface ComponentProps extends Props {}

const usePrepareComponent = ({
  homeVideoReviewTitles,
  ...props
}: Props): ComponentProps => {
  const options = homeVideoReviewTitles
    // MAS can be null
    .filter((homeVideoReviewTitle) => Boolean(homeVideoReviewTitle.mas))

  return {
    ...props,
    homeVideoReviewTitles: options
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  EditActions
)
