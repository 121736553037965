import { H1 as BluBlocksH1 } from "@bluframe/blublocks";
import styled from "styled-components";
export const Wrapper = styled.div `
  display: grid;
  justify-items: start;
  padding: 20px 40px;
  row-gap: 20px;
`;
export const H1 = styled(BluBlocksH1) `
  font-size: 24px;
`;
