import contests from "./contests"
import dashboard from "./dashboard"
import forum from "./forum"
import homeVideoReviews from "./homeVideoReviews"

export default {
  contests,
  dashboard,
  forum,
  homeVideoReviews
}
