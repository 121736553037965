/* eslint-disable no-ternary */

import {
  H1 as BluBlocksH1,
  H2 as BluBlocksH2,
  H3 as BluBlocksH3,
  Paragraph as BluBlocksParagraph,
  SubtitleH1 as BluBlocksSubtitleH1,
  SubtitleH2 as BluBlocksSubtitleH2,
  SubtitleH3 as BluBlocksSubtitleH3,
  Theme
} from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Form = styled.form`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 40px;
  width: 100%;
`

export const Top = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const TitleAndSLug = styled.div<{ $isEditMode: boolean }>`
  display: grid;
  row-gap: ${({ $isEditMode }) => ($isEditMode ? "20px" : "10px")};
`

export const H1 = styled(BluBlocksH1)`
  font-size: 22px;
  margin: 0;
`

export const SubtitleH1 = styled(BluBlocksSubtitleH1)`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`

export const H2 = styled(BluBlocksH2)`
  font-size: 18px;
  margin: 0;
`

export const SubtitleH2 = styled(BluBlocksSubtitleH2)`
  font-size: 16px;
  margin: 0;
`

export const Slug = styled(BluBlocksParagraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
`

export const Buttons = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Main = styled.div`
  display: grid;
  row-gap: 20px;
`

export const ImageAndRules = styled.div<{ $hasImage: boolean }>`
  align-items: center;
  column-gap: 20px;
  display: grid;
  ${({ $hasImage }) => ($hasImage ? "grid-template-columns: 120px 1fr;" : "")}

  ${mediaQueries.tablet} {
    column-gap: 40px;
    ${({ $hasImage }) => ($hasImage ? "grid-template-columns: 180px 1fr;" : "")}
  }
`

export const Image = styled.img``

export const Inputs = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto auto auto 1fr;
  justify-items: start;
  row-gap: 20px;
`

export const InputAndImage = styled.div`
  display: grid;
  row-gap: 20px;
`

export const InputsRules = styled.div`
  display: grid;
  row-gap: 10px;
`

export const Preview = styled.div``

export const UsersCount = styled.div`
  display: grid;
  justify-items: end;
  justify-self: end;
  row-gap: 2px;
`

export const EnrolledUsersCount = styled(BluBlocksH3)`
  font-size: 18px;
  margin: 0;
`

export const OptedOutUsersCount = styled(BluBlocksSubtitleH3)`
  font-size: 16px;
  margin: 0;
`
