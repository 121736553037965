import { gql } from "graphql-tag"

const query = gql`
  type Query {
    getHomeVideoReviews(isUHD: Boolean): [HomeVideoReview]
    getHomeVideoReview(id: ID!): HomeVideoReview
  }
`

export default query
