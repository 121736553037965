import gql from "graphql-tag"
import homeVideoReview from "fragments/homeVideoReview"

const GET_DRAFTS = gql`
  ${homeVideoReview}

  query getDrafts {
    drafts {
      ...HomeVideoReview
    }
  }
`
export default GET_DRAFTS
