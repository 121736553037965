/* eslint-disable no-ternary, no-nested-ternary */

import { LoadingWrapper, Wrapper } from "./styled"
import { green, grey, red } from "@material-ui/core/colors"
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone"
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone"
import { Loading } from "@bluframe/blublocks"
import { Props } from "."
import RemoveCircleTwoToneIcon from "@material-ui/icons/RemoveCircleTwoTone"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const COLOR_WEIGHT = 500

const useStyles = makeStyles({
  iconDisabled: {
    color: grey[COLOR_WEIGHT]
  },
  iconGreen: {
    color: green[COLOR_WEIGHT]
  },
  iconRed: {
    color: red[COLOR_WEIGHT]
  },
  text: {
    color: grey[COLOR_WEIGHT]
  }
})

const Check = ({ disabled, isGreen, isLoading, label }: Props) => {
  const classes = useStyles()

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingWrapper>
          <Loading size={18} />
        </LoadingWrapper>
      ) : isGreen ? (
        <CheckCircleTwoToneIcon className={classes.iconGreen} />
      ) : disabled ? (
        <RemoveCircleTwoToneIcon className={classes.iconDisabled} />
      ) : (
        <CancelTwoToneIcon className={classes.iconRed} />
      )}
      {label ? (
        <Typography className={classes.text} component="p">
          {label}
        </Typography>
      ) : null}
    </Wrapper>
  )
}
export default Check
