import { gql } from "graphql-tag"

const homeVideoReview = gql`
  input HomeVideoReviewAddInput {
    "OMDb Id"
    OMDbId: ID
    "AVForums Id"
    avForumsId: ID
    "AVNirvana Id"
    avNirvanaId: ID
    "AVSForum Id"
    avsForumId: ID
    "BluRay Id"
    bluRayId: ID
    "BluRayAuthority Id"
    bluRayAuthorityId: ID
    "Digital Bits Id"
    digitalBitsId: ID
    "Do Blu Id"
    doBluId: ID
    "HiDefDigest Id"
    hidefDigestId: ID
    "HomeTheaterForum Id"
    homeTheaterForumId: ID
    "Image Service Name"
    imageServiceName: String
    "Is it a Blu Ray"
    isBluRay: Boolean!
    "Is it Published"
    isPublished: Boolean!
    "Is it UHD"
    isUHD: Boolean!
    "Name"
    name: String
    "Why So Blu? Id"
    whySoBluId: ID
  }

  type HomeVideoReviewAddResponse {
    "Status"
    status: String!
  }

  type PublishDraftHomeVideoReviewResponse {
    "IDs"
    ids: [ID!]!
  }

  input HomeVideoReviewUpdateInput {
    "OMDB Id"
    OMDbId: ID
    "AVForums Id"
    avForumsId: ID
    "AVNirvana Id"
    avNirvanaId: ID
    "AVSForum Id"
    avsForumId: ID
    "BluRay Id"
    bluRayId: ID
    "BluRayAuthority Id"
    bluRayAuthorityId: ID
    "Digital Bits Id"
    digitalBitsId: ID
    "Do Blu Id"
    doBluId: ID
    "HiDefDigest Id"
    hidefDigestId: ID
    "HomeTheaterForum Id"
    homeTheaterForumId: ID
    "Id"
    id: ID!
    "Is it a Blu Ray"
    isBluRay: Boolean
    "Is it Published"
    isPublished: Boolean
    "Is it UHD"
    isUHD: Boolean
    "Name"
    name: String
    "Why So Blu? Id"
    whySoBluId: ID
  }

  type HomeVideoReviewUpdateResponse {
    "Home Video Review Id"
    id: ID!
  }

  type HomeVideoReviewRegenerateSummaryResponse {
    "AVForums Id"
    avForumsId: ID
    "AVNirvana Id"
    avNirvanaId: ID
    "AVSForum Id"
    avsForumId: ID
    "BluRay Id"
    bluRayId: ID
    "BluRayAuthority Id"
    bluRayAuthorityId: ID
    "Digital Bits Id"
    digitalBitsId: ID
    "Do Blu Id"
    doBluId: ID
    "Editions"
    editions: [String]
    "HiDefDigest Id"
    hidefDigestId: ID
    "Summary Id"
    homeVideoReviewSummaryId: ID
    "Home Video Review Id"
    id: ID!
    "Is it a Blu Ray"
    isBluRay: Boolean!
    "Is it Published"
    isPublished: Boolean!
    "Is it UHD"
    isUHD: Boolean!
    "MAS Id"
    masId: ID!
    "Why So Blu? Id"
    whySoBluId: ID
  }

  type HomeVideoReview {
    "AVForums Review"
    avForums: Review
    "AVNirvana Review"
    avNirvana: Review
    "AVSForum Review"
    avsForum: Review
    "BluRayCom Review"
    bluRay: Review
    "BluRayAuthority Review"
    bluRayAuthority: Review
    "Digital Bits Review"
    digitalBits: Review
    "Do Blu Review"
    doBlu: Review
    "Editions"
    editions: [String]
    "HiDefDigest Review"
    hidefDigest: Review
    "HomeTheaterForum Review"
    homeTheaterForum: Review
    "Id"
    id: ID!
    "Images"
    images: HomeVideoImages
    "Is Blu-ray?"
    isBluRay: Boolean!
    "Is it Published"
    isPublished: Boolean!
    "Is UHD?"
    isUHD: Boolean!
    "MAS Data"
    mas: MAS
    "Name"
    name: String
    "Release Date"
    releaseDate: String!
    "Summary"
    summary: HomeVideoReviewSummary
    "WhySoBlu Review"
    whySoBlu: Review
  }

  type HomeVideoReviewWave {
    cursor: String
    hasPrevData: Boolean
    hasNextData: Boolean!
    homeVideoReviews: [HomeVideoReview]!
    prevCursor: String
  }

  input SearchHomeVideoReviewsInput {
    isUHD: Boolean
    title: String
  }

  type HomeVideoReviewsCount {
    bluRay: Int!
    drafts: Int!
    fourK: Int!
    total: Int!
  }
`

export default homeVideoReview
