import { gql } from "graphql-tag"

const forum = gql`
  type Mutation {
    addCategory(category: CategoryAddInput!): CategoryAddResponse!
    addSection(section: SectionAddInput!): SectionAddResponse!
    deleteCategory(id: ID!): CategoryDeleteResponse!
    deleteSection(id: ID!): SectionDeleteResponse!
    updateCategory(category: CategoryUpdateInput!): CategoryUpdateResponse!
    updateSection(section: SectionUpdateInput!): SectionUpdateResponse!
  }
`

export default forum
