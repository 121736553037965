import { gql } from "graphql-tag"

const mas = gql`
  input MASAddInput {
    actors: String!
    country: String!
    director: String!
    imdbId: String!
    language: String!
    poster: String!
    rating: String!
    releaseDate: String!
    runtime: String!
    title: String!
    writer: String!
    year: String!
  }

  type MAS {
    actors: String!
    country: String!
    director: String!
    id: ID!
    imdbId: String!
    language: String!
    plot: String
    poster: Image!
    rating: String!
    releaseDate: String!
    runtime: String!
    title: String!
    writer: String!
    year: String!
  }

  type PreviewMASResponse {
    current: MAS
    preview: MAS
  }
`

export default mas
