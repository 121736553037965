import { ComponentProps } from "."
import { Icon } from "@bluframe/blublocks"
import { Link } from "react-router-dom"
import colors from "styles/colors"

const Arrow = ({ ariaLabel, iconName, to }: ComponentProps) => (
  <Link aria-label={ariaLabel} to={to}>
    <Icon ariaHidden color={colors.gray} name={iconName} size="36px" />
  </Link>
)

export default Arrow
