import gql from "graphql-tag";
const GET_CHARACTER = gql `
  query getCharacter($id: ID!) {
    getCharacter(id: $id) {
      bio
      firstName
      id
      lastName
      writingInstructions {
        conclusion
        expectedOutput
        intro
        objective
        styleToAdopt
        outputAndStyleReminderFrequency
        writingSamples {
          after
          before
          samples
        }
      }
    }
  }
`;
export default GET_CHARACTER;
