import gql from "graphql-tag"

const GET_DASHBOARD_COUNT_USER = gql`
  query getDashboardCountUser {
    getDashboardCountUser {
      homeVideoReviews {
        bluRay
        drafts
        fourK
        total
      }
    }
  }
`

export default GET_DASHBOARD_COUNT_USER
