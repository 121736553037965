import { ContentComponents } from "@bluframe/blublocks"
import Item from "./Item"
import { Paragraph } from "./styled"
import prepareComponent from "@bluframe/grapple/prepareComponent"

interface Props {
  isMarkdown?: boolean
  isNotLast: boolean
  item: string
}

export interface ComponentProps extends Props {
  components: ContentComponents
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const components: ContentComponents = {
    // eslint-disable-next-line react/no-multi-comp, react/display-name, react/no-unstable-nested-components, id-length
    p: ({ node, ...restProps }) => <Paragraph {...restProps} />
  }

  return {
    ...props,
    components
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
