/* eslint-disable no-ternary, no-nested-ternary */

import { Right, Title, Wrapper } from "./styled"
import { ComponentProps } from "."
import Detail from "./Detail"
import Image from "components/Image"

const Details = ({
  actors,
  country,
  director,
  language,
  plot,
  poster,
  rating,
  releaseDate,
  runtime,
  title,
  writer
}: ComponentProps) => (
  <Wrapper>
    {poster ? (
      <Image
        alternativeText={poster.alternativeText}
        formats={poster.formats}
        height={poster.height}
        name={poster.name}
        url={poster.url}
        width={poster.width}
      />
    ) : null}
    <Right>
      <Title>{title}</Title>
      <Detail label="Director" text={director} />
      <Detail label="Actors" text={actors} />
      {plot ? (
        <Detail isExpandable isMarkdown label="Plot" text={plot} />
      ) : null}
      <Detail label="Writers" text={writer} />
      <Detail label="Release Date" text={releaseDate} />
      <Detail label="Runtime" text={runtime} />
      <Detail label="Rating" text={rating} />
      <Detail label="Country" text={country} />
      <Detail label="Language" text={language} />
    </Right>
  </Wrapper>
)

export default Details
