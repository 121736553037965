export const onCreateReview = (createReview, isReady) => {
    if (isReady) {
        createReview();
    }
};
const createReviews = ({ avForumsReady, avNirvanaReady, avsForumReady, bluRayAuthorityReady, bluRayReady, createAvForums, createAvNirvana, createAvsForum, createBluRay, createBluRayAuthority, createDigitalBits, createDoBlu, createHidefDigest, createHomeTheaterForum, createWhySoBlu, digitalBitsReady, doBluReady, hidefDigestReady, homeTheaterForumReady, whySoBluReady }) => {
    onCreateReview(createAvForums, avForumsReady);
    onCreateReview(createAvNirvana, avNirvanaReady);
    onCreateReview(createAvsForum, avsForumReady);
    onCreateReview(createBluRay, bluRayReady);
    onCreateReview(createBluRayAuthority, bluRayAuthorityReady);
    onCreateReview(createDigitalBits, digitalBitsReady);
    onCreateReview(createDoBlu, doBluReady);
    onCreateReview(createHidefDigest, hidefDigestReady);
    onCreateReview(createHomeTheaterForum, homeTheaterForumReady);
    onCreateReview(createWhySoBlu, whySoBluReady);
};
export default createReviews;
