import gql from "graphql-tag"

const UPDATE_SECTION = gql`
  mutation updateSection($section: SectionUpdateInput!) {
    updateSection(section: $section) {
      id
    }
  }
`

export default UPDATE_SECTION
