/* eslint-disable no-ternary */

import { Reset, Theme } from "@bluframe/blublocks"
import Paragraph from "components/Typography/Paragraph"
import { rgba } from "polished"
import styled from "styled-components"

const IMAGE_AND_SERVICE_LABEL_HOVER_ALPHA = 0.4
const IMAGE_AND_SERVICE_LABEL_SELECTED_ALPHA = 0.6

export const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }: { theme: Theme }) =>
    theme.palette.primary.contrast};
  column-gap: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: start;

  & p {
    margin-bottom: 0;
  }
`

export const Ul = styled(Reset.Ul)`
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`

export const Image = styled.img`
  height: 200px;
`

export const ModalWrapper = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 30px;
`

export const ImageAndServiceLabel = styled.div<{ $isSelected: boolean }>`
  ${({ $isSelected, theme }: { $isSelected: boolean; theme: Theme }) =>
    $isSelected
      ? `
    background: ${rgba(
      theme.palette.primary.main,
      IMAGE_AND_SERVICE_LABEL_SELECTED_ALPHA
    )};
`
      : ""} cursor: pointer;
  display: grid;
  justify-items: center;
  padding: 20px 20px 10px;
  row-gap: 10px;
  transition: background 0.2s ease;

  &:hover {
    background: ${({
      $isSelected,
      theme
    }: {
      $isSelected: boolean
      theme: Theme
    }) =>
      $isSelected
        ? ""
        : rgba(theme.palette.text.light, IMAGE_AND_SERVICE_LABEL_HOVER_ALPHA)};
  }
`

export const ServiceLabel = styled(Paragraph)<{ $isSelected: boolean }>`
  color: ${({ $isSelected, theme }: { $isSelected: boolean; theme: Theme }) =>
    $isSelected ? theme.palette.primary.contrast : theme.palette.subtitle.main};
  font-size: 14px;
  margin: 0;
`
