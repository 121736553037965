import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"
import { MouseEventHandler } from "react"
import Suggestion from "./Suggestion"
import { UseComboboxGetItemPropsReturnValue } from "downshift"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props extends HomeVideoReviewSearchItem {
  itemProps: UseComboboxGetItemPropsReturnValue
  // eslint-disable-next-line no-unused-vars
  onSuggestionClick: (id: string, name: string) => void
}

export interface ComponentProps extends Omit<Props, "onSuggestionClick"> {
  onClick: MouseEventHandler
}

export const usePrepareComponent = (props: Props): ComponentProps => {
  const onClick: MouseEventHandler = (event) => {
    // Downshift onClick
    props.itemProps.onClick?.(event)

    // onSuggestionClick
    props.onSuggestionClick(props.id, `${props.mas.title} (${props.mas.year})`)
  }

  return {
    ...props,
    onClick
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Suggestion
)
