/* eslint-disable max-statements, max-lines-per-function */
import { useState } from "react";
import { handleAdd, handleGetAll, handlePreview, handleUpdateFromUrl } from "./handlers";
import { useLazyQuery, useMutation } from "@apollo/client";
import CREATE_PREVIEW from "mutations/critics/createPreview";
import CREATE_REVIEW from "mutations/critics/createReview";
import GET_REVIEWS from "queries/getReviews";
import Source from "./Source";
import UPDATE_CRITICS_REVIEW_FROM_URL from "mutations/critics/updateCriticsReviewFromUrl";
import prepareComponent from "@bluframe/grapple/prepareComponent";
const handleChangeId = 
// eslint-disable-next-line no-unused-vars
(setId) => (ev) => {
    ev.preventDefault();
    setId(ev.currentTarget.value);
};
const handleChangeUrl = 
// eslint-disable-next-line no-unused-vars
(setUrl) => (ev) => {
    ev.preventDefault();
    setUrl(ev.currentTarget.value);
};
const usePrepareComponent = (props) => {
    const [url, setUrl] = useState("");
    const [id, setId] = useState("");
    const [createPreview, { data: createPreviewData, loading: createPreviewLoading }] = useMutation(CREATE_PREVIEW, {
        variables: {
            previewInput: { isUHD: props.isUHD, serviceName: props.name, url }
        }
    });
    const [getReviews, { data: reviewsData, loading: getReviewsLoading }] = useLazyQuery(GET_REVIEWS, {
        variables: { serviceName: props.name, url }
    });
    const [createReview, { data: reviewData, loading: createReviewLoading }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: { isUHD: props.isUHD, serviceName: props.name, url }
        }
    });
    const [updateReviewFromUrl, { data: updateReviewFromUrlData, loading: updateReviewFromUrlLoading }] = useMutation(UPDATE_CRITICS_REVIEW_FROM_URL, {
        variables: {
            id,
            serviceName: props.name,
            url
        }
    });
    const loadingMessage = `Loading: ${url}...`;
    if (props.focusedService === props.name) {
        if ((createPreviewLoading || createReviewLoading || getReviewsLoading) &&
            props.preview.message !== loadingMessage) {
            props.setPreview({ message: loadingMessage });
        }
        if (props.focusedData === "preview" && createPreviewData) {
            props.setPreview(createPreviewData.createPreview);
        }
        if (props.focusedData === "createReview" && reviewData) {
            props.setPreview(reviewData.createReview);
            props.setHomeVideoReviewId(reviewData.createReview.id);
        }
        if (props.focusedData === "reviews" && reviewsData) {
            props.setPreview(reviewsData.reviews);
        }
        if (props.focusedData === "updateFromUrl" && updateReviewFromUrlData) {
            props.setPreview({
                message: `Updating Critics Review from URL: ${url}...`
            });
        }
    }
    return {
        ...props,
        createPreviewLoading,
        createReviewLoading,
        getReviewsLoading,
        id,
        onAdd: handleAdd(props, createReview),
        onChangeId: handleChangeId(setId),
        onChangeUrl: handleChangeUrl(setUrl),
        onGetAll: handleGetAll({ ...props, getReviews }),
        onPreview: handlePreview(props, createPreview),
        onUpdate: handleUpdateFromUrl(props, updateReviewFromUrl, url),
        setUrl,
        updateReviewFromUrlLoading,
        url
    };
};
export default prepareComponent(usePrepareComponent)(Source);
