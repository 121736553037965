import gql from "graphql-tag";
const SEND_EMAILS_TEMPLATES = gql `
  mutation sendEmailsTemplate(
    $emailsSendTemplateInput: EmailsSendTemplateInput!
  ) {
    sendEmailsTemplate(emailsSendTemplateInput: $emailsSendTemplateInput) {
      status
    }
  }
`;
export default SEND_EMAILS_TEMPLATES;
