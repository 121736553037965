import gql from "graphql-tag";
const GET_ME = gql `
  query getMe {
    me {
      firstName
      isAdmin
      username
    }
  }
`;
export default GET_ME;
