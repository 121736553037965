import { gql } from "graphql-tag"

const emailPreferences = gql`
  input EmailPreferencesUpdateInput {
    isContestsEmails: Boolean
    isLikeEmails: Boolean
    isNewReleasesEmails: Boolean
    isNewsletterEmails: Boolean
    isReplyEmails: Boolean
  }

  type EmailPreferencesUpdateResponse {
    id: ID!
  }

  type EmailPreferences {
    isContestsEmails: Boolean
    isLikeEmails: Boolean
    isNewReleasesEmails: Boolean
    isNewsletterEmails: Boolean
    isReplyEmails: Boolean
  }
`

export default emailPreferences
