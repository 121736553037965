import gql from "graphql-tag";
const GET_NEWSLETTER_AND_SUBCRIBERS_COUNT = gql `
  query getNewsletterAndSubscribersCount($id: ID!) {
    getNewsletterAndSubscribersCount(id: $id) {
      emailPreferences
      id
      isPublished
      name
      placeholders
      templateId
      usersOptedOutCount
      usersSubscribedCount
    }
  }
`;
export default GET_NEWSLETTER_AND_SUBCRIBERS_COUNT;
