import { IconProps } from "@bluframe/blublocks"
import SubItem from "./SubItem"

export interface Props {
  iconName?: IconProps["name"]
  label: string
  link: string
}

export interface ComponentProps extends Props {}

export default SubItem
