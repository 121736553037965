import gql from "graphql-tag"

const ADD_CATEGORY = gql`
  mutation addCategory($category: CategoryAddInput!) {
    addCategory(category: $category) {
      id
    }
  }
`

export default ADD_CATEGORY
