/* eslint-disable max-lines-per-function, no-empty-function, max-lines */
import reducer, { DEFAULT_HOME_VIDEO_REVIEW, RESET_SELECTED_REVIEW, RESET_SELECTED_REVIEWS, SET_IMAGE_SERVICE_NAME, SET_IS_ALL_AROUND_GATHER, SET_IS_MATCH_TITLE, SET_IS_ONLY_NEW, SET_IS_ONLY_PRESENT, SET_IS_SHOW_FLAGGED, SET_IS_TOGGLE_FLAGGED_LOADING, SET_IS_UHD, SET_LIST, SET_NAME, SET_NEXT_CURSOR, SET_OMDB_ID, SET_OVERRIDES, SET_SELECTED_HOME_VIDEO_REVIEW, SET_SELECTED_ID, SET_SELECTED_IS_PREVIEW_LOADING, SET_SELECTED_IS_PREVIEW_VALID, SET_SELECTED_IS_REVIEW_CREATED, initialState } from "./reducer";
import { useReducer } from "react";
export { DEFAULT_HOME_VIDEO_REVIEW };
export const initialContext = {
    clear: () => { },
    clearAll: () => { },
    resetSelectedReviews: () => { },
    setImageServiceName: () => { },
    setIsAllAroundGather: () => { },
    setIsMatchTitle: () => { },
    setIsOnlyNew: () => { },
    setIsOnlyPresent: () => { },
    setIsShowFlagged: () => { },
    setIsToggleFlaggedLoading: () => { },
    setIsUHD: () => { },
    setList: () => { },
    setName: () => { },
    setNextCursor: () => { },
    setOMDbId: () => { },
    setOverrides: () => { },
    setSelectedHomeVideoReview: () => { },
    setSelectedId: () => { },
    setSelectedIsPreviewLoading: () => { },
    setSelectedIsPreviewValid: () => { },
    setSelectedIsReviewCreated: () => { },
    state: initialState
};
const useReviewsContext = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const resetSelectedReviews = () => {
        dispatch({ type: RESET_SELECTED_REVIEWS });
    };
    const setNextCursor = (nextCursor, service) => {
        dispatch({
            payload: { nextCursor, service },
            type: SET_NEXT_CURSOR
        });
    };
    const setOMDbId = (OMDbId) => {
        dispatch({
            payload: { OMDbId },
            type: SET_OMDB_ID
        });
    };
    const clear = (serviceName) => {
        dispatch({
            payload: { service: serviceName },
            type: RESET_SELECTED_REVIEW
        });
    };
    const clearAll = () => {
        resetSelectedReviews();
        setOMDbId("");
    };
    const setImageServiceName = (imageServiceName) => {
        dispatch({
            payload: { imageServiceName },
            type: SET_IMAGE_SERVICE_NAME
        });
    };
    return {
        clear,
        clearAll,
        resetSelectedReviews,
        setImageServiceName,
        setIsAllAroundGather: (isAllAroundGather, serviceName) => {
            dispatch({
                payload: { isAllAroundGather, service: serviceName },
                type: SET_IS_ALL_AROUND_GATHER
            });
        },
        setIsMatchTitle: (isMatchTitle) => {
            dispatch({ payload: { isMatchTitle }, type: SET_IS_MATCH_TITLE });
        },
        setIsOnlyNew: (isOnlyNew, serviceName) => {
            dispatch({
                payload: { isOnlyNew, service: serviceName },
                type: SET_IS_ONLY_NEW
            });
        },
        setIsOnlyPresent: (isOnlyPresent, serviceName) => {
            dispatch({
                payload: { isOnlyPresent, service: serviceName },
                type: SET_IS_ONLY_PRESENT
            });
        },
        setIsShowFlagged: (isShowFlagged, serviceName) => {
            dispatch({
                payload: { isShowFlagged, service: serviceName },
                type: SET_IS_SHOW_FLAGGED
            });
        },
        setIsToggleFlaggedLoading: (isToggleFlaggedLoading) => {
            dispatch({
                payload: { isToggleFlaggedLoading },
                type: SET_IS_TOGGLE_FLAGGED_LOADING
            });
        },
        setIsUHD: (isUHD) => {
            dispatch({
                payload: { isUHD },
                type: SET_IS_UHD
            });
        },
        setList: (list, serviceName) => {
            dispatch({
                payload: { list, service: serviceName },
                type: SET_LIST
            });
        },
        setName: (name) => {
            dispatch({ payload: { name }, type: SET_NAME });
        },
        setNextCursor,
        setOMDbId,
        setOverrides: (overrides, serviceName) => {
            dispatch({
                payload: { overrides, serviceName },
                type: SET_OVERRIDES
            });
        },
        setSelectedHomeVideoReview: ({ id, isPublished, serviceName, title, url }) => {
            dispatch({
                payload: { id, isPublished, service: serviceName, title, url },
                type: SET_SELECTED_HOME_VIDEO_REVIEW
            });
        },
        setSelectedId: (selected, serviceName) => {
            dispatch({
                payload: { selected, service: serviceName },
                type: SET_SELECTED_ID
            });
        },
        setSelectedIsPreviewLoading: (isPreviewLoading, serviceName) => {
            dispatch({
                payload: { isPreviewLoading, service: serviceName },
                type: SET_SELECTED_IS_PREVIEW_LOADING
            });
        },
        setSelectedIsPreviewValid: (isPreviewValid, serviceName) => {
            dispatch({
                payload: { isPreviewValid, service: serviceName },
                type: SET_SELECTED_IS_PREVIEW_VALID
            });
        },
        setSelectedIsReviewCreated: (id, serviceName) => {
            dispatch({
                payload: { id, service: serviceName },
                type: SET_SELECTED_IS_REVIEW_CREATED
            });
        },
        state
    };
};
export default useReviewsContext;
