import { gql } from "graphql-tag"

const newsletter = gql`
  input NewsletterAddInput {
    emailPreferences: String!
    isPublished: Boolean
    name: String!
    placeholders: String
    templateId: String
  }

  input NewsletterUpdateInput {
    emailPreferences: String
    isPublished: Boolean
    name: String
    placeholders: String
    templateId: String
  }

  type NewsletterAddResponse {
    id: ID!
  }

  type NewsletterUpdateResponse {
    id: ID!
  }

  type NewsletterDeleteResponse {
    status: String!
  }

  type Newsletter {
    emailPreferences: String!
    id: ID!
    isPublished: Boolean!
    name: String!
    placeholders: String
    templateId: String
  }

  type NewsletterAndSubscribersCount {
    emailPreferences: String!
    id: ID!
    isPublished: Boolean!
    name: String!
    placeholders: String
    templateId: String
    usersOptedOutCount: Int!
    usersSubscribedCount: Int!
  }
`

export default newsletter
