/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */
import { useLazyQuery, useMutation } from "@apollo/client";
import ADD_NEWSLETTER from "mutations/newsletters/addNewsletter";
import Add from "./Add";
import GET_NEWSLETTERS from "queries/newsletters/getNewsletters";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
const usePrepareComponent = () => {
    const history = useHistory();
    const [addContent, { loading }] = useMutation(ADD_NEWSLETTER, {
        refetchQueries: [GET_NEWSLETTERS]
    });
    const [getContents] = useLazyQuery(GET_NEWSLETTERS);
    const onCancel = () => {
        history.push(`/newsletters`);
    };
    const onSave = (itemAdd) => {
        addContent({
            onCompleted: () => {
                getContents({
                    fetchPolicy: "network-only",
                    onCompleted: () => {
                        history.push(`/newsletters`);
                    }
                });
            },
            variables: {
                newsletterAddInput: {
                    ...itemAdd,
                    emailPreferences: itemAdd.emailPreferences ?? "",
                    name: itemAdd.name ?? "",
                    placeholders: itemAdd.placeholders
                        ? JSON.stringify(itemAdd.placeholders)
                        : undefined
                }
            }
        });
    };
    return {
        loading,
        onCancel,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Add);
