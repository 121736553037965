import { Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Wrapper = styled.div`
  .c3-axis-x path,
  .c3-axis-y path {
    display: none;
  }

  .c3-axis-x .tick line,
  .c3-axis-y .tick line {
    display: none;
  }

  .c3-xgrid-focus {
    display: none;
  }

  .c3-tooltip td {
    border: none !important;
  }

  .c3-tooltip {
    box-shadow: none !important;
  }

  .c3-tooltip th {
    background: ${({ theme }: { theme: Theme }) =>
      theme.palette.text.contrast} !important;
    border: none !important;
    color: ${({ theme }: { theme: Theme }) =>
      theme.palette.text.main} !important;
  }

  .c3-tooltip,
  .c3-tooltip-container {
    border: none;
  }

  /* Change the font size of the legend items */
  .c3-legend-item {
    font-size: 16px;
  }

  /* Change the font size of the x-axis date labels */
  .c3-axis-x .tick text {
    font-size: 10px;

    ${mediaQueries.largeDesktop} {
      font-size: 12px;
    }
  }
`
