/* eslint-disable no-underscore-dangle */
const typePolicies = {
    Query: {
        fields: {
            eightLeggedFreakList: {
                // Specify keyArgs field to tell Apollo Client
                // how to differentiate between different lists
                keyArgs: [
                    "eightLeggedFreakListInput",
                    ["isUHD", "matchTitle", "serviceName"]
                ],
                // Concatenate the incoming list items with
                // the existing list items.
                // @ts-ignore
                // eslint-disable-next-line default-param-last
                merge(existing = { links: [] }, incoming) {
                    // Create a map of the existing list items
                    const resultsMap = new Map(existing.links.map((result) => [result.__ref, result]));
                    // Add incoming list items to the map
                    incoming.links.forEach((result) => {
                        resultsMap.set(result.__ref, result);
                    });
                    // Return a new list of list items
                    return {
                        links: Array.from(resultsMap.values()),
                        nextCursor: incoming.nextCursor
                    };
                }
            }
        }
    }
};
export default typePolicies;
