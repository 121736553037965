import { SubtitleH3 as BluBlocksSubtitleH3, Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const SubtitleH3 = styled(BluBlocksSubtitleH3)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
  font-weight: 500;
  line-height: 1.1;
  font-size: 24px;
  font-family: "Roboto Condensed Bold", Roboto, sans-serif;
  margin-top: 0;

  ${mediaQueries.tablet} {
    font-size: 26px;
  }
`

export default SubtitleH3
