import { Image } from "@bluframe/mightychroma-mightytool-frontend-types"
import imageLoader from "helpers/images/imageLoader"
import imagesConfig from "config/images"

const { DEVICE_SIZES, IMAGE_SIZES } = imagesConfig

const generateSrcSet = (image: Pick<Image, "formats" | "url">) => {
  const sizes = [
    { name: "xsmall", size: IMAGE_SIZES.xsmall },
    { name: "thumbnail", size: IMAGE_SIZES.thumbnail },
    { name: "small", size: DEVICE_SIZES.mobile },
    { name: "small", size: IMAGE_SIZES.small },
    { name: "medium", size: DEVICE_SIZES.tablet },
    { name: "medium", size: IMAGE_SIZES.medium },
    { name: "large", size: DEVICE_SIZES.desktop },
    { name: "large", size: IMAGE_SIZES.large },
    { name: "xlarge", size: IMAGE_SIZES.xlarge }
  ]

  const srcSet = sizes
    .map(({ size }) => {
      const url = imageLoader({ image, width: size })
      return `${url} ${size}w`
    })
    .join(", ")

  return srcSet
}

export default generateSrcSet
