import Item from "./Item"

export interface Props {
  flagLoading: boolean
  id: string
  isFlagged: boolean
  isShowFlagButton: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  url: string
}

export interface ComponentProps extends Props {}

export default Item
