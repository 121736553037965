import { gql } from "graphql-tag"

const emails = gql`
  input EmailsFooterAddInput {
    html: String!
    name: String!
  }

  input EmailsFooterUpdateInput {
    html: String
    name: String
  }

  type EmailsFooterDeleteResponse {
    status: String!
  }

  type EmailsFooter {
    html: String
    id: ID!
    name: String
  }

  input EmailsHeadingAddInput {
    html: String!
    name: String!
  }

  input EmailsHeadingUpdateInput {
    html: String
    name: String
  }

  type EmailsHeadingDeleteResponse {
    status: String!
  }

  type EmailsHeading {
    html: String
    id: ID!
    name: String
  }

  input EmailsTemplateAddInput {
    footerId: ID
    headingId: ID
    html: String!
    identifier: String!
    name: String!
    placeholders: [String!]
    subject: String!
  }

  input EmailsTemplateUpdateInput {
    footerId: ID
    headingId: ID
    html: String
    identifier: String
    name: String
    placeholders: [String!]
    subject: String
  }

  type EmailsTemplateDeleteResponse {
    status: String!
  }

  type EmailsTemplate {
    footer: EmailsFooter
    heading: EmailsHeading
    html: String!
    id: ID!
    identifier: String!
    name: String!
    placeholders: [String!]
    subject: String!
  }

  input EmailFrom {
    address: String!
    name: String!
  }

  input Email {
    from: EmailFrom!
    templateData: String
    templateId: ID!
    toAddresses: [String!]
  }

  input EmailsSendTemplateInput {
    email: Email!
    emailPreferences: String!
  }

  type EmailsSendTemplateResponse {
    status: String!
  }
`

export default emails
