import emailsTemplate from "queries/fragments/emailsTemplate";
import gql from "graphql-tag";
const GET_EMAILS_TEMPLATES = gql `
  ${emailsTemplate}

  query getEmailsTemplates {
    getEmailsTemplates {
      ...EmailsTemplate
    }
  }
`;
export default GET_EMAILS_TEMPLATES;
