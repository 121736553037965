import ImageSelector from "./ImageSelector"
import { ModalProps } from "@bluframe/blublocks"
import { ServiceName } from "@bluframe/mightychroma-mightytool-frontend-types"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useState } from "react"

export interface Image {
  serviceName: ServiceName
  url: string
}

export interface Props {
  imageServiceNameSelected: ServiceName
  images: Image[]
  modal: Omit<ModalProps, "children">
  onSelectClick: () => void
  // eslint-disable-next-line no-unused-vars
  onSelectFrontImage: (serviceName: ServiceName) => void
}

export interface ComponentProps extends Omit<Props, "onSelectFrontImage"> {
  imageServiceNameSelected: ServiceName
  onConfirm: () => void
  // eslint-disable-next-line no-unused-vars
  onSelectImage: (serviceName: ServiceName) => () => void
}

export const usePrepareComponent = (props: Props): ComponentProps => {
  const [newImageServiceNameSelected, setNewImageServiceNameSelected] =
    useState<ServiceName>(() => props.imageServiceNameSelected)

  const onConfirm = () => {
    props.onSelectFrontImage(newImageServiceNameSelected)

    props.modal.onClose()
  }

  const onSelectImage = (serviceName: ServiceName) => () => {
    if (serviceName === newImageServiceNameSelected) {
      return
    }

    setNewImageServiceNameSelected(serviceName)
  }

  return {
    ...props,
    imageServiceNameSelected: newImageServiceNameSelected,
    onConfirm,
    onSelectImage
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  ImageSelector
)
