import Navigation from "./Navigation"

export interface Props {
  isAdmin: boolean
  isExpanded: boolean
  name: string
  onToggleIsExpanded: () => void
}

export interface ComponentProps extends Props {}

export default Navigation
