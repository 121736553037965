import ADD_CATEGORY from "./addCategory"
import ADD_SECTION from "./addSection"
import DELETE_CATEGORY from "./deleteCategory"
import DELETE_SECTION from "./deleteSection"
import UPDATE_CATEGORY from "./updateCategory"
import UPDATE_SECTION from "./updateSection"

export default {
  ADD_CATEGORY,
  ADD_SECTION,
  DELETE_CATEGORY,
  DELETE_SECTION,
  UPDATE_CATEGORY,
  UPDATE_SECTION
}
