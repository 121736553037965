import { IconProps } from "@bluframe/blublocks"
import Item from "./Item"
import { Props as SubItemProps } from "./SubItem"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useToggle from "@bluframe/grapple/useToggle"

interface SubItem extends Omit<SubItemProps, "isExpanded"> {
  isAdmin?: boolean
}

export interface Props {
  iconGroup?: IconProps["group"]
  iconName: IconProps["name"]
  isAdmin: boolean
  isExpanded: boolean
  label: string
  link?: string
  subItems?: SubItem[]
}

export interface ComponentProps extends Props {
  isShowSubItems: boolean
  onToggleIsShowSubItems: () => void
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const [isShowSubItems, toggleIsShowSubItems] = useToggle()

  const onToggleIsShowSubItems = () => {
    toggleIsShowSubItems()
  }

  return {
    ...props,
    isShowSubItems,
    onToggleIsShowSubItems
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
