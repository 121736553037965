import { gql } from "graphql-tag"

const author = gql`
  type Author {
    "Author Name"
    name: String
    "Author Url"
    url: String
  }

  input AuthorInput {
    name: String!
    url: String!
  }
`

export default author
