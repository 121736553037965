import gql from "graphql-tag";
const GET_REVIEWS = gql `
  query getReviews($serviceName: String!) {
    reviews(serviceName: $serviceName) {
      audio {
        quote
        score
      }
      author {
        name
        url
      }
      extra {
        quote
        score
      }
      movie {
        quote
        score
      }
      releaseDate
      total {
        quote
        score
      }
      url
      video {
        quote
        score
      }
    }
  }
`;
export default GET_REVIEWS;
