import IsCompact from "./IsCompact"

export interface Props {
  checked: boolean
  isDisabled: boolean
  onChange: () => void
}

export interface ComponentProps extends Props {}

export default IsCompact
