import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const BigNumber = styled.p`
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;
  font-family: "Roboto Condensed Bold", Roboto, sans-serif;
  margin: 0;

  ${mediaQueries.tablet} {
    font-size: 38px;
  }

  ${mediaQueries.desktop} {
  }

  ${mediaQueries.largeDesktop} {
  }
`

export default BigNumber
