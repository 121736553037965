import gql from "graphql-tag";
const CREATE_REVIEW = gql `
  mutation createReview($createReviewInput: CreateReviewInput!) {
    createReview(createReviewInput: $createReviewInput) {
      id
      serviceName
    }
  }
`;
export default CREATE_REVIEW;
