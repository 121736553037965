/* eslint-disable max-lines-per-function */
export const getIsServiceValid = (url, isPreviewValid) => (url && isPreviewValid) || !url;
export const getIsServiceCreated = (url, isPreviewValid, id) => (Boolean(url) && isPreviewValid && Boolean(id)) || !url;
export const getIsAddHomeVideoReviewEnabled = ({ homeVideoReview, isOmdbIdValid, services }) => Boolean(isOmdbIdValid || homeVideoReview.name) &&
    homeVideoReview.imageServiceName &&
    services[homeVideoReview.imageServiceName] &&
    Boolean(services[homeVideoReview.imageServiceName].url) &&
    services[homeVideoReview.imageServiceName].isPreviewValid &&
    services[homeVideoReview.imageServiceName] &&
    getIsServiceValid(services.avForums.url, services.avForums.isPreviewValid) &&
    getIsServiceValid(services.avNirvana.url, services.avNirvana.isPreviewValid) &&
    getIsServiceValid(services.avsForum.url, services.avsForum.isPreviewValid) &&
    getIsServiceValid(services.bluRay.url, services.bluRay.isPreviewValid) &&
    getIsServiceValid(services.bluRayAuthority.url, services.bluRayAuthority.isPreviewValid) &&
    getIsServiceValid(services.digitalBits.url, services.digitalBits.isPreviewValid) &&
    getIsServiceValid(services.doBlu.url, services.doBlu.isPreviewValid) &&
    getIsServiceValid(services.hidefDigest.url, services.hidefDigest.isPreviewValid) &&
    getIsServiceValid(services.homeTheaterForum.url, services.homeTheaterForum.isPreviewValid) &&
    getIsServiceValid(services.whySoBlu.url, services.whySoBlu.isPreviewValid);
export const getIsHomeVideoReviewReadyToBeCreated = ({ avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, homeVideoReview, whySoBlu }) => Boolean(homeVideoReview.OMDbId || homeVideoReview.name) &&
    getIsServiceCreated(avForums.url, avForums.isPreviewValid, homeVideoReview.avForumsId) &&
    getIsServiceCreated(avNirvana.url, avNirvana.isPreviewValid, homeVideoReview.avNirvanaId) &&
    getIsServiceCreated(avsForum.url, avsForum.isPreviewValid, homeVideoReview.avsForumId) &&
    getIsServiceCreated(bluRay.url, bluRay.isPreviewValid, homeVideoReview.bluRayId) &&
    getIsServiceCreated(bluRayAuthority.url, bluRayAuthority.isPreviewValid, homeVideoReview.bluRayAuthorityId) &&
    getIsServiceCreated(digitalBits.url, digitalBits.isPreviewValid, homeVideoReview.digitalBitsId) &&
    getIsServiceCreated(doBlu.url, doBlu.isPreviewValid, homeVideoReview.doBluId) &&
    getIsServiceCreated(hidefDigest.url, hidefDigest.isPreviewValid, homeVideoReview.hidefDigestId) &&
    getIsServiceCreated(homeTheaterForum.url, homeTheaterForum.isPreviewValid, homeVideoReview.homeTheaterForumId) &&
    getIsServiceCreated(whySoBlu.url, whySoBlu.isPreviewValid, homeVideoReview.whySoBluId);
