import { useLazyQuery, useMutation } from "@apollo/client";
import GET_ME from "queries/getMe";
import LOGIN from "mutations/login";
import Login from "./Login";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const history = useHistory();
    const { onSetToken } = useUser();
    const [login, { loading }] = useMutation(LOGIN);
    const [getMe] = useLazyQuery(GET_ME);
    const onLoginCompleted = ({ login: { token } }) => {
        onSetToken(token);
        getMe({
            fetchPolicy: "network-only",
            onCompleted: () => {
                history.push("/");
            }
        });
    };
    const onSubmit = ({ password, username }) => {
        login({
            onCompleted: onLoginCompleted,
            variables: { loginInput: { password, username } }
        });
    };
    return {
        isLoading: loading,
        onSubmit
    };
};
export default prepareComponent(usePrepareComponent)(Login);
