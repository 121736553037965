import gql from "graphql-tag"

const mas = gql`
  fragment MAS on MAS {
    id
    imdbId
    title
    year
  }
`

export default mas
