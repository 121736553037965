import { FormEventHandler, RefObject, useRef } from "react"
import Item from "./Item"
import { PreviewMASResponse } from "@bluframe/mightychroma-mightytool-frontend-types"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  isAdd?: boolean
  isPreviewLoading: boolean
  isSubmitLoading: boolean
  item?: PreviewMASResponse
  onCancel: () => void
  // eslint-disable-next-line no-unused-vars
  onPreview: (imdbId: string) => void
  // eslint-disable-next-line no-unused-vars
  onSubmit: (imdbId: string) => void
}

export interface ComponentProps extends Omit<Props, "onPreview" | "onSubmit"> {
  imdbIdRef: RefObject<HTMLInputElement>
  onPreview: () => void
  onSubmit: FormEventHandler
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const imdbIdRef = useRef<HTMLInputElement>(null)

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    if (!imdbIdRef.current) {
      return
    }

    props.onSubmit(imdbIdRef.current.value)
  }

  const onPreview = () => {
    if (!imdbIdRef.current) {
      return
    }
    props.onPreview(imdbIdRef.current.value)
  }

  return {
    ...props,
    imdbIdRef,
    onPreview,
    onSubmit
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
