import { Theme } from "@bluframe/blublocks"
import Typography from "components/Typography"
import styled from "styled-components"

export const Wrapper = styled.div``

export const LabelAndValue = styled.div`
  display: grid;
  overflow-x: hidden;
`

export const Label = styled(Typography.Paragraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.light};
  font-size: 12px;
  margin: 0;

  & svg {
    height: 12px;
    width: 12px;
  }
`

export const Value = styled(Typography.Paragraph)`
  font-size: 16px;
  margin: 0;
`

export const InfoText = styled(Typography.Paragraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.info.main};
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`
