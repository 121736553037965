/**
 * Validate service by checking if the given URL, preview state, and ID are valid.
 * @function getIsServiceValid
 * @param {string} url - The URL string to check its validity.
 * @param {boolean} isPreviewValid - The flag to indicate if the preview is valid or not.
 * @param {string} id - The ID string to validate.
 * @return {boolean} - If true, the service is considered valid; otherwise, false.
 */
const getIsServiceValid = (url, isPreviewValid, id) => {
    // If there's a URL and (preview is valid or there's an ID) or no URL,
    // return true (service valid); otherwise, return false (service invalid)
    return (url && Boolean(isPreviewValid || id)) || !url;
};
export default getIsServiceValid;
