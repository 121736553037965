import { Li, Ul } from "./styled"
import { ComponentProps } from "."
import Draft from "components/Drafts/Draft"

const Drafts = ({ drafts }: ComponentProps) => (
  <Ul aria-label="Drafts">
    {drafts.map((draft) => (
      <Li aria-label={draft.title} key={draft.id}>
        <Draft {...draft} />
      </Li>
    ))}
  </Ul>
)

export default Drafts
