import gql from "graphql-tag"

const GET_CONTESTS = gql`
  query getContests {
    getContests {
      id
      title
    }
  }
`

export default GET_CONTESTS
