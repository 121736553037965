import Checkbox from "./Checkbox";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useMemo } from "react";
import useReviews from "hooks/useReviews";
const onChange = (checked, serviceName, setValue) => () => {
    setValue(!checked, serviceName);
};
const usePrepareComponent = ({ inputId, isNeedsDisplayList = true, label, serviceName, setValueName, valueName }) => {
    const reviewsContext = useReviews();
    const { state } = reviewsContext;
    const isDisplayList = useMemo(() => Boolean(state[serviceName].display.length), [state, serviceName]);
    return {
        checked: state[serviceName][valueName],
        inputId,
        isDisplayList,
        isNeedsDisplayList,
        label,
        name: valueName,
        onChange: onChange(state[serviceName][valueName], serviceName, reviewsContext[setValueName])
    };
};
export default prepareComponent(usePrepareComponent)(Checkbox);
