import React, { useState } from "react"
import Item from "./Item"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  expandedItem?: React.ReactNode
  icon?: React.ReactNode
  id: string
  isExpanded?: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onDelete?: (id: string) => void
  // eslint-disable-next-line no-unused-vars
  onView?: (id: string) => void
}

export interface ComponentProps
  extends Omit<Props, "id" | "onDelete" | "onView"> {
  isModalOpen: boolean
  isShowDelete: boolean
  isShowView: boolean
  onModalClose: () => void
  onModalConfirm: () => void
  onModalOpen: () => void
  onView: () => void
}

const usePrepareComponent = ({ id, ...props }: Props): ComponentProps => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const onModalOpen = () => {
    setIsModalOpen(true)
  }

  const onModalConfirm = () => {
    props.onDelete?.(id)
    onModalClose()
  }

  const onView = () => {
    props.onView?.(id)
  }

  return {
    ...props,
    isModalOpen,
    isShowDelete: Boolean(props.onDelete),
    isShowView: Boolean(props.onView),
    onModalClose,
    onModalConfirm,
    onModalOpen,
    onView
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
