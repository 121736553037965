import AddActions from "./AddActions";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useAddHomeVideoReview from "hooks/useAddHomeVideoReview";
import useImageSelector from "hooks/useImageSelector";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const { OMDbId, isAddHomeVideoReviewEnabled, isOmdbIdValid, loading, onAddHomeVideoReview, onClearAll, name } = useAddHomeVideoReview();
    const { setOMDbId, setName } = useReviews();
    const { isAdmin } = useUser();
    const imageSelector = useImageSelector();
    const onOmdbIdChange = (newOMDbId) => {
        setOMDbId(newOMDbId);
    };
    const onNameChange = (newName) => {
        setName(newName);
    };
    return {
        OMDbId,
        imageSelector,
        isAddHomeVideoReviewEnabled,
        isAdmin,
        isOmdbIdValid,
        loading,
        name,
        onAddHomeVideoReview,
        onClearAll,
        onNameChange,
        onOmdbIdChange
    };
};
export default prepareComponent(usePrepareComponent)(AddActions);
