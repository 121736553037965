import blog from "./blog"
import forum from "./forum"
import { gql } from "graphql-tag"

const mutation = gql`
  ${blog}
  ${forum}

  type Mutation {
    addContest(contestAddInput: ContestAddInput!): ContestAddResponse!
    addEmailsHeading(
      emailsHeadingAddInput: EmailsHeadingAddInput!
    ): EmailsHeading
    addEmailsFooter(emailsFooterAddInput: EmailsFooterAddInput!): EmailsFooter
    addEmailsTemplate(
      emailsTemplateAddInput: EmailsTemplateAddInput!
    ): EmailsTemplate
    addHomeVideoReview(
      homeVideoReview: HomeVideoReviewAddInput!
    ): HomeVideoReviewAddResponse!
    addMAS(masAddInput: MASAddInput!): MAS!
    addMASFromImdbId(imdbId: String!): MAS!
    addNewsletter(
      newsletterAddInput: NewsletterAddInput!
    ): NewsletterAddResponse!

    createCharacter(characterInput: CharacterAddInput!): CharacterAddResponse!

    createHomeVideoReviewsImagesGrid(
      homeVideoReviewsIds: [ID!]!
    ): CreateImagesGridResponse!

    createReview(createReviewInput: CreateReviewInput!): ReviewResponse!

    createPreview(previewInput: PreviewInput!): Preview!

    deleteContest(id: ID!): ContestDeleteResponse!

    deleteEmailsHeading(id: ID!): EmailsHeadingDeleteResponse!
    deleteEmailsFooter(id: ID!): EmailsFooterDeleteResponse!
    deleteEmailsTemplate(id: ID!): EmailsTemplateDeleteResponse
    deleteNewsletter(id: ID!): NewsletterDeleteResponse!

    eightLeggedFreakGather(
      eightLeggedFreakGatherInput: EightLeggedFreakGatherInput!
    ): EightLeggedFreakGather

    eightLeggedFreakReviewUpdate(
      review: EightLeggedFreakReviewInput!
      serviceName: String!
    ): EightLeggedFreakReviewResponse

    login(loginInput: LoginInput!): LoginResponse!

    previewMAS(imdbId: String!): PreviewMASResponse!

    publishDraftHomeVideoReview(
      ids: [ID!]!
    ): PublishDraftHomeVideoReviewResponse!

    regenerateHomeVideoReviewSummary(
      id: ID!
    ): HomeVideoReviewRegenerateSummaryResponse!

    sendEmailsTemplate(
      emailsSendTemplateInput: EmailsSendTemplateInput!
    ): EmailsSendTemplateResponse!

    updateCharacter(
      characterInput: CharacterUpdateInput!
    ): CharacterUpdateResponse!

    updateContest(
      id: ID!
      contestUpdateInput: ContestUpdateInput!
    ): ContestUpdateResponse!

    updateEmailsHeading(
      id: ID!
      emailsHeadingUpdateInput: EmailsHeadingUpdateInput!
    ): EmailsHeading
    updateEmailsFooter(
      id: ID!
      emailsFooterUpdateInput: EmailsFooterUpdateInput!
    ): EmailsFooter
    updateEmailsTemplate(
      id: ID!
      emailsTemplateUpdateInput: EmailsTemplateUpdateInput!
    ): EmailsTemplate
    updateMASFromImdbId(imdbId: String!): MAS!
    updateNewsletter(
      id: ID!
      newsletterUpdateInput: NewsletterUpdateInput!
    ): NewsletterUpdateResponse!

    updateHomeVideoReview(
      homeVideoReview: HomeVideoReviewUpdateInput!
    ): HomeVideoReviewUpdateResponse!

    updateReview(serviceName: String!, review: ReviewInput!): ReviewResponse!
    updateCriticsReviewFromUrl(
      id: ID!
      url: String!
      serviceName: String!
    ): ReviewResponse!
  }
`

export default mutation
