import gql from "graphql-tag"
import section from "fragments/section"

const GET_SECTIONS = gql`
  ${section}

  query sections($createdAt: String) {
    sections(createdAt: $createdAt) {
      ...Section
    }
  }
`
export default GET_SECTIONS
