/* eslint-disable max-lines-per-function, max-statements */
import Edit from "./Edit";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useCreateReviews from "hooks/useCreateReviews";
import useReviews from "hooks/useReviews";
import { useState } from "react";
import useUser from "hooks/useUser";
const DEFAULT_IS_COMPACT = true;
const usePrepareComponent = () => {
    const { clear, setIsMatchTitle, setOverrides, setIsUHD, state: { avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, isMatchTitle, isUHD, homeVideoReview, whySoBlu } } = useReviews();
    const { isAdmin } = useUser();
    const [isCompact, setIsCompact] = useState(DEFAULT_IS_COMPACT);
    const { areReviewsLoading } = useCreateReviews();
    const onClear = (serviceName) => () => {
        clear(serviceName);
    };
    const onSetOverrides = (serviceName) => (overrides) => {
        setOverrides(overrides, serviceName);
    };
    const isMatchTitleCheckboxProps = {
        checked: isMatchTitle,
        isDisabled: areReviewsLoading,
        onChange: () => {
            setIsMatchTitle(!isMatchTitle);
        }
    };
    const isUHDCheckboxProps = {
        checked: isUHD,
        isDisabled: areReviewsLoading,
        onChange: () => {
            setIsUHD(!isUHD);
        }
    };
    const isCompactCheckboxProps = {
        checked: isCompact,
        isDisabled: areReviewsLoading,
        onChange: () => {
            setIsCompact(!isCompact);
        }
    };
    const reviewsControlCheckboxesProps = {
        isAdmin,
        isCompactCheckboxProps,
        isMatchTitleCheckboxProps,
        isUHDCheckboxProps
    };
    return {
        areReviewsLoading,
        avForums: avForums.selected,
        avNirvana: avNirvana.selected,
        avsForum: avsForum.selected,
        bluRay: bluRay.selected,
        bluRayAuthority: bluRayAuthority.selected,
        digitalBits: digitalBits.selected,
        doBlu: doBlu.selected,
        hidefDigest: hidefDigest.selected,
        homeTheaterForum: homeTheaterForum.selected,
        homeVideoReview,
        onClear,
        onSetOverrides,
        reviewsControlCheckboxesProps,
        whySoBlu: whySoBlu.selected
    };
};
export default prepareComponent(usePrepareComponent)(Edit);
