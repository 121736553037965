import Dashboard from "./Dashboard"
import { Props as SectionProps } from "./Section"

export interface Props {
  isLoading: boolean
  sections: SectionProps[]
}

export interface ComponentProps extends Props {
  // ComponentProps
}

export default Dashboard
