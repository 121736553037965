import { Logo, Wrapper } from "./styled"
import { Login as BluBlocksLogin } from "@bluframe/blublocks"
import { ComponentProps } from "."

const Login = ({ isLoading, onSubmitLogin }: ComponentProps) => (
  <Wrapper>
    <Logo
      alt="Mighty Chroma Logo"
      src="https://snowflakes.blufra.me/sites/mightychroma.me/content/xsmall_Logo_Social_Circle_Bold_6c5a8b3ecc.png"
    />
    <BluBlocksLogin
      isLoading={isLoading}
      name="Welcome to Mighty Chroma"
      onSubmitLogin={onSubmitLogin}
    />
  </Wrapper>
)

export default Login
