import gql from "graphql-tag";
const GET_SITE_USERS_BY_CONTEST_ID = gql `
  query getSiteUsersByContestId($contestId: ID!) {
    getSiteUsersByContestId(contestId: $contestId) {
      email
      emailPreferences {
        isContestsEmails
        isLikeEmails
        isNewReleasesEmails
        isNewsletterEmails
        isReplyEmails
      }
      id
      username
    }
  }
`;
export default GET_SITE_USERS_BY_CONTEST_ID;
