/* eslint-disable no-ternary, max-lines-per-function, no-underscore-dangle */
import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import config from "config/graphql";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import typePolicies from "./typePolicies";
const { HOST, PATH, PORT } = config;
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
        // eslint-disable-next-line no-console
        console.log(graphQLErrors);
    }
});
const authLink = setContext((__, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
        }
    };
});
const TIMEOUT = 720000;
const graphql = () => new ApolloClient({
    cache: new InMemoryCache({
        typePolicies
    }),
    link: from([
        errorLink,
        authLink,
        new HttpLink({
            fetchOptions: {
                timeout: TIMEOUT
            },
            uri: `${HOST}:${PORT}${PATH}`
        })
    ])
});
export default graphql;
