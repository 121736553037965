import { gql } from "graphql-tag"

const homeVideoReviewSummary = gql`
  type LongAndShort {
    long: String
    short: String
  }

  type HomeVideoReviewSummary {
    audio: LongAndShort
    extra: LongAndShort
    movie: LongAndShort
    total: LongAndShort
    updatedAt: String
    video: LongAndShort
  }

  input LongAndShortInput {
    long: String
    short: String
  }

  input HomeVideoReviewSummaryInput {
    audio: LongAndShortInput
    extra: LongAndShortInput
    movie: LongAndShortInput
    total: LongAndShortInput
    updatedAt: String
    video: LongAndShortInput
  }
`

export default homeVideoReviewSummary
