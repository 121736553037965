import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 15px;
  width: 100%;
`

export const Box = styled.div`
  width: 28px;
`
