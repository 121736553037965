import { Props as ItemProps } from "./Item"
import List from "./List"
import { Props as PaginationProps } from "components/Pagination"

export interface Props {
  hasAdd?: boolean
  items?: ItemProps[]
  label: string
  loading?: boolean
  onAdd: () => void
  pagination?: PaginationProps
}

export interface ComponentProps extends Props {}

export default List
