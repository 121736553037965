/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, complexity, max-lines */

import { Buttons, Form, H1, H2, HeadingAndContent, Main, Top } from "./styled"
import {
  ConfirmModal,
  Content,
  InputText,
  Modal,
  TextArea
} from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  descriptionRef,
  isEditMode,
  isModalOpen,
  item,
  modalContent,
  nameRef,
  onModalClose,
  onModalDeleteConfirm,
  onModalSaveConfirm,
  onSubmit,
  orderRef,
  outlinedButtonLabel,
  outlinedButtonOnClick
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      {isEditMode ? (
        <InputText
          defaultValue={item?.name}
          inputId="name"
          label="Name"
          name="name"
          ref={nameRef}
          required
        />
      ) : (
        <H1>{item?.name}</H1>
      )}
      <Buttons>
        <Button
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          label={actionButtonLabel}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
      </Buttons>
    </Top>
    <Main>
      {isEditMode ? (
        <>
          <InputText
            defaultValue={String(item?.order)}
            inputId="order"
            label="Order"
            name="order"
            ref={orderRef}
            required
            type="number"
          />
          <TextArea
            defaultValue={item?.description}
            inputId="description"
            label="Description"
            name="description"
            ref={descriptionRef}
            required
          />
        </>
      ) : item?.description ? (
        <>
          <HeadingAndContent>
            <H2>{"Description"}</H2>
            <Content content={item?.description} />
          </HeadingAndContent>
          <HeadingAndContent>
            <H2>{"Order"}</H2>
            <Content content={String(item?.order)} />
          </HeadingAndContent>
        </>
      ) : null}
    </Main>
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      {modalContent === "deleteConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to delete ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalDeleteConfirm}
        />
      ) : null}
      {modalContent === "saveConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to save${
            item?.name ? ` ${item.name}` : ""
          }?`}
          onClose={onModalClose}
          onConfirm={onModalSaveConfirm}
        />
      ) : null}
    </Modal>
  </Form>
)

export default Item
