/* eslint-disable max-lines-per-function, no-empty-function, max-statements, max-lines */
import { useCallback, useMemo } from "react";
import CREATE_REVIEW from "mutations/critics/createReview";
import createReviews from "./createReviews";
import { useMutation } from "@apollo/client";
export const initialContext = {
    areReviewsCalled: false,
    areReviewsLoading: false,
    avForumsData: {},
    avNirvanaData: {},
    avsForumData: {},
    bluRayAuthorityData: {},
    bluRayData: {},
    digitalBitsData: {},
    doBluData: {},
    hidefDigestData: {},
    homeTheaterForumData: {},
    onCreateReviews: () => { },
    resetReviews: () => { },
    whySoBluData: {}
};
const useCreateReviewsContext = ({ avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, isUHD, homeVideoReview, whySoBlu }) => {
    const [createAvForums, { called: avForumsCalled, data: avForumsData, loading: avForumsLoading, reset: avForumsReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: avForums.selected.overrides,
                serviceName: "avForums",
                url: avForums.selected.url
            }
        }
    });
    const [createAvNirvana, { called: avNirvanaCalled, data: avNirvanaData, loading: avNirvanaLoading, reset: avNirvanaReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: avNirvana.selected.overrides,
                serviceName: "avNirvana",
                url: avNirvana.selected.url
            }
        }
    });
    const [createAvsForum, { called: avsForumCalled, data: avsForumData, loading: avsForumLoading, reset: avsForumReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: avsForum.selected.overrides,
                serviceName: "avsForum",
                url: avsForum.selected.url
            }
        }
    });
    const [createBluRay, { called: bluRayCalled, data: bluRayData, loading: bluRayLoading, reset: bluRayReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: bluRay.selected.overrides,
                serviceName: "bluRay",
                url: bluRay.selected.url
            }
        }
    });
    const [createBluRayAuthority, { called: bluRayAuthorityCalled, data: bluRayAuthorityData, loading: bluRayAuthorityLoading, reset: bluRayAuthorityReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: bluRayAuthority.selected.overrides,
                serviceName: "bluRayAuthority",
                url: bluRayAuthority.selected.url
            }
        }
    });
    const [createDigitalBits, { called: digitalBitsCalled, data: digitalBitsData, loading: digitalBitsLoading, reset: digitalBitsReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: digitalBits.selected.overrides,
                serviceName: "digitalBits",
                url: digitalBits.selected.url
            }
        }
    });
    const [createDoBlu, { called: doBluCalled, data: doBluData, loading: doBluLoading, reset: doBluReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: doBlu.selected.overrides,
                serviceName: "doBlu",
                url: doBlu.selected.url
            }
        }
    });
    const [createHidefDigest, { called: hidefDigestCalled, data: hidefDigestData, loading: hidefDigestLoading, reset: hidefDigestReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: hidefDigest.selected.overrides,
                serviceName: "hidefDigest",
                url: hidefDigest.selected.url
            }
        }
    });
    const [createHomeTheaterForum, { called: homeTheaterForumCalled, data: homeTheaterForumData, loading: homeTheaterForumLoading, reset: homeTheaterForumReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: homeTheaterForum.selected.overrides,
                serviceName: "homeTheaterForum",
                url: homeTheaterForum.selected.url
            }
        }
    });
    const [createWhySoBlu, { called: whySoBluCalled, data: whySoBluData, loading: whySoBluLoading, reset: whySoBluReset }] = useMutation(CREATE_REVIEW, {
        variables: {
            createReviewInput: {
                isUHD,
                overrides: whySoBlu.selected.overrides,
                serviceName: "whySoBlu",
                url: whySoBlu.selected.url
            }
        }
    });
    const areReviewsCalled = useMemo(() => avForumsCalled ||
        avNirvanaCalled ||
        avsForumCalled ||
        bluRayCalled ||
        bluRayAuthorityCalled ||
        digitalBitsCalled ||
        doBluCalled ||
        hidefDigestCalled ||
        homeTheaterForumCalled ||
        whySoBluCalled, [
        avForumsCalled,
        avNirvanaCalled,
        avsForumCalled,
        bluRayCalled,
        bluRayAuthorityCalled,
        digitalBitsCalled,
        doBluCalled,
        hidefDigestCalled,
        homeTheaterForumCalled,
        whySoBluCalled
    ]);
    const areReviewsLoading = useMemo(() => avForumsLoading ||
        avNirvanaLoading ||
        avsForumLoading ||
        bluRayLoading ||
        bluRayAuthorityLoading ||
        digitalBitsLoading ||
        doBluLoading ||
        hidefDigestLoading ||
        homeTheaterForumLoading ||
        whySoBluLoading, [
        avForumsLoading,
        avNirvanaLoading,
        avsForumLoading,
        bluRayLoading,
        bluRayAuthorityLoading,
        digitalBitsLoading,
        doBluLoading,
        hidefDigestLoading,
        homeTheaterForumLoading,
        whySoBluLoading
    ]);
    const resetReviews = () => {
        if (avForumsData) {
            avForumsReset();
        }
        if (avNirvanaData) {
            avNirvanaReset();
        }
        if (bluRayData) {
            bluRayReset();
        }
        if (bluRayAuthorityData) {
            bluRayAuthorityReset();
        }
        if (digitalBitsData) {
            digitalBitsReset();
        }
        if (doBluData) {
            doBluReset();
        }
        if (hidefDigestData) {
            hidefDigestReset();
        }
        if (homeTheaterForumData) {
            homeTheaterForumReset();
        }
        if (avsForumData) {
            avsForumReset();
        }
        if (whySoBluData) {
            whySoBluReset();
        }
    };
    const onCreateReviews = useCallback(() => {
        createReviews({
            avForumsReady: Boolean(avForums.selected.url) && !homeVideoReview.avForumsId,
            avNirvanaReady: Boolean(avNirvana.selected.url) && !homeVideoReview.avNirvanaId,
            avsForumReady: Boolean(avsForum.selected.url) && !homeVideoReview.avsForumId,
            bluRayAuthorityReady: Boolean(bluRayAuthority.selected.url) &&
                !homeVideoReview.bluRayAuthorityId,
            bluRayReady: Boolean(bluRay.selected.url) && !homeVideoReview.bluRayId,
            createAvForums,
            createAvNirvana,
            createAvsForum,
            createBluRay,
            createBluRayAuthority,
            createDigitalBits,
            createDoBlu,
            createHidefDigest,
            createHomeTheaterForum,
            createWhySoBlu,
            digitalBitsReady: Boolean(digitalBits.selected.url) && !homeVideoReview.digitalBitsId,
            doBluReady: Boolean(doBlu.selected.url) && !homeVideoReview.doBluId,
            hidefDigestReady: Boolean(hidefDigest.selected.url) && !homeVideoReview.hidefDigestId,
            homeTheaterForumReady: Boolean(homeTheaterForum.selected.url) &&
                !homeVideoReview.homeTheaterForumId,
            whySoBluReady: Boolean(whySoBlu.selected.url) && !homeVideoReview.whySoBluId
        });
    }, [
        avForums,
        avNirvana,
        avsForum,
        bluRay,
        bluRayAuthority,
        createAvForums,
        createAvNirvana,
        createAvsForum,
        createBluRay,
        createBluRayAuthority,
        createDigitalBits,
        createDoBlu,
        createHidefDigest,
        createHomeTheaterForum,
        createWhySoBlu,
        digitalBits,
        doBlu,
        hidefDigest,
        homeTheaterForum,
        homeVideoReview,
        whySoBlu
    ]);
    return {
        areReviewsCalled,
        areReviewsLoading,
        avForumsData,
        avNirvanaData,
        avsForumData,
        bluRayAuthorityData,
        bluRayData,
        digitalBitsData,
        doBluData,
        hidefDigestData,
        homeTheaterForumData,
        onCreateReviews,
        resetReviews,
        whySoBluData
    };
};
export default useCreateReviewsContext;
