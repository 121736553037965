import gql from "graphql-tag";
const GET_NEWSLETTERS = gql `
  query getNewsletters {
    getNewsletters {
      id
      name
    }
  }
`;
export default GET_NEWSLETTERS;
