import gql from "graphql-tag";
import image from "queries/fragments/image";
const mas = gql `
  ${image}

  fragment MAS on MAS {
    actors
    country
    director
    id
    imdbId
    language
    poster {
      ...Image
    }
    rating
    releaseDate
    runtime
    title
    writer
    year
  }
`;
export default mas;
