/* eslint-disable no-ternary */

import Paragraph from "components/Typography/Paragraph"
import { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div<{ $isCompact: boolean }>`
  align-items: start;
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  ${({ $isCompact, theme }: { $isCompact: boolean; theme: Theme }) =>
    $isCompact
      ? `
border-bottom: 1px solid ${theme.palette.subtitle.main};
padding-bottom: 6px;
`
      : "grid-template-columns: 1fr auto;"}
  column-gap: 10px;
  display: grid;
  ${({ $isCompact }) => ($isCompact ? "" : "grid-template-columns: 1fr auto;")}
`

export const Buttons = styled.div`
  display: grid;
  row-gap: 10px;
`

export const Left = styled.div<{ $isCompact: boolean }>`
  display: grid;
  ${({ $isCompact }) =>
    $isCompact
      ? `
align-items: center;
column-gap: 5px;
grid-template-columns: auto 24px 24px;`
      : ""}
  row-gap: 5px;
`

export const Name = styled(Paragraph)`
  font-size: 16px;
  margin: 0;
`
