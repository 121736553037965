/* eslint-disable no-ternary, max-lines-per-function */

import { ForwardedRef, forwardRef } from "react"
import { InfoText, Label, LabelAndValue, Value, Wrapper } from "./styled"
import { ComponentProps } from "."
import { InputText } from "@bluframe/blublocks"

const EditableText = forwardRef(
  (
    {
      defaultValue,
      error,
      errorText,
      infoText,
      isAlwaysShowInfo,
      isEditable = true,
      isEditing,
      label,
      name,
      onBlur,
      onChange,
      onKeyDown,
      required,
      success,
      successText,
      type
    }: ComponentProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <Wrapper>
      {isEditable && isEditing ? (
        <InputText
          defaultValue={defaultValue}
          error={error}
          errorText={errorText}
          infoText={infoText}
          inputId={name}
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={ref}
          required={required}
          success={success}
          successText={successText}
          type={type}
        />
      ) : (
        <LabelAndValue>
          <Label as="label">{label}</Label>
          <Value>{defaultValue}</Value>
          {isAlwaysShowInfo && infoText ? (
            <InfoText>{infoText}</InfoText>
          ) : null}
        </LabelAndValue>
      )}
    </Wrapper>
  )
)

EditableText.displayName = "InputEditableText"

export default EditableText
