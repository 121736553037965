/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, max-statements */
import { useHistory, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import DELETE_CONTEST from "mutations/contests/deleteContest";
import GET_CONTEST from "queries/contests/getContest";
import GET_EMAILS_TEMPLATE from "queries/emails/getEmailsTemplate";
import GET_SITE_USERS_BY_CONTEST_ID from "queries/site/getSiteUsersByContestId";
import Item from "./Item";
import SEND_EMAILS_TEMPLATES from "mutations/emails/sendEmailsTemplate";
import UPDATE_CONTEST from "mutations/contests/updateContest";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMemo } from "react";
import useToggle from "@bluframe/grapple/useToggle";
const { contests: { GET_CONTESTS } } = queries;
const usePrepareComponent = () => {
    const [isEditing, toggleIsEditing] = useToggle();
    const history = useHistory();
    const params = useParams();
    const { data: contestData, loading } = useQuery(GET_CONTEST, {
        variables: {
            id: params.id
        }
    });
    const { data: siteUsersData } = useQuery(GET_SITE_USERS_BY_CONTEST_ID, {
        variables: {
            contestId: params.id
        }
    });
    const [getEmailsTemplate, { data: emailsTemplateData }] = useLazyQuery(GET_EMAILS_TEMPLATE);
    const [sendEmailsTemplate] = useMutation(SEND_EMAILS_TEMPLATES);
    const [updateContest] = useMutation(UPDATE_CONTEST, {
        refetchQueries: [{ query: GET_CONTEST, variables: { id: params.id } }]
    });
    const [deleteContest] = useMutation(DELETE_CONTEST, {
        refetchQueries: [{ query: GET_CONTESTS }]
    });
    const onCancel = () => {
        history.push(`/contests`);
    };
    const onEdit = () => {
        toggleIsEditing();
    };
    const onSave = (contestUpdateInput) => {
        updateContest({
            onCompleted: () => {
                toggleIsEditing();
            },
            variables: {
                contestUpdateInput,
                id: params.id
            }
        });
    };
    const usersContestsEmails = useMemo(() => siteUsersData?.getSiteUsersByContestId.filter((item) => item.emailPreferences.isContestsEmails), [siteUsersData]);
    const onEmailSend = (templateId) => {
        if (usersContestsEmails) {
            sendEmailsTemplate({
                variables: {
                    emailsSendTemplateInput: {
                        email: {
                            from: {
                                address: "info@mightychroma.me",
                                name: "Mighty Chroma"
                            },
                            templateId,
                            toAddresses: usersContestsEmails.map((item) => item.email)
                        },
                        emailPreferences: "isContestsEmails"
                    }
                }
            });
        }
    };
    const onEmailTestSend = (templateId) => {
        sendEmailsTemplate({
            variables: {
                emailsSendTemplateInput: {
                    email: {
                        from: {
                            address: "info@mightychroma.me",
                            name: "Mighty Chroma"
                        },
                        templateId,
                        toAddresses: ["info@mightychroma.me"]
                    },
                    emailPreferences: "isContestsEmails"
                }
            }
        });
    };
    const onTemplateIdChange = (templateId) => {
        getEmailsTemplate({
            variables: {
                id: templateId
            }
        });
    };
    const onDelete = () => {
        deleteContest({
            onCompleted: () => {
                history.push(`/contests`);
            },
            variables: {
                id: params.id
            }
        });
    };
    return {
        emailPreview: `${emailsTemplateData?.getEmailsTemplate.heading?.html ?? ""}${emailsTemplateData?.getEmailsTemplate?.html ?? ""}${emailsTemplateData?.getEmailsTemplate.footer?.html ?? ""}`,
        isEditing,
        item: contestData?.getContest,
        loading,
        onCancel,
        onDelete,
        onEdit,
        onEmailSend,
        onEmailTestSend,
        onSave,
        onTemplateIdChange,
        users: siteUsersData?.getSiteUsersByContestId,
        usersContestsEmails
    };
};
export default prepareComponent(usePrepareComponent)(Item);
