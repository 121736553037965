/* eslint-disable no-ternary */

import { Reset, Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: start;
  column-gap: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
`

export const Ul = styled(Reset.Ul)<{ $isCompact: boolean }>`
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  column-gap: 20px;
  display: grid;
  row-gap: 20px;

  ${mediaQueries.tablet} {
    grid-template-columns: ${({ $isCompact }) =>
      $isCompact ? "repeat(3, auto)" : "repeat(4, 1fr)"};
  }

  ${mediaQueries.desktop} {
    grid-template-columns: ${({ $isCompact }) =>
      $isCompact ? "repeat(5, auto)" : "repeat(4, 1fr)"};
  }

  ${mediaQueries.largeDesktop} {
    grid-template-columns: ${({ $isCompact }) =>
      $isCompact ? "repeat(5, auto)" : "repeat(4, 1fr)"};
  }
`
