import GET_ME from "queries/getMe";
import Profile from "./Profile";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const history = useHistory();
    const { onClearToken } = useUser();
    const { data } = useQuery(GET_ME);
    const onLogout = () => {
        onClearToken();
        history.push("/login");
    };
    return {
        me: data?.me,
        onLogout
    };
};
export default prepareComponent(usePrepareComponent)(Profile);
