/* eslint-disable max-statements */

import { Image } from "@bluframe/mightychroma-mightytool-frontend-types"
import imagesConfig from "config/images"

const { DEVICE_SIZES, IMAGE_SIZES } = imagesConfig

// Type definition for the props that this function will receive.
interface Props {
  image: Pick<Image, "formats" | "url">
  width: number
}

const imageLoader = ({ image, width }: Props) => {
  if (width <= IMAGE_SIZES.xsmall) {
    return image.formats.xsmall?.url ?? image.url
  }

  if (width <= IMAGE_SIZES.thumbnail) {
    return image.formats.thumbnail?.url ?? image.url
  }

  if (width <= DEVICE_SIZES.mobile) {
    return image.formats.small?.url ?? image.url
  }

  if (width <= IMAGE_SIZES.small) {
    return image.formats.small?.url ?? image.url
  }

  if (width <= DEVICE_SIZES.tablet) {
    return image.formats.medium?.url ?? image.url
  }

  if (width <= IMAGE_SIZES.medium) {
    return image.formats.medium?.url ?? image.url
  }

  if (width <= IMAGE_SIZES.large) {
    return image.formats.large?.url ?? image.url
  }

  if (width <= DEVICE_SIZES.desktop) {
    return image.formats.large?.url ?? image.url
  }

  if (width <= IMAGE_SIZES.xlarge) {
    return image.formats.xlarge?.url ?? image.url
  }

  return image.url
}

export default imageLoader
