/* eslint-disable no-ternary, max-statements, max-lines-per-function */

import {
  FormEventHandler,
  RefObject,
  createRef,
  useMemo,
  useRef,
  useState
} from "react"
import {
  NewsletterAndSubscribersCount,
  NewsletterUpdateInput,
  Template
} from "@bluframe/mightychroma-mightytool-frontend-types"
import Item from "./Item"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type ModalContent = "deleteConfirm" | "sendConfirm" | "sendTestConfirm" | null

const ONE = 1
const ZERO = 0

export interface Props {
  emailPreview?: string
  isAdd?: boolean
  isEditing?: boolean
  isSendLoading?: boolean
  isSendTestLoading?: boolean
  item?: NewsletterAndSubscribersCount
  onCancel: () => void
  onDelete?: () => void
  onEdit?: () => void
  onEmailSend?: () => void
  onEmailTestSend?: () => void
  // eslint-disable-next-line no-unused-vars
  onSave: (newsletter: NewsletterUpdateInput) => void
  // eslint-disable-next-line no-unused-vars
  onTemplateIdChange?: (templateId?: string) => void
  template?: Template
}

export interface ComponentProps
  extends Omit<
    Props,
    | "isEditing"
    | "onCancel"
    | "onDelete"
    | "onEdit"
    | "onEmailSend"
    | "onEmailTestSend"
    | "onSave"
    | "onTemplateIdChange"
    | "template"
  > {
  actionButtonLabel: string
  actionButtonOnClick?: () => void
  emailPreferencesRef: RefObject<HTMLInputElement>
  isModalOpen: boolean
  isEditMode: boolean
  modalContent: ModalContent
  nameRef: RefObject<HTMLInputElement>
  onModalClose: () => void
  // eslint-disable-next-line no-unused-vars
  onModalConfirmOpen: (modalContent: ModalContent) => () => void
  onModalDeleteConfirm: () => void
  onModalSendConfirm: () => void
  onModalSendTestConfirm: () => void
  onPreviewClick: () => void
  onSubmit: FormEventHandler
  outlinedButtonLabel: string
  outlinedButtonOnClick?: () => void
  placeholdersRefs: { [key: string]: RefObject<HTMLInputElement> }
  subject?: string
  templateIdRef: RefObject<HTMLInputElement>
}

const DEFAULT_MODAL_CONTENT = null

const usePrepareComponent = ({
  isEditing,
  onCancel,
  onDelete,
  onEdit,
  onEmailSend,
  onEmailTestSend,
  onTemplateIdChange,
  onSave,
  template,
  ...props
}: Props): ComponentProps => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContent>(
    DEFAULT_MODAL_CONTENT
  )

  const emailPreferencesRef = useRef<HTMLInputElement>(null)
  const templateIdRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const placeholdersRefs: { [key: string]: RefObject<HTMLInputElement> } =
    useMemo(() => {
      const refs: { [key: string]: RefObject<HTMLInputElement> } = {}
      const placeholders = props.item?.placeholders
        ? Object.keys(props.item.placeholders)
        : template?.placeholders

      for (
        let index = 0;
        index < (placeholders?.length ?? ZERO);
        index += ONE
      ) {
        const key = placeholders?.[index]

        if (key && !refs[key]) {
          // Initialize ref only if it doesn't exist
          refs[key] = createRef<HTMLInputElement>()
        }
      }

      return refs
    }, [props.item?.placeholders, template?.placeholders])

  const isEditMode = Boolean(props.isAdd || isEditing)

  const onModalClose = () => {
    setModalContent(DEFAULT_MODAL_CONTENT)
    setIsModalOpen(false)
  }

  const onModalConfirmOpen = (newModalContent: ModalContent) => () => {
    setModalContent(newModalContent)
    setIsModalOpen(true)
  }

  const onModalDeleteConfirm = () => {
    onDelete?.()
    onModalClose()
  }

  const onModalSendConfirm = () => {
    onEmailSend?.()

    onModalClose()
  }

  const onModalSendTestConfirm = () => {
    onEmailTestSend?.()

    onModalClose()
  }

  const actionButtonLabel = isEditMode ? "Save" : "Delete"
  const outlinedButtonLabel = isEditMode ? "Cancel" : "Edit"

  const actionButtonOnClick = isEditMode
    ? undefined
    : onModalConfirmOpen("deleteConfirm")
  const outlinedButtonOnClick = isEditMode ? onCancel : onEdit

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    if (!nameRef.current) {
      return
    }

    const placeholders: { [key: string]: string } = {}

    Object.keys(placeholdersRefs).forEach((key) => {
      const placeholderRef = placeholdersRefs[key]

      placeholders[key] = placeholderRef.current?.value ?? ""
    })

    onSave({
      emailPreferences: emailPreferencesRef.current?.value ?? "",
      name: nameRef.current.value ?? "",
      placeholders,
      templateId: templateIdRef.current?.value ?? ""
    })
  }

  const onPreviewClick = () => {
    onTemplateIdChange?.(templateIdRef.current?.value)
  }

  return {
    ...props,
    actionButtonLabel,
    actionButtonOnClick,
    emailPreferencesRef,
    isEditMode,
    isModalOpen,
    modalContent,
    nameRef,
    onModalClose,
    onModalConfirmOpen,
    onModalDeleteConfirm,
    onModalSendConfirm,
    onModalSendTestConfirm,
    onPreviewClick,
    onSubmit,
    outlinedButtonLabel,
    outlinedButtonOnClick,
    placeholdersRefs,
    subject: template?.subject,
    templateIdRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
