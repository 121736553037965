import { gql } from "graphql-tag"

const forum = gql`
  type Category {
    commentCount: Int!
    createdAt: String!
    description: String!
    id: ID!
    name: String!
    order: Int!
    section: Section!
    slug: String!
    topicCount: Int!
    updatedAt: String!
  }

  input CategoryAddInput {
    description: String!
    name: String!
    order: Int!
    sectionId: ID!
    slug: String!
  }

  type CategoryAddResponse {
    id: ID
  }

  input CategoryInput {
    description: String
    name: String
    order: Int
    sectionId: ID
    slug: String
  }

  input CategoryUpdateInput {
    id: ID!
    category: CategoryInput!
  }

  type CategoryUpdateResponse {
    id: ID!
  }

  type CategoryDeleteResponse {
    status: String!
  }

  type Section {
    createdAt: String!
    description: String!
    id: ID!
    name: String!
    order: Int!
    updatedAt: String!
  }

  input SectionAddInput {
    description: String!
    name: String!
    order: Int!
  }

  type SectionAddResponse {
    id: ID!
  }

  input SectionInput {
    description: String
    name: String
    order: Int
  }

  input SectionUpdateInput {
    id: ID!
    section: SectionInput!
  }

  type SectionUpdateResponse {
    id: ID!
  }

  type SectionDeleteResponse {
    status: String!
  }
`

export default forum
