import gql from "graphql-tag"

const ADD_PLATFORM = gql`
  mutation addPlatform(
    $platform: PlatformAddInput!
    $socialLinks: SocialLinksAddInput
  ) {
    addPlatform(platform: $platform, socialLinks: $socialLinks) {
      id
    }
  }
`

export default ADD_PLATFORM
