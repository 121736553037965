import gql from "graphql-tag";
const GET_EMAILS_HEADING = gql `
  query getEmailsHeading($id: ID!) {
    getEmailsHeading(id: $id) {
      html
      id
      name
    }
  }
`;
export default GET_EMAILS_HEADING;
