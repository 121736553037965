import type { BaseTheme } from "@bluframe/blublocks"
import colors from "styles/colors"

const theme: BaseTheme = {
  font: {
    family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  palette: {
    disabled: {
      contrast: colors.white,
      main: colors.nobel
    },
    error: {
      contrast: colors.white,
      main: colors.red
    },
    info: {
      contrast: colors.white,
      main: colors.osloGray
    },
    primary: {
      contrast: colors.white,
      main: colors.blazeOrange
    },
    secondary: {
      contrast: colors.white,
      main: colors.dodgerBlue
    },
    subtitle: {
      contrast: colors.white,
      main: colors.osloGray
    },
    success: {
      contrast: colors.white,
      main: colors.dodgerBlue
    },
    text: {
      contrast: colors.white,
      main: colors.tundora
    }
  }
}

export default theme
