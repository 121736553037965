const images = {
  DEVICE_SIZES: {
    desktop: 1024,
    mobile: 480,
    tablet: 768
  },
  IMAGE_SIZES: {
    large: 1024,
    medium: 768,
    small: 480,
    thumbnail: 160,
    xlarge: 1600,
    xsmall: 120
  }
}

export default images
