/* eslint-disable max-statements, max-lines-per-function */
import Dashboard from "./Dashboard";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useQuery } from "@apollo/client";
import useSections from "./hooks/useSections";
import useUser from "hooks/useUser";
const { dashboard: { GET_DASHBOARD_COUNT_ADMIN, GET_DASHBOARD_COUNT_USER } } = queries;
const DASHBOARD_COUNT_ADMIN_POLL_INTERVAL = 60000;
const formatISODate = (date, isStartDate) => {
    // Get the year, month, and day and construct the date string
    const year = date.getUTCFullYear();
    // eslint-disable-next-line no-magic-numbers
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    // eslint-disable-next-line no-magic-numbers
    const day = String(date.getUTCDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    if (isStartDate) {
        return `${formattedDate}T00:00:00.000Z`;
    }
    // Set time to 23:59:59 today
    return `${formattedDate}T23:59:59.999Z`;
};
const getStartAndEndDates = () => {
    const today = new Date();
    // Current date at midnight UTC
    const endDateISO = formatISODate(today);
    // Calculate a date 6 days ago - 1 week ago
    const sixDaysAgo = new Date(today);
    // eslint-disable-next-line no-magic-numbers
    sixDaysAgo.setUTCDate(today.getUTCDate() - 6);
    const startDateISO = formatISODate(sixDaysAgo, true);
    return {
        endDateISO,
        startDateISO
    };
};
export const usePrepareComponent = (props) => {
    const { isAdmin } = useUser();
    const { endDateISO, startDateISO } = getStartAndEndDates();
    const { data: dashboardCountAdminData, loading: dashboardCountAdminLoading } = useQuery(GET_DASHBOARD_COUNT_ADMIN, {
        pollInterval: DASHBOARD_COUNT_ADMIN_POLL_INTERVAL,
        skip: !isAdmin,
        variables: {
            getDashboardCountAdminInput: {
                newUsersByDateRange: {
                    endDate: endDateISO,
                    startDate: startDateISO
                }
            }
        }
    });
    const { data: dashboardCountUserData, loading: dashboardCountUserLoading } = useQuery(GET_DASHBOARD_COUNT_USER, {
        skip: isAdmin
    });
    const sections = useSections({
        dashboardCountAdmin: dashboardCountAdminData?.getDashboardCountAdmin,
        dashboardCountUser: dashboardCountUserData?.getDashboardCountUser
    });
    return {
        ...props,
        isLoading: dashboardCountAdminLoading || dashboardCountUserLoading,
        sections
    };
};
export default prepareComponent(usePrepareComponent)(Dashboard);
