import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-ternary, no-nested-ternary, max-lines-per-function */
import { H1, H2, Left, Main, Paragraph, Right, Row, Top, Wrapper } from "./styled";
import { InputText, Loading } from "@bluframe/blublocks";
import { Button } from "@bluframe/mightychroma-mightytool-ui";
const ONE = 1;
const Roundup = ({ character, characterId, isCharacterLoading, isGenerateBlogRoundupLoading, onChangeCharacterId, onChangeHomeVideoReviewId, onChangeTheme, onClickGenerate, homeVideoReviewIds, reviewNames, theme }) => (_jsxs(Wrapper, { children: [_jsxs(Top, { children: [_jsx(H1, { children: "Reviews Roundup" }), _jsx(Button, { label: "Generate", loading: isGenerateBlogRoundupLoading, onClick: onClickGenerate })] }), _jsxs(Main, { children: [_jsxs(Left, { children: [_jsx(Row, { children: _jsx(InputText, { label: "Character Id", name: "characterId", 
                                // @ts-ignore
                                onChange: onChangeCharacterId, value: characterId }) }), _jsx(Row, { children: _jsx(InputText, { label: "Theme", name: "theme", 
                                // @ts-ignore
                                onChange: onChangeTheme, value: theme }) }), homeVideoReviewIds.map((homeVideoReviewId, index) => (_jsx(Row, { children: _jsx(InputText, { label: `Review Id ${index + ONE}`, name: `homeVideoReviewId-${index + ONE}`, 
                                // @ts-ignore
                                onChange: onChangeHomeVideoReviewId(index), value: homeVideoReviewId }) }, homeVideoReviewId)))] }), _jsxs(Right, { children: [_jsxs(Row, { children: [_jsx(H2, { children: "Character Name" }), character ? (_jsx(Paragraph, { children: `${character?.firstName} ${character?.lastName}` })) : isCharacterLoading ? (_jsx(Loading, {})) : null] }), _jsxs(Row, { children: [_jsx(H2, { children: "Roundup Theme" }), _jsx(Paragraph, { children: theme })] }), reviewNames?.map((reviewName, index) => (_jsxs(Row, { children: [_jsx(H2, { children: `Review ${index + ONE}` }), _jsx(Paragraph, { children: reviewName })] }, reviewName)))] })] })] }));
export default Roundup;
