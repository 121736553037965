import gql from "graphql-tag";
const UPDATE_EMAILS_HEADINGS = gql `
  mutation updateEmailsHeading(
    $id: ID!
    $emailsHeadingUpdateInput: EmailsHeadingUpdateInput!
  ) {
    updateEmailsHeading(
      id: $id
      emailsHeadingUpdateInput: $emailsHeadingUpdateInput
    ) {
      id
    }
  }
`;
export default UPDATE_EMAILS_HEADINGS;
