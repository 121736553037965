import { H3 as BluBlocksH3, Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const H3 = styled(BluBlocksH3)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-weight: 500;
  line-height: 1.1;
  font-size: 24px;
  margin-top: 0;

  ${mediaQueries.tablet} {
    font-size: 26px;
  }
`

export default H3
