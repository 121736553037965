/* eslint-disable max-lines-per-function, no-ternary */

import { Buttons, Left, Name, Wrapper } from "./styled"
import { FormModal, Modal } from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import Check from "./Check"
import { ComponentProps } from "."
import InputText from "components/inputs/Text"
import Overrides from "./Overrides"

const PodSource = ({
  areReviewsLoading,
  inputRefs,
  isCompact,
  isCreated,
  isEditOpen,
  isLoading,
  isValid,
  label,
  onCancel,
  onClear,
  onSave,
  onSetIsEditOpen,
  onSetReleaseDate,
  overrides,
  serviceName,
  text
}: ComponentProps) => (
  <Wrapper $isCompact={isCompact}>
    <Left $isCompact={isCompact}>
      {isCompact ? (
        <Name>{label}</Name>
      ) : (
        <InputText
          disabled
          id={serviceName}
          label={label}
          name={serviceName}
          text={text}
        />
      )}

      <Check
        disabled={areReviewsLoading || !text}
        isGreen={isValid}
        isLoading={isLoading}
        label={isCompact ? undefined : "Preview Valid"}
      />
      <Check
        disabled={areReviewsLoading || !text}
        isGreen={isCreated}
        isLoading={isLoading}
        label={isCompact ? undefined : "Review Created"}
      />
    </Left>
    {isCompact ? null : (
      <Buttons>
        <Button
          disabled={areReviewsLoading || isLoading || !text}
          label="Edit"
          onClick={onSetIsEditOpen(true)}
          outlined
        />
        <Button
          disabled={areReviewsLoading || isLoading || !text}
          label="Clear"
          onClick={onClear(serviceName)}
          outlined
          secondary
        />
      </Buttons>
    )}
    <Modal isOpen={isEditOpen} onClose={onCancel}>
      <FormModal onClose={onCancel} onSubmit={onSave}>
        <Overrides
          inputRefs={inputRefs}
          onSetReleaseDate={onSetReleaseDate}
          overrides={overrides}
        />
      </FormModal>
    </Modal>
  </Wrapper>
)
export default PodSource
