/* eslint-disable no-ternary */

import { H4 as BluBlocksH4, Paragraph, Theme } from "@bluframe/blublocks"
import { rgba } from "polished"
import styled from "styled-components"

const DIVIDER_ALPHA = 0.4

export const Wrapper = styled.div<{ $isExpanded: boolean }>`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  border-right: 1px solid
    ${({ theme }: { theme: Theme }) =>
      rgba(theme.palette.subtitle.main, DIVIDER_ALPHA)};
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100vh;
  ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "justify-items:start;" : "justify-items:center;"}
  left: 0;
  padding: ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "20px" : "20px 10px"};
  position: fixed;
  row-gap: 20px;
  top: 0;
  width: ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "260px" : "60px"};
`

export const Top = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "grid-template-columns: auto 1fr;" : ""}
`

export const H4 = styled(BluBlocksH4)`
  font-size: 18px;
  margin: 0;
`

export const Ul = styled.ul`
  display: grid;
  list-style-type: none;
  margin: 0;
  row-gap: 10px;
`

export const Li = styled.li`
  list-style: none;
`

export const Logo = styled.img`
  width: 40px;
`

export const Name = styled(Paragraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
`

export const Bottom = styled.div`
  align-items: center;
  align-self: end;
  column-gap: 8px;
  cursor: pointer;
  display: grid;
  ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "grid-template-columns: 18px auto;" : ""};

  justify-items: start;
`

export const Toggle = styled.div`
  position: fixed;
  left: ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "248px" : "48px"};
  top: calc(100vh - 50%);

  & button[data-variant="iconOnly"] {
    background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
    border: none;
    padding: 0;

    ${({ $isExpanded }: { $isExpanded: boolean }) =>
      $isExpanded ? "" : `transform: rotate(180deg);`}
  }
`
