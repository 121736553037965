/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */
import ADD_MAS_FROM_IMDBID from "mutations/mas/addMASFromIMDbId";
import Add from "./Add";
import PREVIEW_MAS from "mutations/mas/previewMAS";
import UPDATE_MAS from "mutations/mas/updateMASFromIMDbId";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
const usePrepareComponent = () => {
    const history = useHistory();
    const [addMAS, { loading: isSubmitLoading }] = useMutation(ADD_MAS_FROM_IMDBID);
    const [updateMAS] = useMutation(UPDATE_MAS);
    const [previewMAS, { data: previewData, loading: isPreviewLoading }] = useMutation(PREVIEW_MAS);
    const onCancel = () => {
        history.push(`/`);
    };
    const onPreview = (imdbId) => {
        previewMAS({
            variables: {
                imdbId
            }
        });
    };
    const onSubmit = (imdbId) => {
        if (previewData?.previewMAS?.current) {
            updateMAS({
                onCompleted: () => {
                    previewMAS({ variables: { imdbId } });
                },
                variables: {
                    imdbId
                }
            });
            return;
        }
        addMAS({
            onCompleted: () => {
                previewMAS({ variables: { imdbId } });
            },
            variables: {
                imdbId
            }
        });
    };
    return {
        isPreviewLoading,
        isSubmitLoading,
        item: previewData?.previewMAS,
        onCancel,
        onPreview,
        onSubmit
    };
};
export default prepareComponent(usePrepareComponent)(Add);
