/* eslint-disable no-ternary, no-nested-ternary */
import styled from "styled-components";
export const Wrapper = styled.div `
  display: grid;
  padding-left: ${({ $hasLeftPadding, $isExpanded }) => ($hasLeftPadding ? ($isExpanded ? "260px" : "60px") : "0")};
  row-gap: 20px;
`;
export const Main = styled.div `
  padding: 20px;
`;
export const Overlay = styled.div `
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: ${({ $isShowOverlay }) => $isShowOverlay ? "grid" : "none"};
  height: 100vh;
  left: 0;
  justify-items: center;
  position: fixed;
  row-gap: 20px;
  top: 0;
  width: 100vw;
  z-index: 10;

  & h2 {
    align-self: end;
  }

  & div[aria-label="Loading"] {
    align-self: start;
  }
`;
