/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import Add from "./Add";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
const { forum: { GET_SECTIONS } } = queries;
const { forum: { ADD_SECTION } } = mutations;
const DEFAULT_ORDER = 0;
const usePrepareComponent = () => {
    const history = useHistory();
    const [addContent, { loading }] = useMutation(ADD_SECTION, {
        refetchQueries: [GET_SECTIONS]
    });
    const [getContents] = useLazyQuery(GET_SECTIONS);
    const onCancel = () => {
        history.push("/forum/sections");
    };
    const onSave = (itemAdd) => {
        addContent({
            onCompleted: () => {
                getContents({
                    fetchPolicy: "network-only",
                    onCompleted: () => {
                        history.push("/forum/sections");
                    }
                });
            },
            variables: {
                section: {
                    ...itemAdd,
                    description: itemAdd.description ?? "",
                    name: itemAdd.name ?? "",
                    order: itemAdd.order ?? DEFAULT_ORDER
                }
            }
        });
    };
    return {
        loading,
        onCancel,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Add);
