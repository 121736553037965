import Avatar from "@material-ui/core/Avatar"
import CardHeader from "@material-ui/core/CardHeader"
import { ComponentProps } from "."
import React from "react"
import colors from "styles/colors"
import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

const COLOR_WEIGHT = 500

const useStyles = makeStyles({
  avatar: {
    backgroundColor: red[COLOR_WEIGHT],
    fontSize: 15
  },
  cardHeader: {
    backgroundColor: colors.white
  }
})

const Header = ({ avatar, subheader, title }: ComponentProps) => {
  const classes = useStyles()

  return (
    <CardHeader
      avatar={
        <Avatar aria-label="avatar" className={classes.avatar}>
          {avatar}
        </Avatar>
      }
      className={classes.cardHeader}
      subheader={subheader}
      title={title}
    />
  )
}

export default Header
