import LoadMore from "./LoadMore";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
import useReviews from "hooks/useReviews";
const usePrepareComponent = ({ serviceName }) => {
    const { fetchMore, isUHD, loading } = useEightLeggedFreakList(serviceName);
    const { state } = useReviews();
    const service = state[serviceName];
    const onLoadMore = () => {
        fetchMore({
            variables: {
                eightLeggedFreakListInput: {
                    cursor: service.nextCursor ?? undefined,
                    isUHD,
                    serviceName
                }
            }
        });
    };
    return {
        isDisabled: !service.nextCursor || loading,
        loading,
        onLoadMore
    };
};
export default prepareComponent(usePrepareComponent)(LoadMore);
