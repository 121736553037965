import React, { KeyboardEvent, useEffect, useState } from "react"
import {
  UseComboboxGetInputPropsReturnValue,
  UseComboboxInputValueChange,
  UseComboboxPropGetters,
  useCombobox
} from "downshift"
import Autosuggest from "./Autosuggest"
import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  disabled?: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onSuggestionClick: (id: string, name: string) => void
  onGetSuggestions: (
    // eslint-disable-next-line no-unused-vars
    changes: UseComboboxInputValueChange<HomeVideoReviewSearchItem>
  ) => void
  // eslint-disable-next-line no-unused-vars
  onKeyDown?: (ev: KeyboardEvent<Element>) => void
  suggestions: HomeVideoReviewSearchItem[]
  value: string
}

export interface ComponentProps extends Omit<Props, "onGetSuggestions"> {
  getItemProps: UseComboboxPropGetters<HomeVideoReviewSearchItem>["getItemProps"]
  getMenuProps: UseComboboxPropGetters<HomeVideoReviewSearchItem>["getMenuProps"]
  inputProps: UseComboboxGetInputPropsReturnValue & {
    ref: React.RefObject<HTMLInputElement>
  }
  isOpen: boolean
}

const DEFAULT_DEBOUNCE_DELAY = 500

export const usePrepareComponent = ({
  onGetSuggestions,
  suggestions,
  ...props
}: Props): ComponentProps => {
  const [inputValue, setInputValue] = useState<any>()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (inputValue === undefined) return

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const newTimeoutId = setTimeout(() => {
      onGetSuggestions(inputValue)
    }, DEFAULT_DEBOUNCE_DELAY)

    setTimeoutId(newTimeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const { isOpen, getMenuProps, getInputProps, getItemProps } =
    useCombobox<HomeVideoReviewSearchItem>({
      inputId: "search",
      itemToKey: (item) => item?.id,
      itemToString: (item) => item?.mas.title ?? "",
      items: suggestions,
      labelId: "search-label",
      onInputValueChange: setInputValue
    })

  return {
    ...props,
    getItemProps,
    getMenuProps,
    inputProps: getInputProps(),
    isOpen,
    suggestions
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Autosuggest
)
