import gql from "graphql-tag"

const section = gql`
  fragment Section on Section {
    createdAt
    description
    id
    name
    order
    updatedAt
  }
`

export default section
