import Button from "./Button"

export interface Props {
  isDisabled?: boolean
  isOutlined?: boolean
  label: string
  loading?: boolean
  onClick: () => void
}

export interface ComponentProps extends Props {}

export default Button
