import gql from "graphql-tag"

const criticsReview = gql`
  fragment CriticsReview on Review {
    id
    url
  }
`

export default criticsReview
