import {
  Review,
  ServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import React from "react"
import ReviewsCard from "./ReviewsCard"

export type Props = {
  actions: React.ReactNode
  avatar: string
  display: Review[]
  flagLoading: boolean
  isShowFlagButton: boolean
  onSelect: () => void
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  selected: string
  serviceName: ServiceName
  subheader: string
  title: string
}

export interface ComponentProps extends Props {}

export default ReviewsCard
