import gql from "graphql-tag"

const DELETE_SECTION = gql`
  mutation deleteSection($id: ID!) {
    deleteSection(id: $id) {
      status
    }
  }
`

export default DELETE_SECTION
