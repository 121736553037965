import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
import { useState } from "react";
const DEFAULT_IS_MODAL_OPEN = false;
const useImageSelector = () => {
    const { images } = usePreviews();
    const { setImageServiceName, state: { homeVideoReview: { imageServiceName } } } = useReviews();
    const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN);
    const onModalClose = () => {
        setIsModalOpen(false);
    };
    const onSelectClick = () => {
        setIsModalOpen(true);
    };
    const onSelectFrontImage = (serviceName) => {
        setImageServiceName(serviceName);
    };
    return {
        imageServiceNameSelected: imageServiceName,
        images,
        modal: {
            isOpen: isModalOpen,
            name: "Image Selector",
            onClose: onModalClose
        },
        onSelectClick,
        onSelectFrontImage
    };
};
export default useImageSelector;
