import { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

const ExternalLink = styled.a`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  text-decoration: underline;

  &:hover,
  &:focus {
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    font-weight: 700;
  }
`

export default ExternalLink
