import gql from "graphql-tag";
const emailsTemplate = gql `
  fragment EmailsTemplate on EmailsTemplate {
    footer {
      html
      id
      name
    }
    heading {
      html
      id
      name
    }
    html
    id
    identifier
    name
    placeholders
    subject
  }
`;
export default emailsTemplate;
