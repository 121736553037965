import gql from "graphql-tag";
import mas from "queries/fragments/mas";
const PREVIEW_MAS = gql `
  ${mas}

  mutation previewMAS($imdbId: String!) {
    previewMAS(imdbId: $imdbId) {
      current {
        ...MAS
      }
      preview {
        ...MAS
      }
    }
  }
`;
export default PREVIEW_MAS;
