import { InputText, TextArea } from "@bluframe/blublocks"
import { OverridesLabel, Wrapper } from "./styled"
import { ComponentProps } from "."

const OverridesScoreQuoteAndSection = ({
  inputId,
  inputRefs,
  label,
  name,
  quote,
  score,
  section
}: ComponentProps) => (
  <Wrapper>
    <OverridesLabel>{label}</OverridesLabel>
    <InputText
      defaultValue={String(score ?? "")}
      inputId={`${inputId}-score`}
      label="Score"
      name={`${name}-score`}
      ref={inputRefs.score}
      step="0.5"
      type="number"
    />
    <TextArea
      defaultValue={quote ?? ""}
      inputId={`${inputId}-quote`}
      label="Quote"
      name={`${name}-quote`}
      ref={inputRefs.quote}
    />
    <TextArea
      defaultValue={section ?? ""}
      inputId={`${inputId}-section`}
      label="Section"
      name={`${name}-section`}
      ref={inputRefs.section}
    />
  </Wrapper>
)
export default OverridesScoreQuoteAndSection
