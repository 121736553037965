import gql from "graphql-tag";
const GATHER = gql `
  mutation gather($eightLeggedFreakGatherInput: EightLeggedFreakGatherInput!) {
    eightLeggedFreakGather(
      eightLeggedFreakGatherInput: $eightLeggedFreakGatherInput
    ) {
      status
    }
  }
`;
export default GATHER;
