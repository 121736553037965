/* eslint-disable no-ternary */

import { H2, Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  column-gap: 8px;
  display: grid;
  justify-items: start;
  padding: 16px 20px;
  row-gap: 8px;
  width: 100%;
`

export const Main = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
`

export const Left = styled.div`
  display: grid;
  ${({ $hasIcon }: { $hasIcon: boolean }) =>
    $hasIcon ? "grid-template-columns: 18px auto;" : ""};
`

export const Label = styled(H2)`
  font-size: 18px;
`

export const Buttons = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto;
`
