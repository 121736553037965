import { gql } from "graphql-tag"

const homeVideoReviewScore = gql`
  type HomeVideoReviewScore {
    audio: Int
    extra: Int
    movie: Int
    total: Int
    video: Int
  }
`

export default homeVideoReviewScore
