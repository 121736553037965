import gql from "graphql-tag"

const UPDATE_HOME_VIDEO_REVIEW = gql`
  mutation updateHomeVideoReview(
    $homeVideoReview: HomeVideoReviewUpdateInput!
  ) {
    updateHomeVideoReview(homeVideoReview: $homeVideoReview) {
      id
    }
  }
`
export default UPDATE_HOME_VIDEO_REVIEW
