import gql from "graphql-tag";
const GET_CONTEST = gql `
  query getContest($id: ID!) {
    getContest(id: $id) {
      createdAt
      conclusion
      countryIds
      countries {
        id
        isoCode
        name
        shortName
      }
      endDate
      image {
        alternativeText
        height
        id
        url
        width
      }
      id
      imageId
      intro
      isActive
      isEnded
      isPublished
      rules
      slug
      startDate
      subtitle
      title
      updatedAt
    }
  }
`;
export default GET_CONTEST;
