/* eslint-disable react/jsx-handler-names */

import { ConfirmModal, Modal } from "@bluframe/blublocks"
import {
  Image,
  ImageAndServiceLabel,
  ModalWrapper,
  ServiceLabel,
  Ul,
  Wrapper
} from "./styled"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import H4 from "components/Typography/H4"
import Paragraph from "components/Typography/Paragraph"
import serviceNameLabels from "config/serviceNameLabels"

const ImageSelector = ({
  imageServiceNameSelected,
  images,
  modal,
  onConfirm,
  onSelectClick,
  onSelectImage
}: ComponentProps) => (
  <>
    <Wrapper>
      <Paragraph>{`Images Present: ${images.length}`}</Paragraph>
      <Button label="Select" onClick={onSelectClick} />
    </Wrapper>
    <Modal {...modal} name="Image Selector">
      <ConfirmModal
        message={
          <ModalWrapper>
            <H4>{"Select a Front Image"}</H4>
            <Ul aria-label="Image Selector Images">
              {images.map((image) => (
                <li aria-label={`${image.serviceName} Image`} key={image.url}>
                  <ImageAndServiceLabel
                    $isSelected={imageServiceNameSelected === image.serviceName}
                    onClick={onSelectImage(image.serviceName)}
                  >
                    <Image alt={`${image.serviceName} Image`} src={image.url} />
                    <ServiceLabel
                      $isSelected={
                        imageServiceNameSelected === image.serviceName
                      }
                    >
                      {serviceNameLabels[image.serviceName]}
                    </ServiceLabel>
                  </ImageAndServiceLabel>
                </li>
              ))}
            </Ul>
          </ModalWrapper>
        }
        onClose={modal.onClose}
        onConfirm={onConfirm}
      />
    </Modal>
  </>
)

export default ImageSelector
