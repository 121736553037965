/* eslint-disable no-ternary */

import { ComponentProps } from "."
import Content from "components/Content"
import React from "react"

const Item = ({ components, isMarkdown, isNotLast, item }: ComponentProps) => (
  <>
    {isMarkdown ? <Content components={components} content={item} /> : item}
    {isNotLast ? `, ` : null}
  </>
)

export default Item
