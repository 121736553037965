/* eslint-disable no-ternary, max-lines-per-function */

import {
  Buttons,
  CurrentAndPreview,
  Form,
  H2,
  HeadingsAndDetails,
  SubtitleH2,
  Top
} from "./styled"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import { InputText } from "@bluframe/blublocks"
import MASDetails from "components/MAS/Details"

const Item = ({
  imdbIdRef,
  isAdd,
  isPreviewLoading,
  isSubmitLoading,
  item,
  onCancel,
  onPreview,
  onSubmit
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      {isAdd ? (
        <InputText
          defaultValue={item?.preview.imdbId}
          disabled={isPreviewLoading || isSubmitLoading}
          inputId="imdbId"
          label="IMDb ID"
          name="imdbId"
          ref={imdbIdRef}
        />
      ) : null}
      <Buttons>
        <Button
          disabled={isPreviewLoading || isSubmitLoading}
          label="Cancel"
          onClick={onCancel}
          outlined
        />
        <Button
          disabled={isPreviewLoading || isSubmitLoading}
          label="Preview"
          loading={isPreviewLoading}
          onClick={onPreview}
          outlined
          secondary
        />
        <Button
          disabled={!item || isPreviewLoading || isSubmitLoading}
          label={item?.current ? "Update" : "Add"}
          loading={isSubmitLoading}
          type="submit"
        />
      </Buttons>
    </Top>
    {item ? (
      <CurrentAndPreview $hasCurrent={Boolean(item?.current)}>
        {item.current ? (
          <HeadingsAndDetails>
            <H2>{"Current"}</H2>
            <SubtitleH2>{`id: ${item.current.id}`}</SubtitleH2>
            <MASDetails {...item.current} />
          </HeadingsAndDetails>
        ) : null}

        {item.preview ? (
          <HeadingsAndDetails>
            <H2>{"Preview"}</H2>
            {item.current ? <SubtitleH2 /> : null}
            <MASDetails {...item.preview} />
          </HeadingsAndDetails>
        ) : null}
      </CurrentAndPreview>
    ) : null}
  </Form>
)

export default Item
