const SECOND = 1;
const SECONDS_BASE = 1000;
/**
 * Checks if a JWT token has expired.
 * @param {string} token - The JWT token to be checked.
 * @returns {boolean} - Returns true if the token has expired, otherwise false.
 */
const isTokenExpired = (token) => {
    try {
        // Decode the token to get its payload
        const payloadBase64 = token.split(".")[SECOND];
        const decodedPayload = JSON.parse(window.atob(payloadBase64));
        // Get the current time and expiration time from the token
        // Convert to seconds
        const currentTime = Date.now() / SECONDS_BASE;
        const expTime = decodedPayload.exp;
        // Check if the current time is beyond the expiration time
        return currentTime > expTime;
    }
    catch (error) {
        // If there's an error (e.g., token is malformed), assume the token is invalid/expired
        // eslint-disable-next-line no-console
        console.error("Error decoding the token:", error);
        return true;
    }
};
export default isTokenExpired;
