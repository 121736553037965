import {
  HomeVideoReviewAddInput,
  SelectedReview,
  ServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import HeaderPods from "./HeaderPods"
import React from "react"
import { ReviewOverrides } from "@bluframe/mightychroma-microservices-critics"

export interface Props {
  areReviewsLoading: boolean
  avForums: SelectedReview
  avNirvana: SelectedReview
  avsForum: SelectedReview
  bluRay: SelectedReview
  bluRayAuthority: SelectedReview
  digitalBits: SelectedReview
  doBlu: SelectedReview
  hidefDigest: SelectedReview
  homeTheaterForum: SelectedReview
  homeVideoReview: HomeVideoReviewAddInput
  isCompact: boolean
  left: React.ReactNode
  // eslint-disable-next-line no-unused-vars
  onClear: (serviceName: ServiceName) => () => void
  onSetOverrides: (
    // eslint-disable-next-line no-unused-vars
    serviceName: ServiceName
    // eslint-disable-next-line no-unused-vars
  ) => (overrides: ReviewOverrides) => void
  whySoBlu: SelectedReview
}

export interface ComponentProps extends Props {}

export default HeaderPods
