/* eslint-disable no-ternary */

import { Link as ReactRouterDomLink } from "react-router-dom"
import type { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: center;
  column-gap: 8px;
  display: grid;
  ${({ $iconName }: { $iconName?: string }) =>
    $iconName ? "grid-template-columns: 18px auto;" : ""};
  justify-items: start;
`

export const Link = styled(ReactRouterDomLink)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  font-size: 16px;

  &:hover,
  &:focus {
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  }
`
