/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */
import { useLazyQuery, useMutation } from "@apollo/client";
import Add from "./Add";
import onEmailsError from "components/Emails/onEmailsError";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
import { useState } from "react";
const DEFAULT_ERRORS = {};
const usePrepareComponent = ({ addMutation, itemName, listQuery }) => {
    const history = useHistory();
    const [errors, setErrors] = useState(DEFAULT_ERRORS);
    const [addEmailsItem, { loading }] = useMutation(addMutation, {
        refetchQueries: [listQuery]
    });
    const [getEmailsItems] = useLazyQuery(listQuery);
    const onCancel = () => {
        history.push(`/emails/${itemName}s`);
    };
    const onSave = (itemAdd) => {
        const addInputName = itemName === "footer"
            ? "emailsFooterAddInput"
            : itemName === "heading"
                ? "emailsHeadingAddInput"
                : "emailsTemplateAddInput";
        addEmailsItem({
            onCompleted: () => {
                getEmailsItems({
                    fetchPolicy: "network-only",
                    onCompleted: () => {
                        history.push(`/emails/${itemName}s`);
                    }
                });
            },
            onError: onEmailsError(setErrors),
            variables: {
                [addInputName]: {
                    footerId: itemAdd.footerId,
                    headingId: itemAdd.headingId,
                    html: itemAdd.html,
                    identifier: itemAdd.identifier,
                    name: itemAdd.name,
                    placeholders: itemAdd.placeholders,
                    subject: itemAdd.subject
                }
            }
        });
    };
    return {
        errors,
        isTemplate: itemName === "template",
        loading,
        onCancel,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Add);
