/* eslint-disable no-ternary */

import { Link as ReactRouterDomLink } from "react-router-dom"
import { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: center;
  column-gap: 8px;
  display: grid;
  ${({ $isExpanded }: { $isExpanded: boolean }) =>
    $isExpanded ? "grid-template-columns: 18px auto;" : ""};
  justify-items: start;
  list-style: none;
  row-gap: 8px;
`

export const Link = styled(ReactRouterDomLink)`
  align-items: center;
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  display: grid;

  &:hover,
  &:focus {
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  }
`

export const Ul = styled.ul`
  display: grid;
  list-style-type: none;
  margin: 0;
  row-gap: 4px;
`

export const Li = styled.li`
  list-style: none;
`
