import type { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  & input {
    box-shadow: none;

    &:disabled {
      background: transparent;
    }

    &:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }

  & label,
  label.Mui-focused {
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  }

  & .MuiInput - underline: before,
  .MuiInput - underline: hover: not(.Mui - disabled): before,
  .MuiInput - underline:after {
  border - bottom: 1px solid
  ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  }
`
