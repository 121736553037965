/* eslint-disable no-empty-function */
import { useCallback, useState } from "react";
const DEFAULT_IMAGES = [];
export const initialContext = {
    addImage: () => { },
    images: DEFAULT_IMAGES,
    removeImage: () => { },
    resetImages: () => { }
};
const usePreviewsContext = () => {
    const [images, setImages] = useState(DEFAULT_IMAGES);
    const addImage = useCallback((image) => {
        const present = images.some((item) => item.serviceName === image.serviceName);
        if (!present) {
            setImages((prevImages) => [...prevImages, image]);
        }
    }, [images]);
    const removeImage = (serviceName) => {
        setImages((prevImages) => prevImages.filter((item) => item.serviceName !== serviceName));
    };
    const resetImages = () => {
        setImages(DEFAULT_IMAGES);
    };
    return {
        addImage,
        images,
        removeImage,
        resetImages
    };
};
export default usePreviewsContext;
