/* eslint-disable no-ternary */

import { H1 as BluBlocksH1, Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
`

export const Top = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const H1 = styled(BluBlocksH1)`
  font-size: 20px;
  margin: 0;
`

export const ItemsUl = styled.ul`
  align-items: start;
  display: grid;
  list-style-type: none;
  margin: 0;
  row-gap: 10px;
`

export const Li = styled.li`
  list-style: none;
`
