import gql from "graphql-tag";
const UPDATE_CRITICS_REVIEW_FROM_URL = gql `
  mutation updateCriticsReviewFromUrl(
    $id: ID!
    $serviceName: String!
    $url: String!
  ) {
    updateCriticsReviewFromUrl(id: $id, serviceName: $serviceName, url: $url) {
      id
      serviceName
    }
  }
`;
export default UPDATE_CRITICS_REVIEW_FROM_URL;
