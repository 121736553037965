/* eslint-disable max-lines-per-function, max-statements */
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import CREATE_HOME_VIDEO_REVIEWS_IMAGES_GRID from "mutations/createHomeVideoReviewsImagesGrid";
import ImagesGrid from "./ImagesGrid";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
const { homeVideoReviews: { GET_HOME_VIDEO_REVIEW, SEARCH_HOME_VIDEO_REVIEWS } } = queries;
const ID_VALIDATE = /\S{7}-\S{4}-\S{4}-\S{4}-\S{12}/u;
const ZERO = 0;
const SLICE_END = 80;
const SLICE_START = 0;
const usePrepareComponent = () => {
    const [homeVideoReviewIds, setHomeVideoReviewIds] = useState([""]);
    const [reviewNames, setReviewNames] = useState([]);
    const [getHomeVideoReview] = useLazyQuery(GET_HOME_VIDEO_REVIEW);
    const [createHomeVideoReviewsImagesGrid, { loading: isCreateHomeVideoImagesGridLoading }] = useMutation(CREATE_HOME_VIDEO_REVIEWS_IMAGES_GRID);
    const [searchHomeVideoReviews, { data: searchHomeVideoReviewsData, loading: searchHomeVideoReviewsLoading }] = useLazyQuery(SEARCH_HOME_VIDEO_REVIEWS);
    useEffect(() => {
        const hasEmptyRow = homeVideoReviewIds.filter((id) => id === "").length > ZERO;
        homeVideoReviewIds.forEach((homeVideoReviewId, index) => {
            if (homeVideoReviewId && ID_VALIDATE.test(homeVideoReviewId)) {
                getHomeVideoReview({
                    onCompleted: (homeVideoReviewData) => {
                        setReviewNames((prevReviewNames) => {
                            const newReviewNames = [...prevReviewNames];
                            newReviewNames[index] = `${homeVideoReviewData?.getHomeVideoReview.summary.total.long.slice(SLICE_START, SLICE_END)}...`;
                            return newReviewNames;
                        });
                    },
                    variables: {
                        id: homeVideoReviewId
                    }
                });
            }
        });
        if (!hasEmptyRow) {
            setHomeVideoReviewIds([...homeVideoReviewIds, ""]);
        }
    }, [getHomeVideoReview, homeVideoReviewIds]);
    const onSelectHomeVideoReviewId = (index) => (id) => {
        const newHomeVideoReviewIds = [...homeVideoReviewIds];
        newHomeVideoReviewIds[index] = id;
        setHomeVideoReviewIds(newHomeVideoReviewIds);
    };
    const onGetSuggestions = ({ inputValue }) => {
        searchHomeVideoReviews({
            variables: {
                searchHomeVideoReviewsInput: {
                    title: inputValue
                }
            }
        });
    };
    const onClickGenerate = () => {
        createHomeVideoReviewsImagesGrid({
            variables: {
                homeVideoReviewsIds: homeVideoReviewIds.filter((id) => id)
            }
        });
    };
    return {
        homeVideoReviewIds,
        homeVideoReviewTitles: searchHomeVideoReviewsData?.searchHomeVideoReviews ?? [],
        isAutoSuggestLoading: searchHomeVideoReviewsLoading,
        isCreateHomeVideoImagesGridLoading,
        onClickGenerate,
        onGetSuggestions,
        onSelectHomeVideoReviewId,
        reviewNames
    };
};
export default prepareComponent(usePrepareComponent)(ImagesGrid);
