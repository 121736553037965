/* eslint-disable no-ternary, max-statements, max-lines-per-function */

import {
  Footer,
  FooterUpdateInput,
  Heading,
  HeadingUpdateInput,
  Template,
  TemplateUpdateInput
} from "@bluframe/mightychroma-mightytool-frontend-types"
import { FormEventHandler, RefObject, useEffect, useRef, useState } from "react"
import Item from "./Item"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type ItemType = Footer | Heading | Template
type ItemUpdateType =
  | FooterUpdateInput
  | HeadingUpdateInput
  | TemplateUpdateInput

export interface Props {
  errors?: {
    footerId?: string
    headingId?: string
    html?: string
    identifier?: string
    name?: string
    placeholders?: string
    subject?: string
  }
  isAdd?: boolean
  isEditing?: boolean
  isTemplate?: boolean
  item?: ItemType
  onCancel: () => void
  onDelete?: () => void
  onEdit?: () => void
  // eslint-disable-next-line no-unused-vars
  onSave: (itemUpdate: ItemUpdateType) => void
  isSaveLoading?: boolean
}

export interface ComponentProps
  extends Omit<
    Props,
    "isAdd" | "isEditing" | "onCancel" | "onDelete" | "onEdit" | "onSave"
  > {
  actionButtonLabel: string
  actionButtonOnClick?: () => void
  footerIdRef: RefObject<HTMLInputElement>
  headingIdRef: RefObject<HTMLInputElement>
  htmlRef: RefObject<HTMLTextAreaElement>
  identifierRef: RefObject<HTMLInputElement>
  isModalOpen: boolean
  isEditMode: boolean
  nameRef: RefObject<HTMLInputElement>
  onModalClose: () => void
  onModalConfirm: () => void
  onPreviewClick: () => void
  onSubmit: FormEventHandler
  outlinedButtonLabel: string
  outlinedButtonOnClick?: () => void
  placeholdersRef: RefObject<HTMLInputElement>
  preview: string | undefined
  subjectPreview: string | undefined
  subjectRef: RefObject<HTMLInputElement>
}

const preparePreview = ({
  footer,
  heading,
  html
}: {
  footer?: string
  heading?: string
  html: string
}) => {
  let result = ""
  if (heading) {
    result = `${result}${heading}`
  }
  result = `${result}${html}`
  if (footer) {
    result = `${result}${footer}`
  }
  return result
}

const usePrepareComponent = ({
  isAdd,
  isEditing,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  ...props
}: Props): ComponentProps => {
  const [preview, setPreview] = useState<string | undefined>(() =>
    preparePreview({
      footer: (props.item as Template)?.footer?.html,
      heading: (props.item as Template)?.heading?.html,
      html: props.item?.html ?? ""
    })
  )
  const [subjectPreview, setSubjectPreview] = useState<string | undefined>(
    () => (props.item as Template)?.subject ?? ""
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const footerIdRef = useRef<HTMLInputElement>(null)
  const headingIdRef = useRef<HTMLInputElement>(null)
  const htmlRef = useRef<HTMLTextAreaElement>(null)
  const identifierRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const placeholdersRef = useRef<HTMLInputElement>(null)
  const subjectRef = useRef<HTMLInputElement>(null)

  const isEditMode = Boolean(isAdd || isEditing)

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const onModalOpen = () => {
    setIsModalOpen(true)
  }

  const onModalConfirm = () => {
    onDelete?.()
    onModalClose()
  }

  const actionButtonLabel = isEditMode ? "Save" : "Delete"
  const outlinedButtonLabel = isEditMode ? "Cancel" : "Edit"

  const actionButtonOnClick = isEditMode ? undefined : onModalOpen
  const outlinedButtonOnClick = isEditMode ? onCancel : onEdit

  useEffect(() => {
    setPreview(
      preparePreview({
        footer: (props.item as Template)?.footer?.html,
        heading: (props.item as Template)?.heading?.html,
        html: props.item?.html ?? ""
      })
    )
    setSubjectPreview((props.item as Template)?.subject)
  }, [props.item])

  const onPreviewClick = () => {
    if (htmlRef.current) {
      setPreview(
        preparePreview({
          footer: (props.item as Template)?.footer?.html,
          heading: (props.item as Template)?.heading?.html,
          html: htmlRef.current.value
        })
      )
      setSubjectPreview(subjectRef.current?.value)
    }
  }

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    if (!htmlRef.current || !nameRef.current) {
      return
    }

    onSave({
      footerId: footerIdRef.current?.value,
      headingId: headingIdRef.current?.value,
      html: htmlRef.current.value,
      identifier: identifierRef.current?.value,
      name: nameRef.current.value || "",
      placeholders: placeholdersRef.current?.value
        ? placeholdersRef.current?.value.split(",")
        : undefined,
      subject: subjectRef.current?.value
    })
  }

  return {
    ...props,
    actionButtonLabel,
    actionButtonOnClick,
    footerIdRef,
    headingIdRef,
    htmlRef,
    identifierRef,
    isEditMode,
    isModalOpen,
    nameRef,
    onModalClose,
    onModalConfirm,
    onPreviewClick,
    onSubmit,
    outlinedButtonLabel,
    outlinedButtonOnClick,
    placeholdersRef,
    preview,
    subjectPreview,
    subjectRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
