/* eslint-disable no-ternary */

import { Heading, Ul, Wrapper } from "./styled"
import { Loading, Reset } from "@bluframe/blublocks"
import { ComponentProps } from "."
import Section from "./Section"

const Dashboard = ({ isLoading, sections }: ComponentProps) => (
  <Wrapper aria-labelledby="dashboard" role="region">
    <Heading id="dashboard">{"Dashboard"}</Heading>
    {isLoading ? (
      <Loading />
    ) : (
      <Ul aria-label="Sections">
        {sections.map((section) => (
          <Reset.Li key={section.id}>
            <Section {...section} />
          </Reset.Li>
        ))}
      </Ul>
    )}
  </Wrapper>
)

export default Dashboard
