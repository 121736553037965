import { gql } from "graphql-tag"

const site = gql`
  type SiteUser {
    email: String!
    emailPreferences: EmailPreferences!
    firstName: String
    id: ID!
    lastName: String
    username: String
  }
`

export default site
