import { ComponentProps } from "."
import { Wrapper } from "./styled"

const Content = ({
  components,
  content,
  max,
  paragraphAs,
  stripMarkdown
}: ComponentProps) => (
  <Wrapper
    components={components}
    content={content}
    max={max}
    paragraphAs={paragraphAs}
    stripMarkdown={stripMarkdown}
  />
)

export default Content
