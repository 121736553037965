import { Box, Wrapper } from "./styled"
import Arrow from "./Arrow"
import { ComponentProps } from "."

const Pagination = ({
  hasNextData,
  hasPrevData,
  link,
  next,
  prev
}: ComponentProps) => (
  <Wrapper>
    <Box>
      <Arrow cursor={prev} direction="prev" hasData={hasPrevData} link={link} />
    </Box>
    <Box>
      <Arrow cursor={next} direction="next" hasData={hasNextData} link={link} />
    </Box>
  </Wrapper>
)

export default Pagination
