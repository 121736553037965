import gql from "graphql-tag"
import homeVideoReview from "fragments/homeVideoReview"

const GET_DRAFTS_WAVE = gql`
  ${homeVideoReview}

  query getDraftsWave($cursor: String) {
    draftsWave(cursor: $cursor) {
      cursor
      hasNextData
      hasPrevData
      homeVideoReviews {
        ...HomeVideoReview
      }
      prevCursor
    }
  }
`
export default GET_DRAFTS_WAVE
