import { gql } from "graphql-tag"

const query = gql`
  type Query {
    categories(createdAt: String): [Category!]!
    category(id: ID!, updatedAt: String): Category

    sections(createdAt: String): [Section!]!
    section(id: ID!, updatedAt: String): Section
  }
`

export default query
