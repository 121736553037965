/* eslint-disable no-ternary, max-statements, max-lines-per-function */

import {
  Contest,
  ContestUpdateInput,
  SiteUser
} from "@bluframe/mightychroma-mightytool-frontend-types"
import {
  FormEventHandler,
  RefObject,
  createRef,
  useMemo,
  useRef,
  useState
} from "react"
import Item from "./Item"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type ModalContent =
  | "deleteConfirm"
  | "publishConfirm"
  | "sendConfirm"
  | "sendTestConfirm"
  | null

const ONE = 1
export const MAX_RULES = 4

export interface Props {
  emailPreview?: string
  isAdd?: boolean
  isEditing?: boolean
  item?: Contest
  onCancel: () => void
  onDelete?: () => void
  onEdit?: () => void
  // eslint-disable-next-line no-unused-vars
  onEmailSend?: (templateId: string) => void
  // eslint-disable-next-line no-unused-vars
  onEmailTestSend?: (templateId: string) => void
  // eslint-disable-next-line no-unused-vars
  onSave: (contest: ContestUpdateInput) => void
  // eslint-disable-next-line no-unused-vars
  onTemplateIdChange?: (templateId: string) => void
  users?: SiteUser[]
  usersContestsEmails?: SiteUser[]
}

export interface ComponentProps
  extends Omit<
    Props,
    | "isEditing"
    | "onCancel"
    | "onDelete"
    | "onEdit"
    | "onEmailSend"
    | "onEmailTestSend"
    | "onSave"
    | "onTemplateIdChange"
  > {
  actionButtonLabel: string
  actionButtonOnClick?: () => void
  conclusionRef: RefObject<HTMLTextAreaElement>
  countryIdRef: RefObject<HTMLInputElement>
  endDateRef: RefObject<HTMLInputElement>
  imageIdRef: RefObject<HTMLInputElement>
  introRef: RefObject<HTMLTextAreaElement>
  isModalOpen: boolean
  isEditMode: boolean
  modalContent: ModalContent
  onModalClose: () => void
  // eslint-disable-next-line no-unused-vars
  onModalConfirmOpen: (modalContent: ModalContent) => () => void
  onModalDeleteConfirm: () => void
  onModalSendConfirm: () => void
  onModalSendTestConfirm: () => void
  onPreviewClick: () => void
  onPublishToggle: () => void
  onSubmit: FormEventHandler
  outlinedButtonLabel: string
  outlinedButtonOnClick?: () => void
  ruleRefs: RefObject<HTMLInputElement>[]
  rules?: string
  slugRef: RefObject<HTMLInputElement>
  startDateRef: RefObject<HTMLInputElement>
  subtitleRef: RefObject<HTMLInputElement>
  templateIdRef: RefObject<HTMLInputElement>
  titleRef: RefObject<HTMLInputElement>
}

const DEFAULT_MODAL_CONTENT = null

const usePrepareComponent = ({
  isEditing,
  onCancel,
  onDelete,
  onEdit,
  onEmailSend,
  onEmailTestSend,
  onTemplateIdChange,
  onSave,
  ...props
}: Props): ComponentProps => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContent>(
    DEFAULT_MODAL_CONTENT
  )

  const conclusionRef = useRef<HTMLTextAreaElement>(null)
  const countryIdRef = useRef<HTMLInputElement>(null)
  const endDateRef = useRef<HTMLInputElement>(null)
  const imageIdRef = useRef<HTMLInputElement>(null)
  const introRef = useRef<HTMLTextAreaElement>(null)
  const ruleRefs: RefObject<HTMLInputElement>[] = useMemo(() => {
    const refs = []

    for (let index = 0; index < MAX_RULES; index += ONE) {
      if (!refs[index]) {
        // Initialize ref only if it doesn't exist
        refs[index] = createRef<HTMLInputElement>()
      }
    }

    return refs
  }, [])
  const slugRef = useRef<HTMLInputElement>(null)
  const startDateRef = useRef<HTMLInputElement>(null)
  const subtitleRef = useRef<HTMLInputElement>(null)
  const templateIdRef = useRef<HTMLInputElement>(null)
  const titleRef = useRef<HTMLInputElement>(null)

  const isEditMode = Boolean(props.isAdd || isEditing)

  const onModalClose = () => {
    setModalContent(DEFAULT_MODAL_CONTENT)
    setIsModalOpen(false)
  }

  const onModalConfirmOpen = (newModalContent: ModalContent) => () => {
    setModalContent(newModalContent)
    setIsModalOpen(true)
  }

  const onModalDeleteConfirm = () => {
    onDelete?.()
    onModalClose()
  }

  const onModalSendConfirm = () => {
    if (templateIdRef.current) {
      onEmailSend?.(templateIdRef.current.value)
    }

    onModalClose()
  }

  const onModalSendTestConfirm = () => {
    if (templateIdRef.current) {
      onEmailTestSend?.(templateIdRef.current.value)
    }

    onModalClose()
  }

  const actionButtonLabel = isEditMode ? "Save" : "Delete"
  const outlinedButtonLabel = isEditMode ? "Cancel" : "Edit"

  const actionButtonOnClick = isEditMode
    ? undefined
    : onModalConfirmOpen("deleteConfirm")
  const outlinedButtonOnClick = isEditMode ? onCancel : onEdit

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    if (!titleRef.current) {
      return
    }

    onSave({
      conclusion: conclusionRef.current?.value ?? "",
      countryIds: countryIdRef.current?.value
        ? [countryIdRef.current.value]
        : undefined,
      endDate: endDateRef.current?.value ?? "",
      imageId: imageIdRef.current?.value
        ? Number(imageIdRef.current?.value)
        : undefined,
      intro: introRef.current?.value ?? "",
      rules: ruleRefs.map((ruleRef) => ruleRef.current?.value ?? ""),
      slug: slugRef.current?.value ?? "",
      startDate: startDateRef.current?.value ?? "",
      subtitle: subtitleRef.current?.value,
      title: titleRef.current.value ?? ""
    })
  }

  const onPreviewClick = () => {
    if (templateIdRef.current) {
      onTemplateIdChange?.(templateIdRef.current.value)
    }
  }

  const rules = props.item?.rules?.reduce(
    (acc, rule) => `${acc}- ${rule}\n`,
    ""
  )

  const onPublishToggle = () => {
    onSave({
      isPublished: !props.item?.isPublished
    })
    onModalClose()
  }

  return {
    ...props,
    actionButtonLabel,
    actionButtonOnClick,
    conclusionRef,
    countryIdRef,
    endDateRef,
    imageIdRef,
    introRef,
    isEditMode,
    isModalOpen,
    modalContent,
    onModalClose,
    onModalConfirmOpen,
    onModalDeleteConfirm,
    onModalSendConfirm,
    onModalSendTestConfirm,
    onPreviewClick,
    onPublishToggle,
    onSubmit,
    outlinedButtonLabel,
    outlinedButtonOnClick,
    ruleRefs,
    rules,
    slugRef,
    startDateRef,
    subtitleRef,
    templateIdRef,
    titleRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
