/* eslint-disable no-ternary, max-lines-per-function */

import { AddButton, Loading } from "@bluframe/blublocks"
import { H1, ItemsUl, Li, Top, Wrapper } from "./styled"
import { ComponentProps } from "."
import Item from "./Item"
import Pagination from "components/Pagination"

const List = ({
  hasAdd = true,
  items,
  label,
  loading,
  onAdd,
  pagination
}: ComponentProps) => (
  <Wrapper>
    <Top>
      <H1>{label}</H1>
      {hasAdd ? (
        <AddButton label={`Add ${label}`} onClick={onAdd} size="36px" />
      ) : null}
    </Top>
    {pagination ? <Pagination {...pagination} /> : null}
    {loading ? <Loading /> : null}
    {items ? (
      <ItemsUl aria-label={label}>
        {items.map((item) => (
          <Li aria-label={item.label} key={item.id}>
            <Item {...item} />
          </Li>
        ))}
      </ItemsUl>
    ) : null}
    {pagination ? <Pagination {...pagination} /> : null}
  </Wrapper>
)

export default List
