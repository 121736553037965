import { ComponentProps } from "."
import { Toggle } from "@bluframe/blublocks"

const ActionsCheckbox = ({
  checked,
  disabled,
  inputId,
  label,
  name,
  onChange
}: ComponentProps) => (
  <Toggle
    checked={checked}
    disabled={disabled}
    inputId={inputId}
    label={label}
    name={name}
    onChange={onChange}
  />
)

export default ActionsCheckbox
