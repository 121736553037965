/* eslint-disable no-ternary */

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers"
import type { ComponentProps } from "."
import DateFnsUtils from "@date-io/date-fns"
import { Wrapper } from "../styled"
import { makeStyles } from "@material-ui/core/styles"
import theme from "styles/theme"

const useStyles = makeStyles({
  root: {
    "& .MuiPickersDay-daySelected": {
      background: theme.palette.primary.main
    },
    "&.MuiPopover-root": {
      zIndex: "10000 !important"
    }
  }
})

const DateComponent = ({
  disabled,
  label,
  onChange,
  value
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        <KeyboardDatePicker
          KeyboardButtonProps={{
            "aria-label": "change release date"
          }}
          PopoverProps={{
            classes
          }}
          autoOk
          disableToolbar
          disabled={disabled}
          format="dd/MM/yyyy"
          label={label}
          margin="normal"
          // @ts-ignore
          onChange={onChange}
          value={value}
          variant="inline"
        />
      </Wrapper>
    </MuiPickersUtilsProvider>
  )
}

export default DateComponent
