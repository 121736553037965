/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, complexity */

import {
  Buttons,
  Form,
  H1,
  H2,
  H3,
  HeadingAndButtons,
  Inputs,
  Main,
  NameAndEmailPreferences,
  OptedOutUsersCount,
  Placeholders,
  PlaceholdersAndHeading,
  Preview,
  PublishedButton,
  SubtitleH2,
  UsersCount
} from "./styled"
import { ConfirmModal, InputText, Modal } from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import EditableText from "components/inputs/EditableText"

const ZERO = 0

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  emailPreferencesRef,
  emailPreview,
  isAdd,
  isEditMode,
  isSendLoading,
  isSendTestLoading,
  isModalOpen,
  item,
  modalContent,
  nameRef,
  onModalClose,
  onModalConfirmOpen,
  onModalDeleteConfirm,
  onModalSendConfirm,
  onModalSendTestConfirm,
  onPreviewClick,
  onSubmit,
  outlinedButtonLabel,
  outlinedButtonOnClick,
  placeholdersRefs,
  subject,
  templateIdRef
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <HeadingAndButtons>
      <NameAndEmailPreferences $isEditMode={isEditMode}>
        {isEditMode ? (
          <>
            <InputText
              defaultValue={item?.name}
              inputId="name"
              label="Name"
              name="name"
              ref={nameRef}
              required
            />
            <InputText
              defaultValue={item?.emailPreferences}
              inputId="emailPreferences"
              label="Email Preferences"
              name="emailPreferences"
              ref={emailPreferencesRef}
              required
            />
          </>
        ) : (
          <>
            <H1>{item?.name}</H1>
            <SubtitleH2>{item?.emailPreferences}</SubtitleH2>
          </>
        )}
      </NameAndEmailPreferences>
      <Buttons>
        <Button
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          label={actionButtonLabel}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
        {isAdd ? null : (
          <PublishedButton
            label={item?.isPublished ? "Published" : "Not Published"}
            noHoverHighlight
            outlined={!item?.isPublished}
            secondary
          />
        )}
      </Buttons>
    </HeadingAndButtons>
    <Main>
      {isAdd ? null : (
        <>
          <H2>{"Email"}</H2>
          <Inputs>
            <EditableText
              defaultValue={item?.templateId}
              inputId="templateId"
              isEditing={isEditMode}
              label="Email Template Id"
              name="templateId"
              ref={templateIdRef}
            />
            <Button
              label="Preview"
              onClick={onPreviewClick}
              outlined
              secondary
            />
            <Button
              disabled={item?.isPublished || isEditMode || isSendLoading}
              label="Send"
              loading={isSendLoading}
              onClick={onModalConfirmOpen("sendConfirm")}
            />
            <Button
              disabled={isEditMode || isSendTestLoading}
              label="Send Test"
              loading={isSendTestLoading}
              onClick={onModalConfirmOpen("sendTestConfirm")}
              outlined
              secondary
            />
            <UsersCount>
              <H3>{`Newsletter Users: ${
                item?.usersSubscribedCount ?? ZERO
              }`}</H3>
              <OptedOutUsersCount>{`Opted-out Users: ${
                item?.usersOptedOutCount ?? ZERO
              }`}</OptedOutUsersCount>
            </UsersCount>
          </Inputs>
          <H2>{"Preview"}</H2>
          {subject ? <H3>{`Subject: ${subject}`}</H3> : null}
          <Preview
            dangerouslySetInnerHTML={{
              __html: emailPreview ?? ""
            }}
          />
        </>
      )}
    </Main>
    <PlaceholdersAndHeading>
      <HeadingAndButtons>
        <H2>{"Placeholders"}</H2>{" "}
        <Buttons>
          <Button
            label={outlinedButtonLabel}
            onClick={outlinedButtonOnClick}
            outlined
          />
          {isEditMode ? (
            <Button
              label={actionButtonLabel}
              onClick={actionButtonOnClick}
              type={isEditMode ? "submit" : "button"}
            />
          ) : null}
        </Buttons>
      </HeadingAndButtons>
      <Placeholders>
        {Object.entries(item?.placeholders ?? {}).map(([key, value]) => (
          <EditableText
            defaultValue={value}
            isEditable={isEditMode}
            isEditing={isEditMode}
            key={key}
            label={key}
            name={key}
            ref={placeholdersRefs[key]}
          />
        ))}
      </Placeholders>
    </PlaceholdersAndHeading>
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      {modalContent === "deleteConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to delete ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalDeleteConfirm}
        />
      ) : null}
      {modalContent === "sendConfirm" ? (
        <ConfirmModal
          message={
            <>
              <H2>
                {`Are you sure you want to email this newsletter to ${
                  item?.usersSubscribedCount ?? ZERO
                } users?`}
              </H2>
              <SubtitleH2>
                {`${
                  item?.usersOptedOutCount ?? ZERO
                } users have chosen to opt out and are not going to receive the email`}
              </SubtitleH2>
            </>
          }
          onClose={onModalClose}
          onConfirm={onModalSendConfirm}
        />
      ) : null}
      {modalContent === "sendTestConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to send a test email to info@mightychroma.me?`}
          onClose={onModalClose}
          onConfirm={onModalSendTestConfirm}
        />
      ) : null}
    </Modal>
  </Form>
)

export default Item
