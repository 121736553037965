/* eslint-disable no-ternary */

import { green, grey, red } from "@material-ui/core/colors"
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone"
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone"
import { ComponentProps } from "."
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import { Wrapper } from "../styled"
import { makeStyles } from "@material-ui/core/styles"

const COLOR_WEIGHT = 500

const useStyles = makeStyles({
  iconGreen: {
    color: green[COLOR_WEIGHT]
  },
  iconRed: {
    color: red[COLOR_WEIGHT]
  },
  input: {
    color: grey[COLOR_WEIGHT]
  }
})

const Text = ({
  disabled,
  hasValid,
  id,
  isValid,
  label,
  name,
  onChange,
  text
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <Wrapper>
      <TextField
        InputProps={{
          className: classes.input,
          startAdornment: hasValid && (
            <InputAdornment position="start">
              {isValid ? (
                <CheckCircleTwoToneIcon className={classes.iconGreen} />
              ) : (
                <CancelTwoToneIcon className={classes.iconRed} />
              )}
            </InputAdornment>
          )
        }}
        disabled={disabled}
        id={id}
        label={label}
        name={name}
        onChange={onChange}
        value={text}
      />
    </Wrapper>
  )
}

export default Text
