import category from "fragments/category"
import gql from "graphql-tag"

const GET_CATEGORIES = gql`
  ${category}

  query categories($createdAt: String) {
    categories(createdAt: $createdAt) {
      ...Category
    }
  }
`
export default GET_CATEGORIES
