/* eslint-disable no-ternary, max-lines-per-function */

import { FormEventHandler, RefObject, useRef, useState } from "react"
import PodSource from "./PodSource"
import { ReviewOverrides } from "@bluframe/mightychroma-microservices-critics"
import { ServiceName } from "@bluframe/mightychroma-mightytool-frontend-types"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  areReviewsLoading: boolean
  isCompact: boolean
  isCreated: boolean
  isLoading: boolean
  isValid: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onClear: (serviceName: ServiceName) => () => void
  onSetOverrides: (
    // eslint-disable-next-line no-unused-vars
    serviceName: ServiceName
    // eslint-disable-next-line no-unused-vars
  ) => (overrides: ReviewOverrides) => void
  overrides?: ReviewOverrides
  serviceName: ServiceName
  text: string
}

export interface ComponentProps extends Omit<Props, "onSetOverrides"> {
  inputRefs: {
    [key: string]: {
      quote: RefObject<HTMLTextAreaElement>
      score: RefObject<HTMLInputElement>
      section: RefObject<HTMLTextAreaElement>
    }
  }
  isEditOpen: boolean
  onCancel: () => void
  onSave: FormEventHandler
  // eslint-disable-next-line no-unused-vars
  onSetIsEditOpen: (isOpen: boolean) => () => void
  // eslint-disable-next-line no-unused-vars
  onSetReleaseDate: (releaseDate: string) => void
  overrides: ReviewOverrides
}

const DEFAULT_IS_EDIT_OPEN = false
const DEFAULT_OVERRIDES: ReviewOverrides = {}

const usePrepareComponent = ({
  onSetOverrides,
  ...props
}: Props): ComponentProps => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(DEFAULT_IS_EDIT_OPEN)
  const [newOverrides, setNewOverrides] =
    useState<ReviewOverrides>(DEFAULT_OVERRIDES)

  const inputRefs = {
    audio: {
      quote: useRef(),
      score: useRef(),
      section: useRef()
    },
    extra: { quote: useRef(), score: useRef(), section: useRef() },
    movie: { quote: useRef(), score: useRef(), section: useRef() },
    total: { quote: useRef(), score: useRef(), section: useRef() },
    video: { quote: useRef(), score: useRef(), section: useRef() }
  }

  const onSetIsEditOpen = (isOpen: boolean) => () => {
    setIsEditOpen(isOpen)
  }

  const onSetReleaseDate = (releaseDate: string) => {
    setNewOverrides((prev) => ({ ...prev, releaseDate }))
  }

  const onCancel = () => {
    setNewOverrides(DEFAULT_OVERRIDES)
    setIsEditOpen(false)
  }

  const onSave: FormEventHandler = (event) => {
    event.preventDefault()

    onSetOverrides(props.serviceName)({
      audio: {
        // @ts-ignore
        quote: inputRefs.audio.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.audio.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.audio.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.audio.section.current?.value || undefined
      },
      extra: {
        // @ts-ignore
        quote: inputRefs.extra.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.extra.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.extra.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.extra.section.current?.value || undefined
      },
      movie: {
        // @ts-ignore
        quote: inputRefs.movie.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.movie.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.movie.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.movie.section.current?.value || undefined
      },
      releaseDate: newOverrides.releaseDate,
      total: {
        // @ts-ignore
        quote: inputRefs.total.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.total.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.total.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.total.section.current?.value || undefined
      },
      video: {
        // @ts-ignore
        quote: inputRefs.video.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.video.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.video.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.video.section.current?.value || undefined
      }
    })
    setIsEditOpen(false)
  }

  return {
    ...props,
    // @ts-ignore
    inputRefs,
    isEditOpen,
    onCancel,
    onSave,
    onSetIsEditOpen,
    onSetReleaseDate,
    overrides: newOverrides
  }
}

export default prepareComponent(usePrepareComponent)(PodSource)
