/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary */
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import Item from "./Item";
import onEmailsError from "components/Emails/onEmailsError";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useState } from "react";
import useToggle from "@bluframe/grapple/useToggle";
const DEFAULT_ERRORS = {};
const usePrepareComponent = ({ getQuery, getQueryDataName, itemName, updateMutation }) => {
    const [isEditing, toggleIsEditing] = useToggle();
    const history = useHistory();
    const params = useParams();
    const [errors, setErrors] = useState(DEFAULT_ERRORS);
    const { data, loading } = useQuery(getQuery, {
        variables: {
            id: params.id
        }
    });
    const [updateEmailsItem, { loading: isSaveLoading }] = useMutation(updateMutation, {
        refetchQueries: [{ query: getQuery, variables: { id: params.id } }]
    });
    const onCancel = () => {
        history.push(`/emails/${itemName}s`);
    };
    const onEdit = () => {
        toggleIsEditing();
    };
    const onSave = (itemUpdate) => {
        const updateInputName = itemName === "footer"
            ? "emailsFooterUpdateInput"
            : itemName === "heading"
                ? "emailsHeadingUpdateInput"
                : "emailsTemplateUpdateInput";
        updateEmailsItem({
            onCompleted: () => {
                toggleIsEditing();
            },
            onError: onEmailsError(setErrors),
            variables: {
                id: params.id,
                [updateInputName]: {
                    footerId: itemUpdate.footerId,
                    headingId: itemUpdate.headingId,
                    html: itemUpdate.html,
                    identifier: itemUpdate.identifier,
                    name: itemUpdate.name,
                    placeholders: itemUpdate.placeholders,
                    subject: itemUpdate.subject
                }
            }
        });
    };
    return {
        errors,
        isEditing,
        isSaveLoading,
        isTemplate: itemName === "template",
        item: data?.[getQueryDataName],
        loading,
        onCancel,
        onEdit,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Item);
