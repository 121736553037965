/* eslint-disable no-ternary */

import Image from "./Image"
import { ImageFormats } from "@bluframe/mightychroma-mightytool-frontend-types"
import generateSrcSet from "helpers/images/generateSrcSet"
import prepareComponent from "@bluframe/grapple/prepareComponent"

const DEFAULT_SIZES =
  "(min-width: 1920px) 10vw, (min-width: 1440px) 14vw, (min-width: 1280px) 15vw, (min-width: 1200px) 16vw, (min-width: 1024px) 20vw, (min-width: 768px) 25vw, (max-width: 767px) 30vw, 100vw"

export interface Props {
  alternativeText?: string
  formats: Partial<ImageFormats>
  height?: number
  isContain?: boolean
  isFill?: boolean
  name: string
  sizes?: string
  url: string
  width?: number
}

export interface ComponentProps extends Props {
  alternativeText: string
  srcset: string
  sizes: string
}

export const usePrepareComponent = (props: Props): ComponentProps => {
  return {
    ...props,
    alternativeText: props.alternativeText ?? props.name,
    sizes: props.sizes ?? DEFAULT_SIZES,
    srcset: generateSrcSet({ formats: props.formats, url: props.url })
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Image
)
