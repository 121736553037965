import colors from "styles/colors"
import styled from "styled-components"

export const Li = styled.li`
  cursor: pointer;
  list-style-type: none;

  & a {
    color: ${colors.tundora};
    text-decoration: none;
    transition: 0.3s;
  }
`

export const Text = styled.div`
  padding: 5px 20px;
  transition: 0.3s;

  &:hover {
    background: ${colors.alabaster};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
