import { Cards, Heading, Wrapper } from "./styled"
import { ComponentProps } from "."

const Section = ({ cards, heading, id, span }: ComponentProps) => (
  <Wrapper aria-labelledby={`${id}-heading`} id={id} role="region">
    <Heading id={`${id}-heading`}>{heading}</Heading>
    <Cards $span={span} ariaLabel={`${heading} cards`} cards={cards} />
  </Wrapper>
)

export default Section
