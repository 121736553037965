import gql from "graphql-tag"
import mas from "fragments/mas"

const SEARCH_HOME_VIDEO_REVIEWS = gql`
  ${mas}

  query searchHomeVideoReviews(
    $searchHomeVideoReviewsInput: SearchHomeVideoReviewsInput!
  ) {
    searchHomeVideoReviews(
      searchHomeVideoReviewsInput: $searchHomeVideoReviewsInput
    ) {
      id
      mas {
        ...MAS
      }
    }
  }
`

export default SEARCH_HOME_VIDEO_REVIEWS
