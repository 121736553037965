import gql from "graphql-tag"
import section from "fragments/section"

const GET_SECTION = gql`
  ${section}

  query section($id: ID!, $updatedAt: String) {
    section(id: $id, updatedAt: $updatedAt) {
      ...Section
    }
  }
`
export default GET_SECTION
