/* eslint-disable no-ternary */

import { Li, Link, Ul, Wrapper } from "./styled"
import { ComponentProps } from "."
import { Icon } from "@bluframe/blublocks"
import SubItem from "./SubItem"
import theme from "styles/theme"

const Item = ({
  iconGroup,
  iconName,
  isAdmin,
  isExpanded,
  isShowSubItems,
  label,
  link,
  onToggleIsShowSubItems,
  subItems
}: ComponentProps) => (
  <Wrapper $isExpanded={isExpanded}>
    <Link onClick={onToggleIsShowSubItems} to={link ?? "#"}>
      <Icon
        color={theme.palette.primary.main}
        group={iconGroup}
        name={iconName}
        size="18px"
        title={label}
      />
    </Link>
    {isExpanded ? (
      <Link onClick={onToggleIsShowSubItems} to={link ?? "#"}>
        {label}
      </Link>
    ) : null}
    {subItems && isExpanded && isShowSubItems ? (
      <>
        <div />
        <Ul aria-label={`${label} Navigation Sub Items`}>
          {subItems
            .filter((item) => (isAdmin ? true : !item.isAdmin))
            .map(({ isAdmin: __, ...subItem }) => (
              <Li aria-label={`${label} ${subItem.label}`} key={subItem.link}>
                <SubItem {...subItem} />
              </Li>
            ))}
        </Ul>
      </>
    ) : null}
  </Wrapper>
)

export default Item
