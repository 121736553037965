import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
export const Wrapper = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
`;
export const PreviewWrapper = styled.div ``;
export const Preview = styled(Paper) `
  display: grid;
  justify-content: center;
  max-height: 900px;
  min-height: 900px;
  overflow-y: scroll;
  padding: 20px;
`;
export const Main = styled.div `
  display: grid;
  row-gap: 20px;
`;
