/* eslint-disable no-ternary */

import {
  H2 as BluBlocksH2,
  SubtitleH2 as BluBlocksSubtitleH2,
  Theme
} from "@bluframe/blublocks"
import styled from "styled-components"

export const Form = styled.form`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 40px;
  width: 100%;
`

export const Top = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const Buttons = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
`

export const CurrentAndPreview = styled.div<{ $hasCurrent: boolean }>`
  column-gap: 20px;
  display: grid;
  ${({ $hasCurrent }) =>
    $hasCurrent
      ? `grid-template-columns: 1fr 1fr;`
      : `grid-template-columns: 1fr;`}
`

export const HeadingsAndDetails = styled.div`
  display: grid;
  row-gap: 20px;
`

export const H2 = styled(BluBlocksH2)`
  font-size: 18px;
`

export const SubtitleH2 = styled(BluBlocksSubtitleH2)`
  font-size: 16px;
  height: 25px;
`
