/* eslint-disable no-ternary */

import {
  H1 as BluBlocksH1,
  H3 as BluBlocksH3,
  SubtitleH1 as BluBlocksSubtitleH1,
  TextArea,
  Theme
} from "@bluframe/blublocks"
import styled from "styled-components"

export const Form = styled.form`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 40px;
  width: 100%;
`

export const Top = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const H1 = styled(BluBlocksH1)`
  font-size: 22px;
  margin: 0;
`

export const SubtitleH1 = styled(BluBlocksSubtitleH1)`
  font-size: 18px;
`

export const Buttons = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Main = styled.div<{ $isEditing?: boolean }>`
  display: grid;
  ${({ $isEditing }) =>
    $isEditing
      ? `
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  `
      : ""}
`

export const Inputs = styled.div`
  display: grid;
  row-gap: 20px;
`

export const HtmlTextArea = styled(TextArea)`
  & textarea {
    padding-bottom: 16px;
  }

  & label {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.palette.text.contrast};
    padding: 0 10px;
  }
`

export const SubjectAndPreview = styled.div`
  display: grid;
  row-gap: 40px;
`

export const Subject = styled(BluBlocksH3)`
  font-size: 16px;
`

export const Preview = styled.div``
