import gql from "graphql-tag";
const GET_EMAILS_FOOTER = gql `
  query getEmailsFooter($id: ID!) {
    getEmailsFooter(id: $id) {
      html
      id
      name
    }
  }
`;
export default GET_EMAILS_FOOTER;
