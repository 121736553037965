/* eslint-disable no-ternary */

import { Link, Wrapper } from "./styled"
import { ComponentProps } from "."
import { Icon } from "@bluframe/blublocks"
import theme from "styles/theme"

const SubItem = ({ iconName, label, link }: ComponentProps) => (
  <Wrapper $iconName={iconName}>
    {iconName ? (
      <Link to={link}>
        <Icon
          color={theme.palette.primary.main}
          name={iconName}
          size="18px"
          title={label}
        />
      </Link>
    ) : (
      <div />
    )}
    <Link to={link}>{label}</Link>
  </Wrapper>
)

export default SubItem
