import GET_CATEGORIES from "./categories"
import GET_CATEGORY from "./category"
import GET_SECTION from "./section"
import GET_SECTIONS from "./sections"

export default {
  GET_CATEGORIES,
  GET_CATEGORY,
  GET_SECTION,
  GET_SECTIONS
}
