import GET_ME from "queries/getMe";
import { useQuery } from "@apollo/client";
export const initialContext = {
    firstName: "",
    isAdmin: false,
    onClearToken: () => undefined,
    onGetToken: () => undefined,
    onSetToken: () => undefined
};
const useUserContext = () => {
    const { data } = useQuery(GET_ME, {
        fetchPolicy: "cache-first"
    });
    const onClearToken = () => {
        localStorage.removeItem("token");
    };
    const onSetToken = (newToken) => {
        localStorage.setItem("token", newToken);
    };
    const onGetToken = () => localStorage.getItem("token");
    return {
        firstName: data?.me?.firstName ?? "",
        isAdmin: data?.me?.isAdmin ?? false,
        onClearToken,
        onGetToken,
        onSetToken,
        username: data?.me?.username
    };
};
export default useUserContext;
