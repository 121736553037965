import CardContent from "@material-ui/core/CardContent"
import type { ComponentProps } from "."
import Header from "./Header"
import List from "./List"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  cardContent: {
    height: 300
  }
})

const Content = ({
  avatar,
  display,
  flagLoading,
  isShowFlagButton,
  onSelect,
  onToggleFlagged,
  selected,
  subheader,
  title
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <CardContent className={classes.cardContent}>
      <Header avatar={avatar} subheader={subheader} title={title} />
      <List
        display={display}
        flagLoading={flagLoading}
        isShowFlagButton={isShowFlagButton}
        onSelect={onSelect}
        onToggleFlagged={onToggleFlagged}
        selected={selected}
      />
    </CardContent>
  )
}

export default Content
