import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-ternary */
import { H2, Loading } from "@bluframe/blublocks";
import { Main, Overlay, Wrapper } from "./styled";
import Navigation from "components/Navigation";
import { useLocation } from "react-router-dom";
const Layout = ({ children, isExpanded, isShowOverlay, onToggleIsExpanded, overlayLabel }) => {
    const location = useLocation();
    const isShowLocation = location.pathname !== "/login";
    return (_jsxs(_Fragment, { children: [_jsxs(Overlay, { "$isShowOverlay": isShowOverlay, children: [_jsx(H2, { children: overlayLabel }), _jsx(Loading, { size: 48 })] }), _jsxs(Wrapper, { "$hasLeftPadding": isShowLocation, "$isExpanded": isExpanded, children: [isShowLocation ? (_jsx(Navigation, { isExpanded: isExpanded, onToggleIsExpanded: onToggleIsExpanded })) : null, _jsx(Main, { children: children })] })] }));
};
export default Layout;
