const gauge = {
  apple: "#50B92E",
  schoolBusYellow: "#FFE002"
}

const blues = {
  dodgerBlue: "#0F89FF"
}

const reds = {
  blazeOrange: "#FF5C03",
  california: "#FFA30A",
  red: "#FF0F03"
}

const whites = {
  alabaster: "#FAFAFA",
  white: "#FFFFFF",
  wildSand: "#F5F5F5"
}

const grays = {
  black: "#000000",
  gray: "#7F7F7F",
  iron: "#DBDEE1",
  mineShaft: "#333333",
  nobel: "#B4B1B2",
  osloGray: "#969B9F",
  thunder: "#2E292E",
  tundora: "#4C4145"
}

const colors = {
  ...blues,
  ...gauge,
  ...grays,
  ...reds,
  ...whites
}

export default colors
