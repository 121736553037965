import gql from "graphql-tag"
import homeVideoReview from "fragments/homeVideoReview"

const GET_HOME_VIDEO_REVIEW = gql`
  ${homeVideoReview}

  query getHomeVideoReview($id: ID!) {
    getHomeVideoReview(id: $id) {
      ...HomeVideoReview
    }
  }
`

export default GET_HOME_VIDEO_REVIEW
