import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function */
import { Row, Wrapper } from "./styled";
import AllAroundGather from "components/Reviews/Actions/AllAroundGather";
import Gather from "components/Reviews/Actions/Gather";
import List from "components/Reviews/Actions/List";
import OnlyNew from "components/Reviews/Actions/OnlyNew";
import Reviews from "components/Reviews";
import { ReviewsControlCheckboxes } from "@bluframe/mightychroma-mightytool-ui";
import config from "config/site";
const EightLeggedFreak = ({ reviewsControlCheckboxesProps }) => (_jsxs(Wrapper, { children: [_jsx(ReviewsControlCheckboxes, { ...reviewsControlCheckboxesProps }), config.rows.map((row) => (_jsx(Row, { children: row.reviews.map((review) => (_jsx(Reviews, { actions: _jsxs(_Fragment, { children: [_jsx(Gather, { serviceName: review.serviceName }), _jsx(List, { serviceName: review.serviceName }), _jsx(OnlyNew, { serviceName: review.serviceName }), _jsx(AllAroundGather, { serviceName: review.serviceName })] }), name: review.name, serviceName: review.serviceName }, review.serviceName))) }, row.id)))] }));
export default EightLeggedFreak;
