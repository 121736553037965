import Route from "./Route";
import isTokenExpired from "./isTokenExpired";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
import useUser from "hooks/useUser";
const usePrepareComponent = ({ component, exact, isAdminOnly, isLoggedInOnly, path }) => {
    const history = useHistory();
    const { isAdmin, onClearToken, onGetToken } = useUser();
    const token = onGetToken();
    if (isLoggedInOnly && (!token || isTokenExpired(token))) {
        onClearToken();
        return history.push("/login");
    }
    if (isAdminOnly && !isAdmin) {
        return history.push("/");
    }
    return { component, exact, path };
};
// @ts-ignore
export default prepareComponent(usePrepareComponent)(Route);
