import GATHER from "mutations/gather";
import Gather from "./Gather";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useMutation } from "@apollo/client";
import useReviews from "hooks/useReviews";
const handleGather = (gather) => () => {
    gather();
};
const usePrepareComponent = ({ serviceName }) => {
    const reviewsContext = useReviews();
    const { state } = reviewsContext;
    const [gather, { loading }] = useMutation(GATHER, {
        variables: {
            eightLeggedFreakGatherInput: {
                isAllAroundGather: state[serviceName].isAllAroundGather,
                isBluRay: true,
                isUHD: state.isUHD,
                serviceName
            }
        }
    });
    return {
        isLoading: loading,
        onGather: handleGather(gather)
    };
};
export default prepareComponent(usePrepareComponent)(Gather);
