/* eslint-disable max-lines-per-function, max-statements */
import { useCallback, useEffect } from "react";
import useCreateReviews from "hooks/useCreateReviews";
import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
const useAddEditHomeVideoReview = () => {
    const { resetImages } = usePreviews();
    const { clearAll, setSelectedIsReviewCreated, state: { homeVideoReview } } = useReviews();
    const { avForumsData, avNirvanaData, avsForumData, bluRayAuthorityData, bluRayData, digitalBitsData, doBluData, hidefDigestData, homeTheaterForumData, whySoBluData } = useCreateReviews();
    useEffect(() => {
        if (avForumsData?.createReview && !homeVideoReview.avForumsId) {
            const { createReview: { id, serviceName } } = avForumsData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [avForumsData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (avNirvanaData?.createReview && !homeVideoReview.avNirvanaId) {
            const { createReview: { id, serviceName } } = avNirvanaData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [avNirvanaData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (avsForumData?.createReview && !homeVideoReview.avsForumId) {
            const { createReview: { id, serviceName } } = avsForumData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [avsForumData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (bluRayData?.createReview && !homeVideoReview.bluRayId) {
            const { createReview: { id, serviceName } } = bluRayData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [bluRayData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (bluRayAuthorityData?.createReview &&
            !homeVideoReview.bluRayAuthorityId) {
            const { createReview: { id, serviceName } } = bluRayAuthorityData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [bluRayAuthorityData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (digitalBitsData?.createReview && !homeVideoReview.digitalBitsId) {
            const { createReview: { id, serviceName } } = digitalBitsData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [digitalBitsData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (doBluData?.createReview && !homeVideoReview.doBluId) {
            const { createReview: { id, serviceName } } = doBluData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [doBluData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (hidefDigestData?.createReview && !homeVideoReview.hidefDigestId) {
            const { createReview: { id, serviceName } } = hidefDigestData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [hidefDigestData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (homeTheaterForumData?.createReview &&
            !homeVideoReview.homeTheaterForumId) {
            const { createReview: { id, serviceName } } = homeTheaterForumData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [homeTheaterForumData, homeVideoReview, setSelectedIsReviewCreated]);
    useEffect(() => {
        if (whySoBluData?.createReview && !homeVideoReview.whySoBluId) {
            const { createReview: { id, serviceName } } = whySoBluData;
            setSelectedIsReviewCreated(id, serviceName);
        }
    }, [whySoBluData, homeVideoReview, setSelectedIsReviewCreated]);
    const onClearAll = useCallback(() => {
        // Reset all images
        resetImages();
        // Clear all reviews
        clearAll();
    }, [clearAll, resetImages]);
    return {
        onClearAll
    };
};
export default useAddEditHomeVideoReview;
