import DateComponent from "./Date"

export interface Props {
  disabled?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void
  label: string
  value?: Date | string | null
}

export interface ComponentProps extends Props {}

export default DateComponent
