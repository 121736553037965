import category from "fragments/category"
import gql from "graphql-tag"

const GET_CATEGORY = gql`
  ${category}

  query category($id: ID!, $updatedAt: String) {
    category(id: $id, updatedAt: $updatedAt) {
      ...Category
    }
  }
`
export default GET_CATEGORY
