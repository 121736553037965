export const handleAdd = ({ name, setFocusedData, setFocusedService, setPreview }, createReview) => () => {
    setFocusedData("createReview");
    setFocusedService(name);
    setPreview({});
    createReview();
};
export const handlePreview = ({ name, setFocusedData, setFocusedService, setPreview }, getPreview) => () => {
    setFocusedData("preview");
    setFocusedService(name);
    setPreview({});
    getPreview();
};
export const handleUpdateFromUrl = ({ name, setFocusedData, setFocusedService, setPreview }, updatePreviewFromUrl, url) => () => {
    setFocusedData("updateFromUrl");
    setFocusedService(name);
    setPreview({ message: `Updating Critics Review from URL: ${url}...` });
    updatePreviewFromUrl();
};
export const handleGetAll = ({ getReviews, name, setFocusedData, setFocusedService, setPreview }) => () => {
    setFocusedData("reviews");
    setFocusedService(name);
    setPreview({ message: `Loading all ${name}...` });
    getReviews();
};
