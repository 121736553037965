import { gql } from "graphql-tag"

const scoreAndQuote = gql`
  type ScoreAndQuote {
    quote: String
    score: Float
    section: String
  }

  input ScoreAndQuoteInput {
    quote: String!
    score: Float!
  }

  input ScoreAndQuoteInputOverrides {
    quote: String
    score: Float
    section: String
  }
`

export default scoreAndQuote
