/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, complexity, max-lines */

import {
  Buttons,
  DesriptionAndSection,
  Form,
  H1,
  H2,
  HeadingAndContent,
  Inputs,
  Main,
  NameAndSLug,
  Slug,
  Top
} from "./styled"
import {
  ConfirmModal,
  Content,
  InputText,
  Modal,
  TextArea
} from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  descriptionRef,
  isEditMode,
  isModalOpen,
  item,
  modalContent,
  nameRef,
  onModalClose,
  onModalDeleteConfirm,
  onModalSaveConfirm,
  onSubmit,
  orderRef,
  outlinedButtonLabel,
  outlinedButtonOnClick,
  sectionIdRef,
  slugRef
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      <NameAndSLug $isEditMode={isEditMode}>
        {isEditMode ? (
          <>
            <InputText
              defaultValue={item?.name}
              inputId="name"
              label="Name"
              name="name"
              ref={nameRef}
              required
            />
            <InputText
              defaultValue={item?.slug}
              inputId="slug"
              label="Slug"
              name="slug"
              ref={slugRef}
              required
            />
          </>
        ) : (
          <>
            <H1>{item?.name}</H1>
            <Slug>{item?.slug}</Slug>
          </>
        )}
      </NameAndSLug>
      <Buttons>
        <Button
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          label={actionButtonLabel}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
      </Buttons>
    </Top>
    <Main>
      {isEditMode ? (
        <Inputs>
          <InputText
            defaultValue={String(item?.order)}
            inputId="order"
            label="Order"
            name="order"
            ref={orderRef}
            required
            type="number"
          />
          <InputText
            defaultValue={item?.section.id}
            inputId="sectionId"
            label="Section Id"
            name="sectionId"
            ref={sectionIdRef}
            required
          />
          <TextArea
            defaultValue={item?.description}
            inputId="description"
            label="Description"
            name="description"
            ref={descriptionRef}
            required
          />
        </Inputs>
      ) : item?.description ? (
        <DesriptionAndSection>
          <HeadingAndContent>
            <H2>{"Description"}</H2>
            <Content content={item?.description} />
          </HeadingAndContent>
          <HeadingAndContent>
            <H2>{"Order"}</H2>
            <Content content={String(item?.order)} />
          </HeadingAndContent>
          <HeadingAndContent>
            <H2>{"Section"}</H2>
            <Content content={item?.section.name} />
          </HeadingAndContent>
        </DesriptionAndSection>
      ) : null}
    </Main>
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      {modalContent === "deleteConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to delete ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalDeleteConfirm}
        />
      ) : null}
      {modalContent === "saveConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to save${
            item?.name ? ` ${item.name}` : ""
          }?`}
          onClose={onModalClose}
          onConfirm={onModalSaveConfirm}
        />
      ) : null}
    </Modal>
  </Form>
)

export default Item
