/* eslint-disable no-ternary */

import {
  H1 as BluBlocksH1,
  H2 as BluBlocksH2,
  H3 as BluBlocksH3,
  SubtitleH2 as BluBlocksSubtitleH2,
  SubtitleH3 as BluBlocksSubtitleH3,
  Theme
} from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Form = styled.form`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 40px;
  width: 100%;
`

export const NameAndEmailPreferences = styled.div<{ $isEditMode: boolean }>`
  display: grid;
  row-gap: ${({ $isEditMode }) => ($isEditMode ? "20px" : "10px")};
`

export const PlaceholdersAndHeading = styled.div`
  display: grid;
`

export const HeadingAndButtons = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const Placeholders = styled.div`
  column-gap: 20px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;

  ${mediaQueries.tablet} {
    grid-template-columns: minmax(400px, 1fr) minmax(400px, 1fr);
  }
`

export const H1 = styled(BluBlocksH1)`
  font-size: 22px;
  margin: 0;
`

export const H2 = styled(BluBlocksH2)`
  font-size: 18px;
  margin: 0;
`

export const H3 = styled(BluBlocksH3)`
  font-size: 18px;
  margin: 0;
`

export const SubtitleH2 = styled(BluBlocksSubtitleH2)`
  font-size: 16px;
  margin: 0;
`

export const Buttons = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Main = styled.div`
  display: grid;
  row-gap: 20px;
`

export const Inputs = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto auto auto 1fr;
  justify-items: start;
  row-gap: 20px;
`

export const Preview = styled.div``

export const UsersCount = styled.div`
  display: grid;
  justify-items: end;
  justify-self: end;
  row-gap: 2px;
`

export const OptedOutUsersCount = styled(BluBlocksSubtitleH3)`
  font-size: 16px;
  margin: 0;
`

export const PublishedButton = styled(Button)`
  cursor: auto;
`
