import { Paragraph, Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  display: grid;
  padding: 10px;
  row-gap: 10px;

  & input {
    outline: none;
  }

  & textarea {
    height: 100px;
  }
`

export const OverridesLabel = styled(Paragraph)`
  font-weight: 700;
  margin: 0;
`
