import { gql } from "graphql-tag"

const image = gql`
  type ImageFormat {
    ext: String!
    hash: String!
    height: Int!
    mime: String!
    name: String!
    path: String
    size: Int!
    url: String!
    width: Int!
  }

  type ImageFormats {
    large: ImageFormat
    medium: ImageFormat
    small: ImageFormat
    thumbnail: ImageFormat
    xlarge: ImageFormat
    xsmall: ImageFormat
  }

  type Image {
    alternativeText: String
    caption: String
    ext: String!
    formats: ImageFormats
    hash: String!
    height: Int!
    id: ID!
    mime: String!
    name: String!
    provider: String!
    related: String
    size: Int!
    url: String!
    width: Int!
  }

  type HomeVideoImages {
    back: String
    front: String
    overview: String
    slip: String
    slipback: String
  }

  type CreateImagesGridResponse {
    imagesGridId: ID!
  }
`

export default image
