/* eslint-disable no-ternary */

import { Items, MoreLink, ParagraphWrapper, SpanWrapper } from "./styled"
import { ComponentProps } from "."
import Item from "./Item"

const ONE = 1

const Detail = ({
  isExpandable,
  isExpanded,
  isMarkdown,
  items,
  label,
  onToggleIsExpanded
}: ComponentProps) => {
  const Wrapper = isMarkdown ? SpanWrapper : ParagraphWrapper

  return (
    <Wrapper>
      <strong>
        {label}
        {isMarkdown ? null : ": "}
      </strong>
      <Items $isExpandable={isExpandable} $isExpanded={isExpanded}>
        {items.map((item: string, index: number) => (
          <Item
            isMarkdown={isMarkdown}
            isNotLast={index < items.length - ONE}
            item={item}
            key={item}
          />
        ))}
        {isExpandable ? (
          <MoreLink as="span" onClick={onToggleIsExpanded}>
            {"More"}
          </MoreLink>
        ) : null}
      </Items>
    </Wrapper>
  )
}

export default Detail
