import gql from "graphql-tag"
import section from "fragments/section"

const category = gql`
  ${section}

  fragment Category on Category {
    commentCount
    createdAt
    description
    id
    name
    order
    section {
      ...Section
    }
    slug
    topicCount
    updatedAt
  }
`

export default category
