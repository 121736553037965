import gql from "graphql-tag";
const UPDATE_EMAILS_FOOTERS = gql `
  mutation updateEmailsFooter(
    $id: ID!
    $emailsFooterUpdateInput: EmailsFooterUpdateInput!
  ) {
    updateEmailsFooter(
      id: $id
      emailsFooterUpdateInput: $emailsFooterUpdateInput
    ) {
      id
    }
  }
`;
export default UPDATE_EMAILS_FOOTERS;
