import Pagination from "./Pagination"
import identity from "ramda/src/identity"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  hasNextData?: boolean
  hasPrevData?: boolean
  link?: string
  next?: string | null
  prev?: string | null
}

export interface ComponentProps extends Props {
  link: string
}

const hasPagination = ({ hasNextData, hasPrevData, link }: Props): boolean =>
  Boolean((hasNextData || hasPrevData) && link)

// @ts-ignore
export default prepareComponent<Props, ComponentProps>(identity, {
  onlyRenderIf: hasPagination
})(Pagination)
