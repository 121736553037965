import styled from "styled-components";
export const Wrapper = styled.div `
  display: grid;
  row-gap: 20px;
`;
export const Buttons = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
export const InputTexts = styled.div `
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
`;
export const Checkboxes = styled.div `
  align-items: flex-start;
  display: grid;

  & > div {
    width: 100%;
  }
`;
export const Main = styled.div `
  display: grid;
`;
export const Bottom = styled.div `
  display: grid;
  justify-items: flex-start;
  row-gap: 20px;
`;
