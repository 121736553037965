/* eslint-disable no-ternary, max-lines-per-function */

import {
  Buttons,
  Form,
  H1,
  HtmlTextArea,
  Inputs,
  Main,
  Preview,
  Subject,
  SubjectAndPreview,
  SubtitleH1,
  Top
} from "./styled"
import { ConfirmModal, InputText, Modal } from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import { Template } from "@bluframe/mightychroma-mightytool-frontend-types"

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  errors,
  footerIdRef,
  headingIdRef,
  htmlRef,
  identifierRef,
  isEditMode,
  isModalOpen,
  isSaveLoading,
  isTemplate,
  item,
  nameRef,
  onModalClose,
  onModalConfirm,
  onPreviewClick,
  onSubmit,
  outlinedButtonLabel,
  outlinedButtonOnClick,
  placeholdersRef,
  preview,
  subjectPreview,
  subjectRef
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      {isEditMode ? (
        <div>
          <InputText
            defaultValue={item?.name}
            error={Boolean(errors?.name)}
            errorText={errors?.name}
            inputId="name"
            label="Name"
            name="name"
            ref={nameRef}
            required
          />
          {isTemplate ? (
            <>
              <InputText
                defaultValue={(item as Template)?.identifier}
                error={Boolean(errors?.identifier)}
                errorText={errors?.identifier}
                inputId="identifier"
                label="Identifier"
                name="identifier"
                ref={identifierRef}
                required
              />
              <InputText
                defaultValue={(item as Template)?.placeholders?.join(",")}
                error={Boolean(errors?.placeholders)}
                errorText={errors?.placeholders}
                infoText="Comma-separated list of placeholders"
                inputId="placeholders"
                label="Placeholders"
                name="placeholders"
                ref={placeholdersRef}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div>
          <H1>{item?.name}</H1>
          {isTemplate ? (
            <SubtitleH1>{(item as Template)?.identifier}</SubtitleH1>
          ) : null}
        </div>
      )}
      <Buttons>
        {isEditMode ? (
          <Button
            disabled={isEditMode ? isSaveLoading : undefined}
            label="Preview"
            onClick={onPreviewClick}
            outlined
            secondary
          />
        ) : null}
        <Button
          disabled={isEditMode ? isSaveLoading : undefined}
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          disabled={isEditMode ? isSaveLoading : undefined}
          label={actionButtonLabel}
          loading={isEditMode ? isSaveLoading : undefined}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
      </Buttons>
    </Top>
    <Main $isEditing={isEditMode}>
      {isEditMode ? (
        <Inputs>
          {isTemplate ? (
            <>
              <InputText
                defaultValue={(item as Template)?.subject}
                error={Boolean(errors?.subject)}
                errorText={errors?.subject}
                inputId="subject"
                label="Subject"
                name="subject"
                ref={subjectRef}
                required
              />
              <InputText
                defaultValue={(item as Template)?.heading?.id}
                error={Boolean(errors?.headingId)}
                errorText={errors?.headingId}
                inputId="headingId"
                label="Heading ID"
                name="headingId"
                ref={headingIdRef}
              />
            </>
          ) : null}
          <HtmlTextArea
            defaultValue={item?.html}
            error={Boolean(errors?.html)}
            errorText={errors?.html}
            inputId="html"
            label="HTML"
            name="html"
            ref={htmlRef}
            required
          />
          {isTemplate ? (
            <InputText
              defaultValue={(item as Template)?.footer?.id}
              error={Boolean(errors?.footerId)}
              errorText={errors?.footerId}
              inputId="footerId"
              label="Footer ID"
              name="footerId"
              ref={footerIdRef}
            />
          ) : null}
        </Inputs>
      ) : null}
      <SubjectAndPreview>
        <Subject>{subjectPreview}</Subject>
        <Preview
          dangerouslySetInnerHTML={{
            __html: preview ?? ""
          }}
        />
      </SubjectAndPreview>
    </Main>
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      <ConfirmModal
        message={`Are you sure you want to delete ${item?.name}?`}
        onClose={onModalClose}
        onConfirm={onModalConfirm}
      />
    </Modal>
  </Form>
)

export default Item
