/* eslint-disable max-lines-per-function, max-statements */
import { useEffect, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import CREATE_PREVIEW from "mutations/critics/createPreview";
import EIGHT_LEGGED_FREAK_REVIEW_UPDATE from "mutations/eightLeggedFreakReviewUpdate";
import GET_OMDB_ID from "queries/getOmdbId";
import Reviews from "./Reviews";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const ZERO = 0;
const THREE = 3;
const onSelect = (
// eslint-disable-next-line no-unused-vars
getOmdbId, hasSetOmdbId, serviceName, 
// eslint-disable-next-line no-unused-vars
setSelectedId
// eslint-disable-next-line max-params
) => (ev) => {
    setSelectedId(ev.currentTarget.value, serviceName);
    if (hasSetOmdbId) {
        getOmdbId({
            variables: {
                bluRayId: ev.currentTarget.value
            }
        });
    }
};
const getDisplay = (serviceState) => {
    if (serviceState.isOnlyNew) {
        return serviceState.display.filter(({ isNew }) => isNew);
    }
    if (serviceState.isOnlyPresent) {
        return serviceState.display.filter(({ isNew }) => !isNew);
    }
    return serviceState.display;
};
const usePrepareComponent = ({ actions, name, serviceName, setOMDbId }) => {
    const { addImage } = usePreviews();
    const { setIsToggleFlaggedLoading, setSelectedId, setSelectedIsPreviewLoading, setSelectedIsPreviewValid, state } = useReviews();
    const { isAdmin } = useUser();
    const serviceState = useMemo(() => state[serviceName], [state, serviceName]);
    const [createPreview, { data: createPreviewData, loading: createPreviewLoading }] = useMutation(CREATE_PREVIEW, {
        onCompleted: (data) => {
            if (data.createPreview.images?.front) {
                // Add image to image selector
                addImage({
                    serviceName,
                    url: data.createPreview.images.front
                });
            }
        }
    });
    const [getOmdbId] = useLazyQuery(GET_OMDB_ID, {
        onCompleted: (omdbIdData) => {
            if (setOMDbId && omdbIdData?.omdbId) {
                setOMDbId(omdbIdData.omdbId);
            }
        }
    });
    const { refetch } = useEightLeggedFreakList(serviceName);
    const [eightLeggedFreakReviewUpdate, { loading: flagLoading }] = useMutation(EIGHT_LEGGED_FREAK_REVIEW_UPDATE);
    useEffect(() => {
        setIsToggleFlaggedLoading(flagLoading);
        // It just goes into infinite loop if we include
        // setIsToggleFlaggedLoading in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagLoading]);
    useEffect(() => {
        if (serviceState.selected.url) {
            createPreview({
                variables: {
                    previewInput: {
                        isUHD: state.isUHD,
                        overrides: serviceState.selected.overrides,
                        serviceName,
                        url: serviceState.selected.url
                    }
                }
            });
        }
    }, [
        createPreview,
        serviceName,
        serviceState.selected.overrides,
        serviceState.selected.url,
        state.isUHD
    ]);
    useEffect(() => {
        if (createPreviewData) {
            setSelectedIsPreviewValid(createPreviewData.createPreview.isValid, serviceName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPreviewData]);
    useEffect(() => {
        setSelectedIsPreviewLoading(createPreviewLoading, serviceName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPreviewLoading]);
    const display = useMemo(() => getDisplay(serviceState), [serviceState]);
    const filteredDisplay = useMemo(() => {
        if (serviceState.isShowFlagged) {
            return display;
        }
        return display.filter(({ isFlagged }) => !isFlagged);
    }, [display, serviceState.isShowFlagged]);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subheader = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return `Total New: ${filteredDisplay.length}`;
        }
        if (serviceState.isOnlyPresent) {
            return `Total Present: ${filteredDisplay.length}`;
        }
        return `Total Available: ${filteredDisplay.length}`;
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const title = useMemo(() => `${name} Reviews`);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const avatar = useMemo(() => name.substr(ZERO, THREE));
    const onToggleFlagged = (id, isFlagged) => () => {
        eightLeggedFreakReviewUpdate({
            onCompleted: () => {
                refetch({
                    eightLeggedFreakListInput: {
                        isUHD: state.isUHD,
                        serviceName
                    }
                });
            },
            variables: {
                review: {
                    id,
                    isFlagged: !isFlagged
                },
                serviceName
            }
        });
    };
    return {
        actions,
        avatar,
        display: filteredDisplay,
        flagLoading,
        isShowFlagButton: isAdmin,
        onSelect: onSelect(getOmdbId, Boolean(setOMDbId), serviceName, setSelectedId),
        onToggleFlagged,
        selected: serviceState.selected.id,
        serviceName,
        subheader,
        title
    };
};
export default prepareComponent(usePrepareComponent)(Reviews);
