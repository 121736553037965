/* eslint-disable max-statements */

import Arrow from "./Arrow"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  cursor?: string | null
  direction: "next" | "prev"
  hasData?: boolean
  link: string
}

export interface ComponentProps extends Omit<Props, "link"> {
  ariaLabel: "Next" | "Previous"
  iconName: "IoChevronBack" | "IoChevronForward"
  to: string
}

export const hasLink = ({ hasData }: Props): boolean => Boolean(hasData)

export const usePrepareComponent = ({
  cursor,
  link,
  ...props
}: Props): ComponentProps => {
  let ariaLabel: ComponentProps["ariaLabel"] = "Next"
  let iconName: ComponentProps["iconName"] = "IoChevronForward"

  if (props.direction === "prev") {
    ariaLabel = "Previous"
    iconName = "IoChevronBack"
  }

  if (!cursor) {
    return {
      ...props,
      ariaLabel,
      cursor,
      iconName,
      to: link
    }
  }

  return {
    ...props,
    ariaLabel,
    cursor,
    iconName,
    to: `${link}?startAt=${cursor}`
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent, {
  onlyRenderIf: hasLink
})(Arrow)
