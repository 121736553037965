import { gql } from "graphql-tag"

const dashboard = gql`
  type DashboardCountUser {
    homeVideoReviews: HomeVideoReviewsCount!
  }

  input DateRange {
    endDate: String!
    startDate: String!
  }

  input GetDashboardCountAdminInput {
    newUsersByDateRange: DateRange!
  }

  type DashboardCountAdmin {
    homeVideoReviews: HomeVideoReviewsCount!
    newUsersByDateRange: [NewUsersByDateRange]!
    users: UsersCount!
  }
`

export default dashboard
