/* eslint-disable no-ternary */

import {
  H1 as BluBlocksH1,
  H2 as BluBlocksH2,
  Paragraph as BluBlocksParagraph,
  Theme
} from "@bluframe/blublocks"
import styled from "styled-components"

export const Form = styled.form`
  align-items: start;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 40px;
  width: 100%;
`

export const Top = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const NameAndSLug = styled.div<{ $isEditMode: boolean }>`
  display: grid;
  row-gap: ${({ $isEditMode }) => ($isEditMode ? "20px" : "10px")};
`

export const H1 = styled(BluBlocksH1)`
  font-size: 22px;
  margin: 0;
`

export const H2 = styled(BluBlocksH2)`
  font-size: 20px;
  margin: 0;
`

export const Slug = styled(BluBlocksParagraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
`

export const Buttons = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Main = styled.div`
  display: grid;
  row-gap: 20px;
`

export const Inputs = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  justify-items: start;
  row-gap: 20px;
`

export const DesriptionAndSection = styled.div`
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: start;
  row-gap: 20px;
`

export const HeadingAndContent = styled.div`
  display: grid;
  row-gap: 10px;
`
