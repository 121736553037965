import { InputTextProps as BluBlocksInputTextProps } from "@bluframe/blublocks"
import EditableText from "./EditableText"

export interface Props extends Omit<BluBlocksInputTextProps, "value"> {
  isAlwaysShowInfo?: boolean
  isEditable?: boolean
  isEditing: boolean
}

export interface ComponentProps extends Props {}

export default EditableText
