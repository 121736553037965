/* eslint-disable no-ternary */

import { ComponentProps } from "."
import { Image } from "./styled"

const ImageComponent = ({
  alternativeText,
  formats,
  height,
  isContain,
  isFill,
  sizes,
  url,
  width
}: ComponentProps) => (
  <Image
    alt={alternativeText}
    height={height}
    sizes={sizes}
    src={url}
    style={isFill ? { objectFit: isContain ? "contain" : "cover" } : undefined}
    width={width}
  />
)

export default ImageComponent
