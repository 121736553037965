import Autosuggest from "components/Autosuggest"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import { Wrapper } from "./styled"

const EditActions = ({
  homeVideoReviewTitles,
  isUpdateHomeVideoReviewEnabled,
  label,
  loading,
  onClearAll,
  onGetSuggestions,
  onSelectHomeVideoReviewId,
  onUpdateHomeVideoReview,
  selectedHomeVideoReviewId
}: ComponentProps) => (
  <Wrapper>
    <Autosuggest
      disabled={loading}
      label="Select a Home Video Review"
      onGetSuggestions={onGetSuggestions}
      onSuggestionClick={onSelectHomeVideoReviewId}
      suggestions={homeVideoReviewTitles}
      value={selectedHomeVideoReviewId}
    />

    <Button
      bold
      disabled={!isUpdateHomeVideoReviewEnabled || loading}
      label={label}
      loading={loading}
      onClick={onUpdateHomeVideoReview}
    />
    <Button
      bold
      disabled={loading}
      label="Clear All"
      onClick={onClearAll}
      secondary
    />
  </Wrapper>
)

export default EditActions
