import gql from "graphql-tag";
const EIGHT_LEGGED_FREAK_REVIEW_UPDATE = gql `
  mutation eightLeggedFreakReviewUpdate(
    $review: EightLeggedFreakReviewInput!
    $serviceName: String!
  ) {
    eightLeggedFreakReviewUpdate(review: $review, serviceName: $serviceName) {
      id
    }
  }
`;
export default EIGHT_LEGGED_FREAK_REVIEW_UPDATE;
