import { H4 as BluBlocksH4, Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const H4 = styled(BluBlocksH4)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-weight: 500;
  line-height: 1.1;
  font-size: 20px;
  margin-top: 0;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
`

export default H4
