import authenticate from "./authenticate"
import author from "./author"
import blog from "./blog"
import contest from "./contest"
import dashboard from "./dashboard"
import eightLeggedFreak from "./eightLeggedFreak"
import emailPreferences from "./emailPreferences"
import emails from "./emails"
import forum from "./forum"
import homeVideoReview from "./homeVideoReview"
import homeVideoReviewScore from "./homeVideoReviewScore"
import homeVideoReviewSummary from "./homeVideoReviewSummary"
import image from "./image"
import mas from "./mas"
import mutation from "./mutation"
import newsletter from "./newsletter"
import preview from "./preview"
import query from "./query"
import review from "./review"
import scoreAndQuote from "./scoreAndQuote"
import site from "./site"
import tag from "./tag"

export default [
  authenticate,
  author,
  blog,
  contest,
  dashboard,
  eightLeggedFreak,
  emailPreferences,
  emails,
  forum,
  homeVideoReview,
  homeVideoReviewScore,
  homeVideoReviewSummary,
  image,
  mas,
  mutation,
  newsletter,
  preview,
  query,
  review,
  scoreAndQuote,
  site,
  tag
]
