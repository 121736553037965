import GET_DRAFTS from "./getDrafts"
import GET_DRAFTS_WAVE from "./getDraftsWave"
import GET_HOME_VIDEO_REVIEW from "./getHomeVideoReview"
import GET_HOME_VIDEO_REVIEWS from "./getHomeVideoReviews"
import SEARCH_HOME_VIDEO_REVIEWS from "./searchHomeVideoReviews"

export default {
  GET_DRAFTS,
  GET_DRAFTS_WAVE,
  GET_HOME_VIDEO_REVIEW,
  GET_HOME_VIDEO_REVIEWS,
  SEARCH_HOME_VIDEO_REVIEWS
}
