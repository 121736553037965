import { gql } from "graphql-tag"

const query = gql`
  type Query {
    getDashboardCountUser: DashboardCountUser
    getDashboardCountAdmin(
      getDashboardCountAdminInput: GetDashboardCountAdminInput!
    ): DashboardCountAdmin
  }
`

export default query
