/* eslint-disable max-lines-per-function */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMutation, useQuery } from "@apollo/client";
import Drafts from "./Drafts";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useLocation } from "react-router-dom";
const { homeVideoReviews: { PUBLISH_DRAFT_HOME_VIDEO_REVIEW } } = mutations;
const { homeVideoReviews: { GET_DRAFTS_WAVE } } = queries;
const SERVICE_NAMES = [
    "avForums",
    "avNirvana",
    "avsForum",
    "bluRay",
    "bluRayAuthority",
    "digitalBits",
    "doBlu",
    "hidefDigest",
    "homeTheaterForum",
    "whySoBlu"
];
const usePrepareComponent = () => {
    const { search } = useLocation();
    const startAt = new URLSearchParams(search).get("startAt");
    const { data, loading } = useQuery(GET_DRAFTS_WAVE, {
        variables: {
            cursor: startAt ?? undefined
        }
    });
    const [publishDrafts, { loading: draftsLoading }] = useMutation(PUBLISH_DRAFT_HOME_VIDEO_REVIEW, {
        refetchQueries: [GET_DRAFTS_WAVE]
    });
    const onPublish = (id) => {
        publishDrafts({
            variables: {
                ids: [id]
            }
        });
    };
    const getPreviewsFromDraft = (draft) => {
        const previews = [];
        SERVICE_NAMES.forEach((serviceName) => {
            const review = draft[serviceName];
            if (review) {
                previews.push({
                    releaseDate: review.releaseDate ?? undefined,
                    serviceName,
                    url: review.url ?? ""
                });
            }
        });
        return previews;
    };
    const drafts = data?.draftsWave.homeVideoReviews
        .filter((draft) => Boolean(draft.name ?? draft.mas?.title))
        .map((draft) => ({
        id: draft.id,
        isChecked: false,
        isLoading: draftsLoading,
        // eslint-disable-next-line no-empty-function
        onChange: () => { },
        onPublish: () => onPublish(draft.id),
        previews: getPreviewsFromDraft(draft),
        // Typescript needs the `as string` here to satisfy the type checker
        // we filter out drafts without a name or title above
        title: (draft.name ?? draft.mas?.title)
    }));
    return {
        drafts,
        loading,
        pagination: {
            hasNextData: data?.draftsWave.hasNextData,
            hasPrevData: data?.draftsWave.hasPrevData,
            link: "/drafts",
            next: data?.draftsWave.cursor,
            prev: data?.draftsWave.prevCursor
        }
    };
};
export default prepareComponent(usePrepareComponent)(Drafts);
