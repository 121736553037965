import Header from "./Header"

export interface Props {
  avatar: string
  subheader: string
  title: string
}

export interface ComponentProps extends Props {}

export default Header
