import { gql } from "graphql-tag"

const contest = gql`
  input ContestAddInput {
    conclusion: String
    countryIds: [String]
    endDate: String
    homeVideoReviewId: String
    imageId: Int
    intro: String
    isActive: Boolean
    isEnded: Boolean
    isPublished: Boolean
    rules: [String]
    slug: String!
    startDate: String
    subtitle: String
    title: String!
  }

  input ContestUpdateInput {
    conclusion: String
    countryIds: [String]
    endDate: String
    homeVideoReviewId: String
    imageId: Int
    intro: String
    isActive: Boolean
    isEnded: Boolean
    isPublished: Boolean
    rules: [String]
    slug: String
    startDate: String
    subtitle: String
    title: String
  }

  type ContestAddResponse {
    id: ID!
  }

  type ContestUpdateResponse {
    id: ID!
  }

  type ContestDeleteResponse {
    status: String!
  }

  type Contest {
    conclusion: String
    countries: [Country]
    countryIds: [String]
    createdAt: String!
    endDate: String
    enrollCount: Int
    homeVideoReviewId: String
    id: ID!
    image: Image
    imageId: Int
    intro: String
    isActive: Boolean!
    isEnded: Boolean!
    isPublished: Boolean!
    rules: [String]
    slug: String!
    startDate: String
    subtitle: String
    title: String!
    updatedAt: String!
  }

  type Country {
    id: ID!
    isoCode: String!
    name: String!
    shortName: String!
  }
`

export default contest
