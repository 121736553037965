import styled from "styled-components";
export const Wrapper = styled.div `
  align-items: flex-start;
  display: grid;
  row-gap: 32px;
`;
export const Row = styled.div `
  column-gap: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
`;
