/* eslint-disable no-ternary, max-lines-per-function */

import Button from "components/buttons/Button"
import { ComponentProps } from "."
import ImageSelector from "components/HomeVideoReviews/ImageSelector"
import Text from "components/inputs/Text"
import { Wrapper } from "./styled"

const AddReviewActions = ({
  OMDbId,
  imageSelector,
  isAddHomeVideoReviewEnabled,
  isAdmin,
  isOmdbIdValid,
  label,
  loading,
  name,
  onAddHomeVideoReview,
  onClearAll,
  onNameChange,
  onOmdbIdChange
}: ComponentProps) => (
  <Wrapper>
    <Text
      disabled={loading}
      hasValid
      id="OMDbId"
      isValid={isOmdbIdValid}
      label="OMDbId"
      name="OMDbId"
      onChange={onOmdbIdChange}
      text={OMDbId}
    />
    <Button
      bold
      disabled={!isAddHomeVideoReviewEnabled || loading}
      label={label}
      loading={loading}
      onClick={onAddHomeVideoReview}
    />
    <Button
      bold
      disabled={loading}
      label="Clear All"
      onClick={onClearAll}
      secondary
    />
    {isAdmin ? <ImageSelector {...imageSelector} /> : null}
    {isAdmin ? (
      <Text
        disabled={loading}
        id="name"
        label="Name"
        name="name"
        onChange={onNameChange}
        text={name}
      />
    ) : null}
  </Wrapper>
)

export default AddReviewActions
