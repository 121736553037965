import Detail from "./Detail"
import { memo } from "react"
import pick from "ramda/src/pick"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useToggle from "@bluframe/grapple/useToggle"

interface Props {
  isExpandable?: boolean
  isMarkdown?: boolean
  label: string
  text: string
}

export interface ComponentProps extends Omit<Props, "text"> {
  isExpanded: boolean
  items: string[]
  onToggleIsExpanded: () => void
}

const useComponentProps = (props: Props): ComponentProps => {
  const [isExpanded, toggleExpanded] = useToggle()

  const onToggleIsExpanded = (): void => {
    toggleExpanded()
  }

  return {
    ...pick(["isExpandable", "isMarkdown", "label"])(props),
    isExpanded,
    // eslint-disable-next-line no-ternary
    items: props.isMarkdown ? [props.text] : props.text.split(", "),
    onToggleIsExpanded
  }
}

export default prepareComponent<Props, ComponentProps>(useComponentProps)(
  memo(Detail)
)
