import gql from "graphql-tag";
const GET_EMAILS_FOOTERS = gql `
  query getEmailsFooters {
    getEmailsFooters {
      html
      id
      name
    }
  }
`;
export default GET_EMAILS_FOOTERS;
