import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  justify-items: center;
`

export const Logo = styled.img`
  width: 80px;
`
