import { gql } from "graphql-tag"

const preview = gql`
  input PreviewInput {
    isUHD: Boolean!
    overrides: ReviewOverridesInput
    serviceName: String!
    url: String!
  }

  type Preview {
    audio: ScoreAndQuote
    author: Author
    editions: [String]
    extra: ScoreAndQuote
    errorFields: [String]
    images: HomeVideoImages
    isValid: Boolean!
    movie: ScoreAndQuote
    releaseDate: String
    tags: [String]
    total: ScoreAndQuote
    url: String!
    video: ScoreAndQuote
  }
`

export default preview
