/* eslint-disable no-ternary */

import { Paragraph, Theme } from "@bluframe/blublocks"
import styled from "styled-components"

const MAX_EXPENDABLE_LINES = 4

export const ParagraphWrapper = styled(Paragraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`

export const SpanWrapper = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`

export const Items = styled.span<{
  $isExpandable?: boolean
  $isExpanded: boolean
}>`
  & > span {
    ${({ $isExpandable, $isExpanded }) =>
      $isExpandable && !$isExpanded
        ? `
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${MAX_EXPENDABLE_LINES};
  display: -webkit-box;
`
        : `
  display: grid;
`}
  }
`

export const MoreLink = styled.a`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  cursor: pointer;
  font-weight: 700;

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  }
`
