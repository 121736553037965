import OverridesScoreQuoteAndSection from "./OverridesScoreQuoteAndSection"
import { type RefObject } from "react"

export interface Props {
  inputId: string
  inputRefs: {
    quote: RefObject<HTMLTextAreaElement>
    score: RefObject<HTMLInputElement>
    section: RefObject<HTMLTextAreaElement>
  }
  label: string
  name: string
  quote?: string | null
  score?: number | null
  section?: string | null
}

export interface ComponentProps extends Props {}

export default OverridesScoreQuoteAndSection
