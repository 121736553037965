import { gql } from "graphql-tag"

const review = gql`
  input CreateReviewInput {
    isOverridesOnly: Boolean
    isUHD: Boolean!
    overrides: ReviewOverridesInput
    serviceName: String!
    url: String!
  }

  type Review {
    audio: ScoreAndQuote
    author: Author
    editions: [String]
    extra: ScoreAndQuote
    id: ID!
    images: HomeVideoImages
    isFlagged: Boolean!
    movie: ScoreAndQuote
    releaseDate: String
    total: ScoreAndQuote
    url: String!
    video: ScoreAndQuote
  }

  input ReviewOverridesInput {
    audio: ScoreAndQuoteInputOverrides
    extra: ScoreAndQuoteInputOverrides
    movie: ScoreAndQuoteInputOverrides
    releaseDate: String
    total: ScoreAndQuoteInputOverrides
    video: ScoreAndQuoteInputOverrides
  }

  input ReviewInput {
    audio: ScoreAndQuoteInput
    author: AuthorInput
    extra: ScoreAndQuoteInput
    id: ID!
    isFlagged: Boolean
    movie: ScoreAndQuoteInput
    overrides: ReviewOverridesInput
    releaseDate: String
    total: ScoreAndQuoteInput
    url: String
    video: ScoreAndQuoteInput
  }

  type ReviewResponse {
    id: ID!
    serviceName: String!
  }
`

export default review
