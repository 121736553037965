import { CardProps } from "@bluframe/blublocks"
import Section from "./Section"

export interface Props {
  cards: CardProps[]
  heading: string
  id: string
  span?: number
}

export interface ComponentProps extends Props {
  // ComponentProps
}

export default Section
