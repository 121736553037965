import gql from "graphql-tag"

const ADD_HOME_VIDEO_REVIEW = gql`
  mutation addHomeVideoReview($homeVideoReview: HomeVideoReviewAddInput!) {
    addHomeVideoReview(homeVideoReview: $homeVideoReview) {
      status
    }
  }
`
export default ADD_HOME_VIDEO_REVIEW
