/* eslint-disable no-ternary, max-lines-per-function */

import { Props as ItemProps } from "./Item"

interface Item extends Omit<ItemProps, "isAdmin" | "isExpanded"> {
  isAdmin?: boolean
}

const items: Item[] = [
  {
    iconName: "IoHomeOutline",
    label: "Home",
    link: "/"
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegFileLines",
    label: "Home Video Reviews",
    link: "/home-video-reviews",
    subItems: [
      { label: "Add", link: "/home-video-reviews/add" },
      { isAdmin: true, label: "Edit", link: "/home-video-reviews/edit" },
      {
        isAdmin: true,
        label: "Single Add",
        link: "/home-video-reviews/single-add"
      }
    ]
  },
  {
    iconName: "IoBookmarkOutline",
    isAdmin: true,
    label: "Drafts",
    link: "/drafts"
  },
  {
    iconName: "IoMailOutline",
    isAdmin: true,
    label: "E-Mails",
    link: "/emails",
    subItems: [
      { label: "Headings", link: "/emails/headings" },
      { label: "Footers", link: "/emails/footers" },
      { label: "Templates", link: "/emails/templates" }
    ]
  },
  {
    iconName: "IoTrophyOutline",
    isAdmin: true,
    label: "Contests",
    link: "/contests"
  },
  {
    iconName: "IoChatbubbleEllipsesOutline",
    isAdmin: true,
    label: "Forum",
    link: "/forum",
    subItems: [
      { label: "Categories", link: "/forum/categories" },
      { label: "Sections", link: "/forum/sections" }
    ]
  },
  {
    iconName: "IoNewspaperOutline",
    isAdmin: true,
    label: "Newsletters",
    link: "/newsletters"
  },
  {
    iconName: "IoMapOutline",
    isAdmin: true,
    label: "Eight Legged Freak",
    link: "/eightleggedfreak"
  },
  {
    iconName: "IoNewspaperOutline",
    isAdmin: true,
    label: "Blog",
    link: "/blog",
    subItems: [{ label: "Roundup", link: "/blog/roundup" }]
  },
  {
    iconName: "IoImageOutline",
    isAdmin: true,
    label: "Images Grid",
    link: "/images-grid"
  },
  {
    iconName: "IoFilmOutline",
    isAdmin: true,
    label: "Movies and Shows",
    link: "/moviesandshows",
    subItems: [
      { label: "Movies", link: "/moviesandshows/movies/add" },
      { label: "TV Shows", link: "/moviesandshows/shows" }
    ]
  }
]

export default items
