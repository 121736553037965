/* eslint-disable max-lines-per-function */

import "c3/c3.css"
import * as d3 from "d3"
import { RefObject, useEffect, useRef } from "react"
import Chart from "./Chart"
import c3 from "c3"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import theme from "styles/theme"

export interface Props {
  ariaLabel?: string
  data: { date: string; newUsers: number }[]
}

export interface ComponentProps extends Omit<Props, "data"> {
  chartRef: RefObject<HTMLDivElement>
}

export const usePrepareComponent = ({
  data,
  ...props
}: Props): ComponentProps => {
  // Ref to attach the chart
  const chartRef = useRef(null)

  useEffect(() => {
    // Generate the chart
    const chart = c3.generate({
      axis: {
        // eslint-disable-next-line id-length
        x: {
          tick: {
            format(date) {
              return d3.timeFormat("%e %b")(date as Date)
            }
          },
          type: "timeseries"
        },
        // eslint-disable-next-line id-length
        y: {
          show: false
        }
      },
      bindto: chartRef.current,
      data: {
        colors: { newUsers: theme.palette.primary.main },
        json: data,
        keys: {
          value: ["newUsers"],
          // eslint-disable-next-line id-length
          x: "date"
        },
        names: {
          newUsers: "New Users"
        },
        type: "bar"
      },
      size: {
        height: 200,
        width: 440
      },
      tooltip: {
        format: {
          value: (value) => value as string
        }
      }
    })

    // Cleanup on component unmount
    return () => {
      chart.destroy()
    }
  }, [data])

  return {
    ...props,
    chartRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Chart
)
