import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-ternary, max-lines-per-function */
import { Chart, DashboardBigNumber } from "@bluframe/mightychroma-mightytool-ui";
import useUser from "hooks/useUser";
export const useSections = ({ dashboardCountAdmin, dashboardCountUser }) => {
    const { isAdmin } = useUser();
    const dashboardCountData = isAdmin ? dashboardCountAdmin : dashboardCountUser;
    const { homeVideoReviews } = dashboardCountData ?? {};
    const homeVideReviewsSection = {
        cards: [
            {
                children: _jsx(DashboardBigNumber, { number: homeVideoReviews?.fourK }),
                heading: "4K",
                id: "4k"
            },
            {
                children: _jsx(DashboardBigNumber, { number: homeVideoReviews?.bluRay }),
                heading: "Blu-ray",
                id: "blu-ray"
            },
            {
                children: _jsx(DashboardBigNumber, { number: homeVideoReviews?.drafts }),
                heading: "Drafts",
                id: "drafts"
            },
            {
                children: _jsx(DashboardBigNumber, { number: homeVideoReviews?.total }),
                heading: "Total",
                id: "total"
            }
        ],
        heading: "Home Video Reviews",
        id: "home-video-reviews"
    };
    const sections = [homeVideReviewsSection];
    if (isAdmin) {
        const { newUsersByDateRange, users } = dashboardCountAdmin ?? {};
        sections.push(...[
            {
                cards: [
                    {
                        children: _jsx(DashboardBigNumber, { number: users?.active }),
                        heading: "Active",
                        id: "active"
                    },
                    {
                        children: _jsx(DashboardBigNumber, { number: users?.pending }),
                        heading: "Pending",
                        id: "pending"
                    },
                    {
                        children: _jsx(DashboardBigNumber, { number: users?.total }),
                        heading: "Total",
                        id: "total"
                    }
                ],
                heading: "Users",
                id: "users"
            },
            {
                cards: [
                    {
                        children: _jsx(Chart, { data: newUsersByDateRange ?? [] }),
                        heading: "New Users",
                        id: "new-users"
                    }
                ],
                heading: "Growth",
                id: "growth",
                span: 2.5
            }
        ]);
    }
    return sections;
};
export default useSections;
