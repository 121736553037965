import { Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Paragraph = styled.p`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px;
  text-align: justify;

  ${mediaQueries.tablet} {
    font-size: 18px;
    line-height: 26px;
  }
`

export default Paragraph
