import Checkbox from "./Checkbox"

export interface Props {
  checked: boolean
  disabled: boolean
  inputId?: string
  label: string
  name: string
  onChange: () => void
}

export interface ComponentProps extends Props {}

export default Checkbox
