/* eslint-disable no-ternary, max-lines-per-function */

import { ComponentProps } from "."
import IsCompact from "./IsCompact"
import IsMatchTitleCheckbox from "./IsMatchTitleCheckbox"
import IsUHDCheckbox from "./IsUHDCheckbox"
import { Ul } from "./styled"

const ControlCheckboxes = ({
  isAdmin,
  isCompactCheckboxProps,
  isMatchTitleCheckboxProps,
  isUHDCheckboxProps
}: ComponentProps) => (
  <Ul aria-label="Control Toggles">
    {isAdmin && isUHDCheckboxProps ? (
      <li aria-label="Is UHD Toggle">
        <IsUHDCheckbox {...isUHDCheckboxProps} />
      </li>
    ) : null}

    {isAdmin && isMatchTitleCheckboxProps ? (
      <li aria-label="Is Match Title Toggle">
        <IsMatchTitleCheckbox {...isMatchTitleCheckboxProps} />
      </li>
    ) : null}

    {isCompactCheckboxProps ? (
      <li aria-label="Is Compact Toggle">
        <IsCompact {...isCompactCheckboxProps} />
      </li>
    ) : null}
  </Ul>
)

export default ControlCheckboxes
