import gql from "graphql-tag"

const ADD_SECTION = gql`
  mutation addSection($section: SectionAddInput!) {
    addSection(section: $section) {
      id
    }
  }
`

export default ADD_SECTION
