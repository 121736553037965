/* eslint-disable no-ternary */

import { Cards as BluBlocksCards } from "@bluframe/blublocks"
import Typography from "components/Typography"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  row-gap: 20px;
`

export const Heading = styled(Typography.H2)`
  font-size: 18px;
  font-weight: 700;
`

const BASE_SPAN = 180

export const Cards = styled<typeof BluBlocksCards & { $span?: number }>(
  BluBlocksCards
)`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${({ $span }: typeof BluBlocksCards & { $span?: number }) =>
        $span ? `${BASE_SPAN * $span}` : BASE_SPAN}px,
      1fr
    )
  );
  text-align: center;

  & h5 {
    font-size: 16px;
  }
`
