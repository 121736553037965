import EightLeggedFreak from "./EightLeggedFreak";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useCreateReviews from "hooks/useCreateReviews";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const { setIsUHD, state: { isUHD } } = useReviews();
    const { isAdmin } = useUser();
    const { areReviewsLoading } = useCreateReviews();
    const isUHDCheckboxProps = {
        checked: isUHD,
        isDisabled: areReviewsLoading,
        onChange: () => {
            setIsUHD(!isUHD);
        }
    };
    const reviewsControlCheckboxesProps = {
        isAdmin,
        isUHDCheckboxProps
    };
    return {
        reviewsControlCheckboxesProps
    };
};
export default prepareComponent(usePrepareComponent)(EightLeggedFreak);
