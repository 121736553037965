import gql from "graphql-tag";
const GET_EMAILS_HEADINGS = gql `
  query getEmailsHeadings {
    getEmailsHeadings {
      html
      id
      name
    }
  }
`;
export default GET_EMAILS_HEADINGS;
