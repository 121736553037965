import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 5px;
`

export const LoadingWrapper = styled.div`
  margin-right: 5px;
`
