/**
 * Determine if a service is created by checking if the given URL, preview state, and ID are valid.
 * @function getIsServiceCreated
 * @param {string} url - The URL string to check its existence.
 * @param {string} id - The ID string to check its existence.
 * @return {boolean} - If true, the service is considered created; otherwise, false.
 */
const getIsServiceCreated = (url, id) => {
    // If there's a URL and there's an ID or no URL,
    // return true (service created); otherwise, return false (service not created)
    return (Boolean(url) && Boolean(id)) || !url;
};
export default getIsServiceCreated;
