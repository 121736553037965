import emailsTemplate from "queries/fragments/emailsTemplate";
import gql from "graphql-tag";
const GET_EMAILS_TEMPLATE = gql `
  ${emailsTemplate}

  query getEmailsTemplate($id: ID!) {
    getEmailsTemplate(id: $id) {
      ...EmailsTemplate
    }
  }
`;
export default GET_EMAILS_TEMPLATE;
