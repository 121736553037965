import gql from "graphql-tag"

const GET_DASHBOARD_COUNT_ADMIN = gql`
  query getDashboardCountAdmin(
    $getDashboardCountAdminInput: GetDashboardCountAdminInput!
  ) {
    getDashboardCountAdmin(
      getDashboardCountAdminInput: $getDashboardCountAdminInput
    ) {
      homeVideoReviews {
        bluRay
        drafts
        fourK
        total
      }
      newUsersByDateRange {
        date
        newUsers
      }
      users {
        active
        pending
        total
      }
    }
  }
`

export default GET_DASHBOARD_COUNT_ADMIN
