import gql from "graphql-tag"

const PUBLISH_DRAFT_HOME_VIDEO_REVIEW = gql`
  mutation publishDraftHomeVideoReview($ids: [ID!]!) {
    publishDraftHomeVideoReview(ids: $ids) {
      ids
    }
  }
`
export default PUBLISH_DRAFT_HOME_VIDEO_REVIEW
