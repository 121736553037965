import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-ternary, no-undefined */
import { Buttons, Checkboxes, Row } from "./styled";
import List from "components/Reviews/Actions/List";
import LoadMore from "components/Reviews/Actions/LoadMore";
import OnlyNew from "components/Reviews/Actions/OnlyNew";
import OnlyPresent from "components/Reviews/Actions/OnlyPresent";
import Reviews from "components/Reviews";
import ShowFlagged from "components/Reviews/Actions/ShowFlagged";
import config from "config/site";
const Main = ({ isAdmin, setOMDbId }) => (_jsx(_Fragment, { children: config.rows.map((row) => (_jsx(Row, { children: row.reviews.map((review) => (_jsx(Reviews, { actions: _jsxs(_Fragment, { children: [_jsxs(Buttons, { children: [_jsx(List, { serviceName: review.serviceName }), review.serviceName === "bluRay" ? (_jsx(LoadMore, { serviceName: review.serviceName })) : null] }), isAdmin ? (_jsxs(Checkboxes, { children: [_jsx(OnlyNew, { serviceName: review.serviceName }), _jsx(OnlyPresent, { serviceName: review.serviceName }), _jsx(ShowFlagged, { serviceName: review.serviceName })] })) : null] }), name: review.name, serviceName: review.serviceName, setOMDbId: review.serviceName === "bluRay" ? setOMDbId : undefined }, review.serviceName))) }, row.id))) }));
export default Main;
