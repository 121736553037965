import gql from "graphql-tag";
const CREATE_PREVIEW = gql `
  mutation createPreview($previewInput: PreviewInput!) {
    createPreview(previewInput: $previewInput) {
      audio {
        quote
        score
        section
      }
      author {
        name
        url
      }
      editions
      extra {
        quote
        score
        section
      }
      errorFields
      images {
        back
        front
        overview
        slip
        slipback
      }
      isValid
      movie {
        quote
        score
        section
      }
      releaseDate
      tags
      total {
        quote
        score
        section
      }
      url
      video {
        quote
        score
        section
      }
    }
  }
`;
export default CREATE_PREVIEW;
