import { Props as DraftProps } from "../Draft"
import Drafts from "./Drafts"

export interface Props {
  drafts: DraftProps[]
}

export interface ComponentProps extends Props {}

export default Drafts
