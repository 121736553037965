import { ChangeEventHandler } from "react"
import Text from "./Text"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  disabled?: boolean
  hasValid?: boolean
  id?: string
  isValid?: boolean
  label: string
  name?: string
  // eslint-disable-next-line no-unused-vars
  onChange?: (text: string) => void
  text?: number | string | null
}

export interface ComponentProps extends Omit<Props, "onChange"> {
  onChange: ChangeEventHandler<HTMLInputElement>
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  }

  return {
    ...props,
    onChange
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Text
)
