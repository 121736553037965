import Button from "./Button"
import type { ButtonProps } from "@bluframe/blublocks"

export interface Props extends ButtonProps {
  loading?: boolean
}

export interface ComponentProps extends Props {}

export default Button
