import BaseButton from "components/buttons/Button"
import { ComponentProps } from "."

const Button = ({
  isDisabled,
  isOutlined,
  label,
  loading,
  onClick
}: ComponentProps) => (
  <BaseButton
    disabled={isDisabled || loading}
    label={label}
    loading={loading}
    onClick={onClick}
    outlined={isOutlined}
  />
)

export default Button
