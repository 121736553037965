/* eslint-disable no-ternary */

import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import CircularProgress from "@material-ui/core/CircularProgress"
import type { ComponentProps } from "."
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ListItem from "@material-ui/core/ListItem"
import Radio from "@material-ui/core/Radio"
import React from "react"
import colors from "styles/colors"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  actionButtons: {
    "& .MuiCircularProgress-root": {
      marginLeft: "5px"
    },
    opacity: "0",
    transition: "opacity 0.25s ease-in-out"
  },
  listItem: {
    "&:hover $actionButtons": {
      opacity: "1"
    },
    background: colors.white,
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "4px",
    paddingTop: "4px"
  }
})

const Item = ({
  flagLoading,
  id,
  isFlagged,
  isShowFlagButton,
  label,
  onToggleFlagged,
  url
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <ListItem aria-label={label} className={classes.listItem}>
      <FormControlLabel control={<Radio />} label={label} value={id} />
      <ButtonGroup
        aria-label="Actions"
        className={classes.actionButtons}
        color="primary"
        variant="outlined"
      >
        <Button href={url} target="__blank">{`Inspect`}</Button>
        {isShowFlagButton ? (
          <Button onClick={onToggleFlagged(id, isFlagged)}>
            {isFlagged ? "Unflag" : "Flag"}
            {flagLoading ? (
              <CircularProgress color="primary" size={14} />
            ) : null}
          </Button>
        ) : null}
      </ButtonGroup>
    </ListItem>
  )
}

export default Item
