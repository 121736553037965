import List from "./List"
import { Review } from "@bluframe/mightychroma-mightytool-frontend-types"

export interface Props {
  display: Review[]
  flagLoading: boolean
  isShowFlagButton: boolean
  onSelect: () => void
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  selected: string
}

export interface ComponentProps extends Props {}

export default List
