import { Props as AutosuggestProps } from "components/Autosuggest"
import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"
import ImagesGrid from "./ImagesGrid"

export interface Props {
  homeVideoReviewIds: string[]
  homeVideoReviewTitles: HomeVideoReviewSearchItem[]
  isAutoSuggestLoading: boolean
  isCreateHomeVideoImagesGridLoading: boolean
  onClickGenerate: () => void
  // eslint-disable-next-line no-unused-vars
  onSelectHomeVideoReviewId: (index: number) => (
    // eslint-disable-next-line no-unused-vars
    id: string,
    // eslint-disable-next-line no-unused-vars
    name: string
  ) => void
  onGetSuggestions: AutosuggestProps["onGetSuggestions"]
  reviewNames: string[]
}

export interface ComponentProps extends Props {}

export default ImagesGrid
