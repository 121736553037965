import Checkbox from "components/HomeVideoReviews/ReviewsCard/Actions/Checkbox"
import { ComponentProps } from "."

const IsCompact = ({ checked, isDisabled, onChange }: ComponentProps) => (
  <Checkbox
    checked={checked}
    disabled={isDisabled}
    inputId="isCompact"
    label="Compact"
    name="isCompact"
    onChange={onChange}
  />
)

export default IsCompact
