import type { Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const LI = styled.li`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.main};
  font-size: 16px;
  margin: 0 0 4px;
  text-align: justify;

  ${mediaQueries.tablet} {
    margin: 0 0 6px;
  }
`

export default LI
