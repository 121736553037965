import Draft from "./Draft"
import { OptionValue } from "@bluframe/blublocks"
import { ServiceName } from "@bluframe/mightychroma-mightytool-frontend-types"

// eslint-disable-next-line no-unused-vars
export const SERVICE_NAMES_MAP: { [key in ServiceName]: string } = {
  avForums: "AV Forums",
  avNirvana: "AV Nirvana",
  avsForum: "AVS Forum",
  bluRay: "Blu-ray.com",
  bluRayAuthority: "Blu-ray Authority",
  digitalBits: "The Digital Bits",
  doBlu: "DoBlu",
  hidefDigest: "Hi-Def Digest",
  homeTheaterForum: "Home Theater Forum",
  whySoBlu: "Why So Blu?"
}

export interface DraftPreview {
  image?: string
  releaseDate?: string
  serviceName: ServiceName
  url: string
}

export interface Props {
  id: string
  isChecked: boolean
  isLoading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (value: OptionValue) => void
  onPublish: () => void
  previews: DraftPreview[]
  title: string
}

export interface ComponentProps extends Props {}

export default Draft
