import gql from "graphql-tag";
const UPDATE_EMAILS_TEMPLATES = gql `
  mutation updateEmailsTemplate(
    $id: ID!
    $emailsTemplateUpdateInput: EmailsTemplateUpdateInput!
  ) {
    updateEmailsTemplate(
      id: $id
      emailsTemplateUpdateInput: $emailsTemplateUpdateInput
    ) {
      id
    }
  }
`;
export default UPDATE_EMAILS_TEMPLATES;
