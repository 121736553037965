import gql from "graphql-tag"

const GENERATE_BLOG_ROUNDUP = gql`
  mutation generateBlogRoundup(
    $blogRoundupGenerateInput: BlogRoundupGenerateInput!
  ) {
    generateBlogRoundup(blogRoundupGenerateInput: $blogRoundupGenerateInput) {
      status
    }
  }
`
export default GENERATE_BLOG_ROUNDUP
