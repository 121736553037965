/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, max-statements */
import { useHistory, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import DELETE_NEWSLETTER from "mutations/newsletters/deleteNewsletter";
import GET_EMAILS_TEMPLATE from "queries/emails/getEmailsTemplate";
import GET_NEWSLETTERS from "queries/newsletters/getNewsletters";
import GET_NEWSLETTER_AND_SUBSCRIBERS_COUNT from "queries/newsletters/getNewsletterAndSubscribersCount";
import Item from "./Item";
import SEND_EMAILS_TEMPLATES from "mutations/emails/sendEmailsTemplate";
import UPDATE_NEWSLETTER from "mutations/newsletters/updateNewsletter";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useMemo } from "react";
import useToggle from "@bluframe/grapple/useToggle";
const replacePlaceholders = (html, data, placeholders) => placeholders?.reduce((acc, placeholder) => {
    if (placeholder && data[placeholder]) {
        // If we use unicode regex isn't able to parse
        // {{ }} placeholders
        // eslint-disable-next-line require-unicode-regexp
        const placeholderRegex = new RegExp(`{{${placeholder}}}`, "g");
        return acc.replace(placeholderRegex, data[placeholder]);
    }
    return acc;
}, html) ?? html;
const usePrepareComponent = () => {
    const [isEditing, toggleIsEditing] = useToggle();
    const history = useHistory();
    const params = useParams();
    const { data: newsletterData, loading } = useQuery(GET_NEWSLETTER_AND_SUBSCRIBERS_COUNT, {
        variables: {
            id: params.id
        }
    });
    const [getEmailsTemplate, { data: emailsTemplateData }] = useLazyQuery(GET_EMAILS_TEMPLATE);
    const [sendEmailsTemplate, { loading: isSendLoading }] = useMutation(SEND_EMAILS_TEMPLATES);
    const [sendEmailsTemplateTest, { loading: isSendTestLoading }] = useMutation(SEND_EMAILS_TEMPLATES);
    const [updateNewsletter] = useMutation(UPDATE_NEWSLETTER, {
        refetchQueries: [
            {
                query: GET_NEWSLETTER_AND_SUBSCRIBERS_COUNT,
                variables: { id: params.id }
            }
        ]
    });
    const [deleteNewsletter] = useMutation(DELETE_NEWSLETTER, {
        refetchQueries: [{ query: GET_NEWSLETTERS }]
    });
    const newsletterAndSubscribersCount = useMemo(() => {
        if (newsletterData?.getNewsletterAndSubscribersCount) {
            let placeholders;
            if (newsletterData?.getNewsletterAndSubscribersCount?.placeholders) {
                placeholders = JSON.parse(newsletterData?.getNewsletterAndSubscribersCount?.placeholders);
            }
            return {
                ...newsletterData?.getNewsletterAndSubscribersCount,
                placeholders
            };
        }
        return undefined;
    }, [newsletterData?.getNewsletterAndSubscribersCount]);
    const onCancel = () => {
        history.push(`/newsletters`);
    };
    const onEdit = () => {
        toggleIsEditing();
    };
    const onSave = (newsletterUpdateInput) => {
        updateNewsletter({
            onCompleted: () => {
                toggleIsEditing();
            },
            variables: {
                id: params.id,
                newsletterUpdateInput: {
                    ...newsletterUpdateInput,
                    placeholders: newsletterUpdateInput.placeholders
                        ? JSON.stringify(newsletterUpdateInput.placeholders)
                        : undefined
                }
            }
        });
    };
    const onEmailSend = () => {
        if (newsletterData?.getNewsletterAndSubscribersCount.templateId) {
            sendEmailsTemplate({
                onCompleted: () => {
                    // Update newsletter to published after we
                    // send all emails
                    updateNewsletter({
                        variables: {
                            id: params.id,
                            newsletterUpdateInput: {
                                isPublished: true
                            }
                        }
                    });
                },
                variables: {
                    emailsSendTemplateInput: {
                        email: {
                            from: {
                                address: "info@mightychroma.me",
                                name: "Mighty Chroma"
                            },
                            templateData: newsletterAndSubscribersCount?.placeholders
                                ? JSON.stringify(newsletterAndSubscribersCount.placeholders)
                                : undefined,
                            templateId: newsletterData?.getNewsletterAndSubscribersCount.templateId
                        },
                        emailPreferences: newsletterData.getNewsletterAndSubscribersCount
                            ?.emailPreferences ?? "isNewsletterEmails"
                    }
                }
            });
        }
    };
    const onEmailTestSend = () => {
        if (newsletterData?.getNewsletterAndSubscribersCount.templateId) {
            sendEmailsTemplateTest({
                variables: {
                    emailsSendTemplateInput: {
                        email: {
                            from: {
                                address: "info@mightychroma.me",
                                name: "Mighty Chroma"
                            },
                            templateData: newsletterAndSubscribersCount?.placeholders
                                ? JSON.stringify(newsletterAndSubscribersCount.placeholders)
                                : undefined,
                            templateId: newsletterData?.getNewsletterAndSubscribersCount.templateId,
                            toAddresses: ["info@mightychroma.me"]
                        },
                        emailPreferences: newsletterData.getNewsletterAndSubscribersCount
                            .emailPreferences ?? "isNewsletterEmails"
                    }
                }
            });
        }
    };
    const onTemplateIdChange = (newTemplateId) => {
        const templateId = newTemplateId ??
            newsletterData?.getNewsletterAndSubscribersCount.templateId;
        if (templateId) {
            getEmailsTemplate({
                variables: {
                    id: templateId
                }
            });
        }
    };
    const onDelete = () => {
        deleteNewsletter({
            onCompleted: () => {
                history.push(`/newsletters`);
            },
            variables: {
                id: params.id
            }
        });
    };
    const emailPreview = useMemo(() => {
        const heading = emailsTemplateData?.getEmailsTemplate.heading?.html ?? "";
        const footer = emailsTemplateData?.getEmailsTemplate.footer?.html ?? "";
        const html = replacePlaceholders(emailsTemplateData?.getEmailsTemplate?.html ?? "", newsletterAndSubscribersCount?.placeholders, emailsTemplateData?.getEmailsTemplate?.placeholders);
        return `${heading}${html}${footer}`;
    }, [
        emailsTemplateData?.getEmailsTemplate,
        newsletterAndSubscribersCount?.placeholders
    ]);
    return {
        emailPreview,
        isEditing,
        isSendLoading,
        isSendTestLoading,
        item: newsletterAndSubscribersCount,
        loading,
        onCancel,
        onDelete,
        onEdit,
        onEmailSend,
        onEmailTestSend,
        onSave,
        onTemplateIdChange
    };
};
export default prepareComponent(usePrepareComponent)(Item);
