/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, max-statements */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Item from "./Item";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useToggle from "@bluframe/grapple/useToggle";
const { forum: { GET_CATEGORIES, GET_CATEGORY } } = queries;
const { forum: { DELETE_CATEGORY, UPDATE_CATEGORY } } = mutations;
const usePrepareComponent = () => {
    const [isEditing, toggleIsEditing] = useToggle();
    const history = useHistory();
    const params = useParams();
    const { data: categoryData, loading } = useQuery(GET_CATEGORY, {
        variables: {
            id: params.id
        }
    });
    const [updateCategory] = useMutation(UPDATE_CATEGORY, {
        refetchQueries: [
            {
                query: GET_CATEGORY,
                variables: { id: params.id }
            }
        ]
    });
    const [deleteCategory] = useMutation(DELETE_CATEGORY, {
        refetchQueries: [{ query: GET_CATEGORIES }]
    });
    const onCancel = () => {
        history.push("/forum/categories");
    };
    const onEdit = () => {
        toggleIsEditing();
    };
    const onSave = (category) => {
        updateCategory({
            onCompleted: () => {
                toggleIsEditing();
            },
            variables: {
                category: {
                    category,
                    id: params.id
                }
            }
        });
    };
    const onDelete = () => {
        deleteCategory({
            onCompleted: () => {
                history.push("/forum/categories");
            },
            variables: {
                id: params.id
            }
        });
    };
    return {
        isEditing,
        item: categoryData?.category,
        loading,
        onCancel,
        onDelete,
        onEdit,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Item);
