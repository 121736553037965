import ControlCheckboxes from "./ControlCheckboxes"
import { Props as IsMatchTitleCheckboxProps } from "./IsMatchTitleCheckbox"
import { Props as IsUHDCheckboxProps } from "./IsUHDCheckbox"

export interface Props {
  isAdmin?: boolean
  isCompactCheckboxProps?: IsMatchTitleCheckboxProps
  isMatchTitleCheckboxProps?: IsMatchTitleCheckboxProps
  isUHDCheckboxProps?: IsUHDCheckboxProps
}

export interface ComponentProps extends Props {}

export default ControlCheckboxes
