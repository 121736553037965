/* eslint-disable max-lines-per-function, max-statements */
import { useEffect, useState } from "react";
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GET_CHARACTER from "queries/getCharacter";
import Roundup from "./Roundup";
import prepareComponent from "@bluframe/grapple/prepareComponent";
const { homeVideoReviews: { GET_HOME_VIDEO_REVIEW } } = queries;
const { blog: { GENERATE_BLOG_ROUNDUP } } = mutations;
const ID_VALIDATE = /\S{7}-\S{4}-\S{4}-\S{4}-\S{12}/u;
const ZERO = 0;
const SLICE_END = 80;
const SLICE_START = 0;
const usePrepareComponent = () => {
    const [characterId, setCharacterId] = useState("");
    const [homeVideoReviewIds, setHomeVideoReviewIds] = useState([""]);
    const [theme, setTheme] = useState("");
    const [reviewNames, setReviewNames] = useState([]);
    const [getHomeVideoReview] = useLazyQuery(GET_HOME_VIDEO_REVIEW);
    const [generateBlogRoundup, { loading: isGenerateBlogRoundupLoading }] = useMutation(GENERATE_BLOG_ROUNDUP);
    useEffect(() => {
        const hasEmptyRow = homeVideoReviewIds.filter((id) => id === "").length > ZERO;
        homeVideoReviewIds.forEach((homeVideoReviewId, index) => {
            if (homeVideoReviewId && ID_VALIDATE.test(homeVideoReviewId)) {
                getHomeVideoReview({
                    onCompleted: (homeVideoReviewData) => {
                        setReviewNames((prevReviewNames) => {
                            const newReviewNames = [...prevReviewNames];
                            // @ts-ignore
                            newReviewNames[index] = `${homeVideoReviewData?.getHomeVideoReview.summary.total.long.slice(SLICE_START, SLICE_END)}...`;
                            return newReviewNames;
                        });
                    },
                    variables: {
                        id: homeVideoReviewId
                    }
                });
            }
        });
        if (!hasEmptyRow) {
            setHomeVideoReviewIds([...homeVideoReviewIds, ""]);
        }
    }, [getHomeVideoReview, homeVideoReviewIds]);
    const { data: characterData, loading: isCharacterLoading } = useQuery(GET_CHARACTER, {
        skip: !ID_VALIDATE.test(characterId),
        variables: { id: characterId }
    });
    const onChangeCharacterId = (event) => {
        setCharacterId(event.target.value);
    };
    const onChangeHomeVideoReviewId = (index) => (event) => {
        const newHomeVideoReviewIds = [...homeVideoReviewIds];
        newHomeVideoReviewIds[index] = event.target.value;
        setHomeVideoReviewIds(newHomeVideoReviewIds);
    };
    const onChangeTheme = (event) => {
        setTheme(event.target.value);
    };
    const onClickGenerate = () => {
        generateBlogRoundup({
            variables: {
                blogRoundupGenerateInput: {
                    characterId,
                    homeVideoReviewIds: homeVideoReviewIds.filter((id) => id),
                    theme
                }
            }
        });
    };
    return {
        character: characterData?.getCharacter,
        characterId,
        homeVideoReviewIds,
        isCharacterLoading,
        isGenerateBlogRoundupLoading,
        onChangeCharacterId,
        onChangeHomeVideoReviewId,
        onChangeTheme,
        onClickGenerate,
        reviewNames,
        theme
    };
};
export default prepareComponent(usePrepareComponent)(Roundup);
