/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */
import { useLazyQuery, useMutation } from "@apollo/client";
import ADD_CONTEST from "mutations/contests/addContest";
import Add from "./Add";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useHistory } from "react-router-dom";
const { contests: { GET_CONTESTS } } = queries;
const usePrepareComponent = () => {
    const history = useHistory();
    const [addContent, { loading }] = useMutation(ADD_CONTEST, {
        refetchQueries: [GET_CONTESTS]
    });
    const [getContents] = useLazyQuery(GET_CONTESTS);
    const onCancel = () => {
        history.push(`/contests`);
    };
    const onSave = (itemAdd) => {
        addContent({
            onCompleted: () => {
                getContents({
                    fetchPolicy: "network-only",
                    onCompleted: () => {
                        history.push(`/contests`);
                    }
                });
            },
            variables: {
                contestAddInput: {
                    ...itemAdd,
                    slug: itemAdd.slug ?? "",
                    title: itemAdd.title ?? ""
                }
            }
        });
    };
    return {
        loading,
        onCancel,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Add);
