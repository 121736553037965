import { Li, Text } from "./styled"
import { ComponentProps } from "."

const Suggestion = ({
  mas: { title, year },
  itemProps: { onMouseDown, onMouseMove, ref, role, ...itemProps },
  onClick
}: ComponentProps) => (
  <Li
    aria-disabled={itemProps["aria-disabled"]}
    aria-label={`${title} (${year})`}
    aria-selected={itemProps["aria-selected"]}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseMove={onMouseMove}
    ref={ref}
    role={role}
  >
    <Text>{`${title} (${year})`}</Text>
  </Li>
)

export default Suggestion
