import { ComponentProps } from "."
import { Wrapper } from "./styled"

const Chart = ({ ariaLabel, chartRef }: ComponentProps) => (
  <Wrapper
    aria-label={ariaLabel ?? "Chart"}
    ref={chartRef}
    role="img"
    tabIndex={0}
  />
)

export default Chart
